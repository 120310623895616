import { authnApi } from "@portal-shared/api/authnApi";
import { LoginRequest } from "@portal-shared/types/authnTypes";
import { useMutation } from "@tanstack/react-query";

export const useLoginMutation = () => {
  return useMutation({
    mutationFn: (data: LoginRequest) => {
      localStorage && localStorage?.clear();
      return authnApi.login(data);
    },
  });
};
