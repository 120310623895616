export const createPasswordPageTranslations = {
  tCreatePasswordPageAccountNumber: "Account Number: {accountNumber}",
  tCreatePasswordPageCreateYourPassword:
    "Create your password and access your Rhythm Account.",
  tCreatePasswordPageRequestedService:
    "Requested Service Start Date: {startDate}",
  tCreatePasswordPageSitTight:
    "Sit tight, we're processing your request. You'll receive an email and SMS in the next few hours to confirm your enrollment and service start date.",
  tCreatePasswordPageStayInformed:
    "Stay informed about your usage, view and pay bills, and see other important documents.",
  tCreatePasswordPageThanks: "Thanks for choosing Rhythm, {firstName}!",
  tCreatePasswordPageTwoFriendsHighFiving: "Two friends high fiving",
};
