import { UUIDType } from "@common/types/apiTypes";
import { rhWindow } from "@common/utils/rhWindow";

export const SIGN_UP_PATHNAME = "sign-up";

export enum SignUpStepNames {
  Address = "address",
  Availability = "availability",
}

export const SignUpSteps = [
  "address",
  "billing-preferences",
  "call-us",
  "confirm-address",
  "contact",
  "credit-check",
  "deposit",
  "details",
  "id-upload",
  "information",
  "name",
  "payment",
  "summary",
  "add-ons",
  "provide-additional-information",
] as const;

export type SignUpStepType = (typeof SignUpSteps)[number];

export const SIGN_UP_RESUME_SUB_PATHNAME = "welcome-back";
export type SignUpResumeStepType = typeof SIGN_UP_RESUME_SUB_PATHNAME;

export const SIGN_UP_LEAD_GEN_SUB_PATHNAME = "lead-generation";
export type SignUpLeadGenStepType = typeof SIGN_UP_LEAD_GEN_SUB_PATHNAME;

const addDomain = (path: string): string =>
  `${rhWindow.location.origin}${path}`;

export const signUpBasePath = () => {
  return `/${SIGN_UP_PATHNAME}`;
};

export const signUpUrl = () => {
  return addDomain(signUpBasePath());
};

export const signUpStepPath = (
  step: SignUpStepType | SignUpResumeStepType | SignUpLeadGenStepType
) => {
  return `${signUpBasePath()}/${step}`;
};

export const signUpResumePath = (uuid?: UUIDType) => {
  return `/${signUpBasePath()}/${SIGN_UP_RESUME_SUB_PATHNAME}/${uuid}`;
};

export const signUpLeadGenPath = (uuid?: UUIDType) => {
  return `/${signUpBasePath()}/${SIGN_UP_LEAD_GEN_SUB_PATHNAME}/${uuid}`;
};

export const enrollBasePath = () => {
  return "/enroll";
};

export const enrollOffersPath = () => {
  return `${enrollBasePath()}/offers`;
};

export const enrollOffersErrorPath = () => {
  return `${enrollBasePath()}/oops`;
};
