import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTextButton } from "@design-system/components/RhTextButton/RhTextButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { DeviceStatus } from "@portal-account/types/devicesTypes";
import styled from "styled-components";

export const StyledContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(2)};

  @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
    flex-flow: row nowrap;
    gap: ${rhSpacingPx(8)};
  }
`;

export const StyledInfoContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-flow: row wrap;
  gap: ${rhSpacingPx(2)};
  width: 100%;
`;

export const StyledDeviceInfoRow = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  gap: ${rhSpacingPx(2)};
  justify-content: flex-start;
  text-transform: capitalize;
  width: 100%;

  > * {
    flex: 1;
  }
`;

export const StyledImageWrapper = styled.div`
  align-items: flex-start;
  border-radius: ${rhSpacingPx(1)};
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
  width: 100%;

  & > img {
    height: 16rem;
    width: 18rem;
  }
`;

export const StyledDeviceStatus = styled(RhTypography).attrs({
  variant: "body1",
})<{ $status: DeviceStatus }>`
  color: ${({ $status }) =>
    $status === DeviceStatus.Online
      ? CSSVariables.COLOR_SUCCESS_MAIN
      : CSSVariables.COLOR_ERROR_MAIN};
  font-weight: ${FontWeight.Semibold};
  text-transform: capitalize;
`;

export const DeviceModelOrId = styled(RhTypography).attrs({ variant: "body1" })`
  max-width: 15ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledLegalDocLinkContainer = styled.div`
  align-items: center;
  align-items: flex-start;
  display: flex;
  gap: ${rhSpacingPx(1)};
  justify-content: flex-start;
  line-height: 1.4;
  padding-top: ${rhSpacingPx(0.5)};
`;

export const StyledTextButton = styled(RhTextButton)`
  background: none;
  border: 0;
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  cursor: pointer;
  padding: 0;
  text-align: left;
`;

export const StyledDeviceSettingsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
`;

export const StyledLinkGroup = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  gap: ${rhSpacingPx(0.5)};
  justify-content: flex-start;
  padding-top: ${rhSpacingPx(0.5)};
  text-align: left;
  width: 100%;
`;
