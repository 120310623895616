import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { StyledLoadingContainer } from "@portal-account/components/BillingHistory/BillingHistory.styled";
import { BillingHistoryTable } from "@portal-account/components/BillingHistoryTable/BillingHistoryTable";
import { BillingHistoryTableV2 } from "@portal-account/components/BillingHistoryTableV2/BillingHistoryTableV2";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { useInvoiceHistoryQuery } from "@portal-account/hooks/queries/useInvoiceHistory.query";
import { Invoice } from "@portal-account/models/Invoice.model";
import { CustomerHomeSectionInfoMessage } from "@portal-account/pages/CustomerHomePage/CustomerHomeSectionInfoMessage/CustomerHomeSectionInfoMessage";
import { CustomerHomeSectionInfoMessageContainer } from "@portal-account/pages/CustomerHomePage/CustomerHomeSectionInfoMessage/CustomerHomeSectionInfoMessage.styled";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React from "react";

const BillingHistoryFeatureFlag = (props: { invoices: Invoice[] }) => {
  const { invoices } = props;
  const { featureFlagClient } = useFeatureFlagClient();

  const { portalBillingHistoryV2 } = featureFlagClient.getFlags([
    ["portalBillingHistoryV2", false],
  ]);

  return portalBillingHistoryV2.value ? (
    <BillingHistoryTableV2 invoices={invoices} />
  ) : (
    <BillingHistoryTable invoices={invoices} />
  );
};

export const BillingHistory = () => {
  const { t } = useRhIntl();
  const { premise } = usePremiseFromContext();

  const { data: invoiceHistoryData, isPending } = useInvoiceHistoryQuery({
    premiseId: premise.id,
  });

  const emptyStateMessage = t("BillingHistory.noPriorBills");

  if (isPending) {
    return (
      <StyledLoadingContainer>
        <RhCircularProgress size={24} />
      </StyledLoadingContainer>
    );
  }

  return (
    <div data-testid="billingHistory__container">
      {invoiceHistoryData?.results.length ? (
        <BillingHistoryFeatureFlag invoices={invoiceHistoryData.results} />
      ) : (
        <CustomerHomeSectionInfoMessageContainer>
          <CustomerHomeSectionInfoMessage>
            {emptyStateMessage}
          </CustomerHomeSectionInfoMessage>
        </CustomerHomeSectionInfoMessageContainer>
      )}
    </div>
  );
};
