import { AppBrands } from "@common/enums/appBrands.enum";
import { getAppBrand } from "@common/services/getEnvApplicationSettings.service";
import {
  BrandImageUrls,
  EnergyTexasBrandImageUrls,
  RhythmBrandImageUrls,
} from "@portal-shared/hooks/useBrandImageUrls/types";

export const useBrandImageUrls = (): BrandImageUrls => {
  const appBrand = getAppBrand();

  switch (appBrand) {
    case AppBrands.RHYTHM: {
      return RhythmBrandImageUrls;
    }
    case AppBrands.ENERGY_TEXAS: {
      return EnergyTexasBrandImageUrls;
    }
    default: {
      return RhythmBrandImageUrls;
    }
  }
};
