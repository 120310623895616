import { DefaultGlobalAnnouncement } from "@common/types/globalAnnouncementTypes";

export enum FeatureFlagMetric {
  PortalCustomerCopiedOrSharedRafButton = "portal-customer-copied-or-shared-raf-button",
  PortalProspectAddedPaymentMethod = "portal-prospect-added-payment-method",
  PortalProspectEmailSubmitted = "portal-prospect-email-submitted",
  PortalProspectEnabledAutopay = "portal-prospect-enabled-autopay",
  PortalProspectEnrolled = "portal-prospect-enrolled",
  PortalProspectEnrolledWithProductAddOnPlan = "portal-prospect-enrolled-with-product-add-on-plan",
  PortalProspectMovingSwitchingSubmitted = "portal-prospect-moving-switching-submitted",
  PortalProspectOfferCardLearnMoreClicked = "portal-prospect-offer-card-learn-more-clicked",
  PortalProspectOfferPhoneNumberClicked = "portal-prospect-offer-phone-number-clicked",
  PortalProspectOpenedPlanInformationDialog = "portal-prospect-opened-plan-information-dialog",
  PortalProspectRewardsSubmitted = "portal-prospect-rewards-submitted",
  PortalProspectSelectedPlan = "portal-prospect-selected-plan",
  PortalProspectServiceAddressSubmitted = "portal-prospect-service-address-submitted",
  PortalProspectShowMoreOffersClicked = "portal-prospect-show-more-offers-clicked",
  PortalProspectSubmittedNameDOB = "portal-prospect-submitted-name-dob",
  PortalProspectZipCodeSubmitted = "portal-prospect-zip-code-submitted",
  PortalRemovedProductAddOnPlan = "portal-removed-product-add-on-plan",
  PortalSelectedProductAddOnPlan = "portal-selected-product-add-on-plan",
}

type ValidFlagValueTypes = boolean | string;

export type ValidFlagValues =
  | ValidFlagValueTypes
  | Record<string, ValidFlagValueTypes>;

export type FeatureFlagSet = Record<string, ValidFlagValues>;

export interface FetchedFeatureFlags {
  flags: FeatureFlagSet;
}

export interface FeatureFlagValues {
  enrollmentPricingCampaign: string;
  opsAlloyValidationReport: boolean;
  opsBillBreakdownGraph: boolean;
  opsCommercialEnrollment: boolean;
  opsDepositAlternativeProgram: boolean;
  opsDocumentsTab: boolean;
  opsEnergyOnlyDeferPaymentPlan: boolean;
  opsFidelityCashPayments: boolean;
  opsMultibrand: boolean;
  opsPendingProductContracts: boolean;
  opsProductAddOns: boolean;
  opsProductAddOnsEnrollment: boolean;
  opsProductContractClaims: boolean;
  opsReprocessInvoice: boolean;
  opsShowViewTosLink: boolean;
  opsUsageChart: boolean;
  opsUsageGraphTemperatures: boolean;
  opsZuoraAch: boolean;
  portalAddAch: boolean;
  portalAverageBilling: boolean;
  portalBillBreakdownGraph: boolean;
  portalBillingHistoryV2: boolean;
  portalComparePowerSection: boolean;
  portalCondensedMobileOfferCard: boolean;
  portalDepositAlternativeProgram: boolean;
  portalDeviceManagement: boolean;
  portalDeviceManagementAmazon: boolean;
  portalDeviceManagementHoneywell: boolean;
  portalEstimatedAnnualSavingsTag: boolean;
  portalGlobalAnnouncement: DefaultGlobalAnnouncement;
  portalNewContactForm: boolean;
  portalNewPlansPage: boolean;
  portalOptOutProductContract: boolean;
  portalPickYourDueDate: boolean;
  portalProductAddOns: boolean;
  portalProductAddOnsOptIn: boolean;
  portalProductAddOnsSignUpFlow: boolean;
  portalProductContractClaimsManagement: boolean;
  portalRenewalsCompetitorPlanChart: boolean;
  portalRepPriceComparison: boolean;
  portalShowViewTosLink: boolean;
  portalSolarBuybackUsageChart: boolean;
  portalSolarPlanShowAveragePrice: boolean;
  portalTouGenerationUsageGraph: boolean;
  portalUsageChart: boolean;
  portalUsageGraphTemperatures: boolean;
  portalZuoraAchDigitalEnrollment: boolean;
  pricingNewCampaignsPage: boolean;
  pricingOffersBulkUpdate: boolean;
  pricingSolarBuybackDifferentiated: boolean;
  prospectCreditFlowV2: boolean;
}

export interface RhFlag {
  label: keyof FeatureFlagValues;
  recordVisitor(): void;
  value: ValidFlagValues;
}

export type RhFlags = {
  [key in keyof FeatureFlagValues]: RhFlag;
};

export interface FeatureFlagClient {
  getFlags: (
    flagNames: [keyof FeatureFlagValues, ValidFlagValues][]
  ) => RhFlags;
  isFetchingFlags: () => boolean;
  sendIdentifyEvent: (custom: Record<string, unknown>) => Promise<boolean>;
  trackMetric: (metric: FeatureFlagMetric) => Promise<void>;
}

export interface FeatureFlagManager {
  featureFlagClient: FeatureFlagClient;
}
