import { SignUpStepType } from "@enroll-utils/constants/routePaths";
import {
  CreditCheckResponseV2,
  CreditCheckStatus,
} from "@enroll-utils/types/creditCheckTypes";

export const getStepAfterCreditCheckNotApproved = (
  creditCheckResponse: CreditCheckResponseV2
): SignUpStepType => {
  if (
    creditCheckResponse.status === CreditCheckStatus.APPROVED_WITH_REQUIREMENTS
  ) {
    if (
      creditCheckResponse.requirements[0].requirementType === "IDENTITY_CHECK"
    ) {
      return "id-upload";
    } else if (
      creditCheckResponse.requirements[0].requirementType === "DEPOSIT"
    ) {
      return "deposit";
    } else if (creditCheckResponse.requirements[0].requirementType === "SSN") {
      return "credit-check";
    }
  }
  return "provide-additional-information";
};
