import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import styled from "styled-components";

export const PortalPasswordInputContainer = styled.div`
  position: relative;
  > svg {
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid ${CSSVariables.COLOR_PRIMARY_MAIN};
      outline-offset: 2px;
    }
  }
`;
