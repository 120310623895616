import { rhSpacingPx } from "@design-system/theme/spacing";
import { PortalInput } from "@portal-shared/components/PortalInput/PortalInput.styled";
import { PortalInputErrorMessage } from "@portal-shared/components/PortalInputErrorMessage/PortalInputErrorMessage.styled";
import styled from "styled-components";

export const StyledEnrollPremiseForm = styled.form`
  max-width: 350px;
  padding-top: ${rhSpacingPx(5)};
`;

export const StyledEnrollPremiseFormRow = styled.div`
  display: flex;
  gap: ${rhSpacingPx(2)};
`;

export const StyledUnitNumberInput = styled(PortalInput)`
  width: 90px;
`;

export const StyledEnrollZipCodeErrorMessage = styled(PortalInputErrorMessage)`
  text-align: right;
`;
