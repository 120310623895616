import { RhythmCampaignSlugs } from "@common/constants/campaigns.constant";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useCreateProspectMutation } from "@enroll-data/hooks/mutations/useCreateProspect.mutation";
import { useProspectUpdateMutation } from "@enroll-data/hooks/mutations/useProspectUpdate.mutation";
import { useProspectQuery } from "@enroll-data/hooks/queries/useProspect.query";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import {
  ProspectCreateRequest,
  ProspectUpdateRequestType,
} from "@enroll-utils/types/prospectTypes";
import { OfferSnapshotFilterType } from "@portal-enroll/components/OfferSnapshotFilterTabs/OfferSnapshotFilterTabs.types";
import { selectedOfferSnapshotFilterAtom } from "@portal-enroll/components/OfferSnapshotFilterTabs/selectedOfferSnapshotFilter.atom";
import { ProspectContext } from "@portal-enroll/components/ProspectProvider/ProspectContext";
import {
  prospectReceived,
  setSignUpInfo,
} from "@portal-enroll/slices/signUpSlice";
import { googleAnalyticsDataAtom } from "@portal-shared/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking.atoms";
import { featureFlagUserIdAtom } from "@portal-shared/hooks/useFeatureFlagUserId/featureFlagUserId.atoms";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useSendFlagToDataLayer } from "@portal-shared/hooks/useSendFlagToDataLayer/useSendFlagToDataLayer";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { getBrandInformation } from "@portal-shared/services/brandInformation.service";
import { AllSupportedLocales } from "@portal-shared/types/LocaleTypes";
import { getLocale } from "@portal-shared/utils/getBrowserLocale";
import { captureMessage } from "@sentry/react";
import dayjs from "dayjs";
import { useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import React, { PropsWithChildren, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

interface ProspectProviderProps {}

interface ProspectProviderProps {
  createRetry?: number;
}

export const ProspectProvider = ({
  children,
  createRetry = 2,
}: PropsWithChildren<ProspectProviderProps>) => {
  useSendFlagToDataLayer("enrollmentPricingCampaign");

  const setFeatureFlagUserIdAtom = useSetAtom(featureFlagUserIdAtom);
  const [locale, setLocale] = useLocaleAtom();
  const [searchParams] = useSearchParams();

  const createProspectMutation = useCreateProspectMutation({
    mutationOptions: {
      retry: createRetry,
    },
  });
  const updateProspectMutation = useProspectUpdateMutation();
  const setGoogleAnalyticsDataAtom = useSetAtom(googleAnalyticsDataAtom);
  const dispatch = useDispatch();
  const {
    cookies: {
      _ga: googleClientIdCookie,
      ajs_anonymous_id: segmentAnonIdCookie,
      rhFeatureFlagUserId,
      rhProspectUuid,
    },
    cookies,
    removeCookie,
    setCookie,
  } = useEnrollCookies();
  const setSelectedOfferSnapshotFilter = useSetAtom(
    selectedOfferSnapshotFilterAtom
  );

  const { cookieDomain } = getBrandInformation();

  const prospectQuery = useProspectQuery();

  useEffect(() => {
    if (rhFeatureFlagUserId) {
      setFeatureFlagUserIdAtom(rhFeatureFlagUserId);
    } else if (prospectQuery.data && prospectQuery.data.featureFlagUserId) {
      setFeatureFlagUserIdAtom(prospectQuery.data.featureFlagUserId);
      setCookie("rhFeatureFlagUserId", prospectQuery.data.featureFlagUserId, {
        domain: cookieDomain,
        expires: dayjs().add(60, "day").toDate(),
      });
    }

    if (
      rhFeatureFlagUserId &&
      prospectQuery.data &&
      prospectQuery.data.featureFlagUserId &&
      rhFeatureFlagUserId !== prospectQuery.data.featureFlagUserId
    ) {
      captureMessage(
        "[Feature flags] - Feature flag Id mismatch between cookie and user",
        {
          extra: {
            cookieFeatureFlagUserId: rhFeatureFlagUserId,
            prospectFeatureFlagUserId: prospectQuery.data.featureFlagUserId,
          },
          level: "warning",
        }
      );
    }
  }, [
    cookieDomain,
    prospectQuery.data,
    rhFeatureFlagUserId,
    setCookie,
    setFeatureFlagUserIdAtom,
  ]);

  useEffect(() => {
    if (prospectQuery.data) {
      setGoogleAnalyticsDataAtom({
        prospectOfferSnapshotUuid:
          prospectQuery.data.offerSnapshotUuid || undefined,
        prospectUuid: prospectQuery.data.uuid,
        type: "loggedOut",
      });
    } else {
      setGoogleAnalyticsDataAtom(RESET);
    }
  }, [prospectQuery.data, setGoogleAnalyticsDataAtom]);

  useEffect(() => {
    if (
      rhProspectUuid ||
      Object.keys(cookies).length === 0 ||
      createProspectMutation.isPending
    ) {
      return;
    }

    const prospectCreateBody: ProspectCreateRequest = {
      acquisitionCampaign: searchParams.get("rh_campaign"),
      acquisitionContent: searchParams.get("rh_content"),
      acquisitionMedium: searchParams.get("rh_medium"),
      acquisitionSource: searchParams.get("rh_source"),
      featureFlagUserId:
        rhFeatureFlagUserId || searchParams.get("featureFlagUserId"),
      googleAnalyticsUserPseudoId: googleClientIdCookie,
      googleClientId: googleClientIdCookie,
      languagePreference: getLocale(searchParams.get("locale")) || locale,
      rcid: searchParams.get("rcid") ?? RhythmCampaignSlugs.Default,
      referralCode: searchParams.get("referralCode"),
      segmentAnonId: segmentAnonIdCookie,
      zipCode: searchParams.get("zipcode"),
    };

    const filteredParamsObject = Object.fromEntries(
      Object.entries(prospectCreateBody).filter(
        ([, value]) => value !== undefined || value !== null
      )
    );

    createProspectMutation.mutate(filteredParamsObject);
  }, [cookies]);

  useEffect(() => {
    if (searchParams.size === 0) {
      return;
    }

    // Non prospect fields
    if (searchParams.get("term_months_tab")) {
      setSelectedOfferSnapshotFilter(
        searchParams.get("term_months_tab") as OfferSnapshotFilterType
      );
    }

    if (searchParams.get("appSource")) {
      dispatch(
        setSignUpInfo({
          appSource: searchParams.get("appSource"),
        })
      );
    }
  }, [dispatch, searchParams, setSelectedOfferSnapshotFilter]);

  useEffect(() => {
    if (!rhProspectUuid) {
      return;
    }
    if (searchParams.size === 0) {
      return;
    }
    const languagePreference = (AllSupportedLocales as string[]).includes(
      searchParams.get("locale") || ""
    )
      ? searchParams.get("locale")
      : undefined;

    const prospectFields: ProspectUpdateRequestType = {
      acquisitionCampaign: searchParams.get("rh_campaign") || undefined,
      acquisitionContent: searchParams.get("rh_content") || undefined,
      acquisitionMedium: searchParams.get("rh_medium") || undefined,
      acquisitionSource: searchParams.get("rh_source") || undefined,
      featureFlagUserId:
        rhFeatureFlagUserId ||
        searchParams.get("featureFlagUserId") ||
        undefined,
      languagePreference,
      rcid: searchParams.get("rcid") || undefined,
      referralCode: searchParams.get("referralCode") || undefined,
      zipCode: searchParams.get("zipcode") || undefined,
    };

    const filteredParamsObject = Object.fromEntries(
      Object.entries(prospectFields).filter(
        ([, value]) => value !== undefined || value !== null
      )
    );

    if (Object.entries(filteredParamsObject).length > 0) {
      updateProspectMutation.mutate({
        data: filteredParamsObject,
      });
    }
  }, [searchParams, rhProspectUuid]);

  useEffect(() => {
    if (prospectQuery.isError) {
      removeCookie("rhProspectUuid");
    }
  }, [prospectQuery.isError, removeCookie]);

  useEffect(() => {
    const prospectData = prospectQuery.data;

    if (prospectData && !prospectQuery.isFetching) {
      dispatch(
        prospectReceived({
          ...prospectData,
          addressLine1: prospectData.addressLine1 ?? undefined,
          autopay: Boolean(prospectData.autopay),
          city: prospectData.city ?? undefined,
          eBillOnly: Boolean(prospectData.eBillOnly),
          phone: prospectData.phone || "",
          state: prospectData.state ?? undefined,
          unitNumber: prospectData.unitNumber ?? undefined,
          zipCode: prospectData.zipCode ?? undefined,
        })
      );

      if (prospectData.languagePreference) {
        setLocale(prospectData.languagePreference);
      }
    }
  }, [dispatch, prospectQuery.data, prospectQuery.isFetching, setLocale]);

  if (createProspectMutation.isError) {
    return <Error500Page />;
  }

  if (
    prospectQuery.isError ||
    prospectQuery.isPending ||
    createProspectMutation.isPending
  ) {
    return <RhCircularProgress />;
  }

  if (!rhProspectUuid) {
    return <RhCircularProgress />;
  }

  return (
    <ProspectContext.Provider value={prospectQuery.data}>
      {children}
    </ProspectContext.Provider>
  );
};
