import { connectApi } from "@common/services/ajax";
import { getEnv } from "@common/services/getEnvApplicationSettings.service";
import { FullStory, init as fullStoryInit } from "@fullstory/browser";
import { generateAxiosHeaders } from "@portal-shared/services/generateAxiosHeaders";
import {
  AXIOS_BASE_URL,
  AXIOS_TIMEOUT_MS,
  FullStoryConfig,
  GoogleTagManagerConfig,
  SENTRY_CONFIG,
} from "@portal-shared/settings/config";
import { fullStoryIntegration } from "@sentry/fullstory";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

export const initializePortalApp = () => {
  TagManager.initialize(GoogleTagManagerConfig);

  if (FullStoryConfig) {
    fullStoryInit(FullStoryConfig);
  }

  dayjs.extend(isToday);
  dayjs.extend(isTomorrow);

  connectApi({
    baseURL: AXIOS_BASE_URL,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      accept: "application/json",
      ...generateAxiosHeaders(),
    },
    timeout: AXIOS_TIMEOUT_MS,
  });

  if (SENTRY_CONFIG.dsn) {
    const integrations: Sentry.BrowserOptions["integrations"] = [
      Sentry.browserTracingIntegration(),
      Sentry.rewriteFramesIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        createRoutesFromChildren,
        matchRoutes,
        useEffect,
        useLocation,
        useNavigationType,
      }),
    ];

    if (SENTRY_CONFIG.orgSlug) {
      integrations.push(
        fullStoryIntegration(SENTRY_CONFIG.orgSlug, { client: FullStory })
      );
    }

    Sentry.init({
      dsn: SENTRY_CONFIG.dsn,
      environment: getEnv(),
      integrations,
      release: SENTRY_CONFIG.release,
      tracePropagationTargets: ["*.gotrhythm.com"],
      tracesSampleRate: SENTRY_CONFIG.tracesSampleRate,
    });
  }
};
