import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useGuestPayCreatePaymentMutation } from "@portal-guest/hooks/mutations/useGuestPayCreatePayment.mutation";
import { guestPayConfirmationPath } from "@portal-guest/routes/routePaths";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useCreatePaymentTranslations = () => {
  const { t } = useRhIntl();

  return {
    tPaymentError: t("QuestPayForm.paymentError"),
  };
};

export const useGuestPayFormCreatePayment = () => {
  const navigate = useNavigate();
  const flash = useRhFlash();
  const createPaymentMutation = useGuestPayCreatePaymentMutation();
  const { tPaymentError } = useCreatePaymentTranslations();

  const [makePaymentState, setMakePaymentState] = useState({
    createPayment: ({ amount, token }: { amount: string; token: string }) => {
      createPaymentMutation.mutate(
        {
          amount,
          token,
        },
        {
          onError: (error) => {
            flash.error(tPaymentError);
          },
          onSuccess: (response) => {
            navigate(guestPayConfirmationPath(), {
              state: {
                confirmationNumber: response.paymentNumber,
                remainingBalance: response.remainingBalance,
              },
            });
          },
        }
      );
    },
    isPaying: false,
  });

  useEffect(() => {
    setMakePaymentState((currentState) => {
      return {
        ...currentState,
        isPaying: createPaymentMutation.isPending,
      };
    });
  }, [createPaymentMutation.isPending]);

  return makePaymentState;
};
