import { RhButton } from "@design-system/components/RhButton/RhButton";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { selectedDevicesWithPremiseAtom } from "@portal-account/components/DevicePremiseSelector/DevicePremiseSelector.atoms";
import { chooseYourDeviceBrandStepAtom } from "@portal-account/components/DevicesEnrollChooseBrandForm/DevicesEnrollChooseBrandForm.atoms";
import {
  StyledBackLink,
  StyledButtonContainer,
} from "@portal-account/components/DevicesEnrollPageLayout/DevicesEnrollPageLayout.styled";
import { AlreadyConnectedDevice } from "@portal-account/components/DevicesEnrollSelectDevicesForm/AlreadyConnectedDevice";
import { RESIDEO_SESSION_STORAGE_KEY } from "@portal-account/components/DevicesEnrollSelectDevicesForm/constants";
import { devicesEnrollSelectDevicesFormTranslations } from "@portal-account/components/DevicesEnrollSelectDevicesForm/DevicesEnrollSelectDevicesForm.en.i18n";
import { StyledContainer } from "@portal-account/components/DevicesEnrollSelectDevicesForm/DevicesEnrollSelectDevicesForm.styled";
import { EnrolledDevice } from "@portal-account/components/DevicesEnrollSelectDevicesForm/EnrolledDevice";
import { SelectDevice } from "@portal-account/components/DevicesEnrollSelectDevicesForm/SelectDevice";
import { useAttachResideoDevicesMutation } from "@portal-account/hooks/mutations/useAttachResideoDevices.mutation";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { devicesEnrollStepPath } from "@portal-account/routes/routePaths";
import {
  ActionType,
  DeviceEnrollmentEvents,
} from "@portal-account/services/segment.service";
import {
  DeviceSelected,
  ResideoDeviceListStorageType,
} from "@portal-account/types/devicesTypes";
import { TrackingView } from "@portal-shared/components/TrackingView/TrackingView";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useAtomValue, useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

export const DevicesEnrollSelectDevicesForm = () => {
  const { translate } = useTranslations();
  const navigate = useNavigate();
  const track = useTrackMyAccountEvents();
  const flash = useRhFlash();
  const customer = useCustomerFromContext();
  const [resideoDeviceListFromStorage, , clearResideoDeviceListFromStorage] =
    useSessionStorage<ResideoDeviceListStorageType | null>(
      RESIDEO_SESSION_STORAGE_KEY,
      null
    );
  const [, clearSearchParams] = useSearchParams();
  const selectedDevicesWithPremise = useAtomValue(
    selectedDevicesWithPremiseAtom
  );
  const resetDeviceBrand = useSetAtom(chooseYourDeviceBrandStepAtom);
  const useAttachResideoDevices = useAttachResideoDevicesMutation();

  const deviceBrand = resideoDeviceListFromStorage?.deviceBrand;
  const userId = resideoDeviceListFromStorage?.userId;
  const devices = resideoDeviceListFromStorage?.thermostats ?? [];

  const {
    tDevicesEnrollSelectDevicesFormCancel,
    tDevicesEnrollSelectDevicesFormErrorSubmitting,
    tDevicesEnrollSelectDevicesFormSubmit,
    tDevicesEnrollSelectDevicesFormSubmitting,
  } = translate(devicesEnrollSelectDevicesFormTranslations);

  const resetFlow = () => {
    resetDeviceBrand(RESET);
    clearSearchParams(undefined);
    clearResideoDeviceListFromStorage();
  };

  const submitSelectedDevicesToAttach = async () => {
    if (selectedDevicesWithPremise && deviceBrand && userId) {
      const selectedDevicesToAttach: DeviceSelected[] =
        selectedDevicesWithPremise.map(({ device, premise }) => ({
          deviceId: device.deviceId,
          premiseId: premise.id,
        }));

      try {
        await useAttachResideoDevices.mutateAsync(
          {
            deviceBrand,
            thermostats: selectedDevicesToAttach,
            userId,
          },
          {
            onError: () => {
              flash.error(tDevicesEnrollSelectDevicesFormErrorSubmitting);
            },
            onSuccess: () => {
              track({
                action: ActionType.click,
                category: "devices.enroll.select-devices",
                devices: JSON.stringify({ ...selectedDevicesToAttach }),
                event: DeviceEnrollmentEvents.DevicesSelected,
              });

              resetFlow();

              return navigate(devicesEnrollStepPath("summary"));
            },
          }
        );
      } catch (error) {
        flash.error(tDevicesEnrollSelectDevicesFormErrorSubmitting);
      }
    }
  };

  return (
    <StyledContainer>
      <TrackingView
        data={{
          brand: deviceBrand ?? "",
          devices: devices.map((device) => {
            return {
              alreadyConnected: device.alreadyConnected,
              name: device.deviceName,
            };
          }),
          devicesCount: devices.length,
          event: "Customer viewing device select form",
        }}
      />
      {devices.map((device) => {
        if (device.alreadyConnected && !device.premiseId) {
          return (
            <AlreadyConnectedDevice key={device.deviceId} device={device} />
          );
        }

        if (device.premiseId) {
          return (
            <EnrolledDevice
              key={device.deviceId}
              device={device}
              premises={customer?.premises}
            />
          );
        }

        return (
          <SelectDevice
            key={device.deviceId}
            device={device}
            premises={customer?.premises}
          />
        );
      })}
      <StyledButtonContainer>
        <RhButton
          data-tracking-click={{
            event: "Customer enrolling thermostat devices",
          }}
          disabled={
            useAttachResideoDevices.isPending ||
            selectedDevicesWithPremise.length === 0
          }
          onClick={submitSelectedDevicesToAttach}
          fullWidth={false}
        >
          {useAttachResideoDevices.isPending
            ? tDevicesEnrollSelectDevicesFormSubmitting
            : tDevicesEnrollSelectDevicesFormSubmit}
        </RhButton>
        <StyledBackLink
          to={devicesEnrollStepPath("choose-your-device-brand")}
          onClick={resetFlow}
        >
          {tDevicesEnrollSelectDevicesFormCancel}
        </StyledBackLink>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
