import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledSignUpSSNRequiredPageSSNForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(4)};
  margin-top: ${rhSpacingPx(4)};
  width: 350px;
`;

export const StyledSignUpSSNRequirePagedRadioContainers = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1.5)};
`;
