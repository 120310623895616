import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import {
  GenerationPriceTierSnapshotType,
  PriceTierSnapshotType,
} from "@portal-shared/types/priceTierSnapshotTypes";
import React from "react";
import styled from "styled-components";

const StyledLegendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  width: 100%;
`;

const StyledLegendItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const StyledLegendItemColor = styled.div`
  border-radius: 60px;
  width: 8px;
`;

const StyledLegendItemCopy = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
`;

const StyledRate = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhSpacingPx(1)};

  &:not(:last-of-type) {
    border-bottom: 1px solid ${CSSVariables.COLOR_GREY_100};
  }
`;

const StyledLegendItemBody = styled.div`
  display: flex;
  gap: ${rhSpacingPx(1)};
`;

const StyledTitle = styled(RhTypography)`
  max-width: 60px;
`;

interface PriceScheduleBreakdownLegendProps {
  buybackRateLabel: string;
  chargeRateLabel: string;
  generationPriceTierSnapshots: GenerationPriceTierSnapshotType[] | null;
  priceTierSnapshots: PriceTierSnapshotType[] | null;
}

export const PriceScheduleBreakdownLegend = ({
  buybackRateLabel,
  chargeRateLabel,
  generationPriceTierSnapshots,
  priceTierSnapshots,
}: PriceScheduleBreakdownLegendProps) => {
  const { portalChargeRate, portalSolarBuyBack } = useBrandImageUrls();

  if (!priceTierSnapshots) {
    return null;
  }

  return (
    <StyledLegendContainer>
      {priceTierSnapshots
        .sort(
          (a, b) =>
            Number(a.consumptionPricePerKwh) - Number(b.consumptionPricePerKwh)
        )
        .map((snapshot) => {
          const { name, color, consumptionPricePerKwh } = snapshot;

          const generationSnapshot = generationPriceTierSnapshots?.find(
            (gSnapshot) => gSnapshot.name === name
          );

          return (
            <StyledLegendItem key={name}>
              <RhTypography variant="subtitle2" fontWeight="Bold">
                {name}
              </RhTypography>
              <StyledLegendItemBody>
                <StyledLegendItemColor
                  style={{
                    backgroundColor: color,
                  }}
                />
                <StyledLegendItemCopy>
                  <StyledRate>
                    {generationSnapshot ? (
                      <img src={portalChargeRate} alt="" aria-hidden />
                    ) : null}
                    <StyledTitle>{chargeRateLabel}</StyledTitle>
                    <RhTypography variant="h3">
                      {formatDollarsToCents(consumptionPricePerKwh)}
                    </RhTypography>
                  </StyledRate>
                  {generationSnapshot ? (
                    <StyledRate>
                      <img src={portalSolarBuyBack} alt="" aria-hidden />
                      <StyledTitle>{buybackRateLabel}</StyledTitle>
                      <RhTypography variant="h3">
                        {formatDollarsToCents(
                          generationSnapshot.generationPricePerKwh
                        )}
                      </RhTypography>
                    </StyledRate>
                  ) : null}
                </StyledLegendItemCopy>
              </StyledLegendItemBody>
            </StyledLegendItem>
          );
        })}
    </StyledLegendContainer>
  );
};
