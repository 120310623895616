import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacing } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled, { css } from "styled-components";

const cssVars = css`
  --callout-height: 32px;
  --default-spacing: ${rhSpacing(1)}px;
  --purple: ${CSSVariables.COLOR_PRIMARY_MAIN};
  --white: ${CSSVariables.COLOR_WHITE};
  --card-padding: ${rhSpacing(3)}px;
  --grey-border: 1px solid ${CSSVariables.COLOR_GREY_100};
  --card-radius: 6px;
`;

export const StyledArticle = styled.article`
  ${cssVars}
  border-radius: var(--card-radius);
  box-shadow:
    0 7px 30px -10px rgba(150, 170, 180, 0.5),
    inset 0px 0px 0px 1px rgba(0, 0, 0, 0.075);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 100%;
  padding: var(--card-padding);
  position: relative;
  width: 100%;

  &[data-current-plan="true"] {
    box-shadow:
      inset 0px 0px 0px 1px var(--purple),
      0 7px 30px -10px rgba(150, 170, 180, 0.5);
  }

  &[data-selected="true"] {
    box-shadow: inset 0px 0px 0px 1px var(--purple);
  }

  & > :not(:last-child) {
    margin-bottom: 16px;
  }

  & > :last-child {
    align-self: center;
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    max-width: unset;
  }
`;

export const Spacer = styled.div`
  height: 100%;
`;

export const CurrentPlanCallout = styled.div`
  ${cssVars}
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px 6px 0 0;
  color: var(--white);
  display: flex;
  height: var(--callout-height);
  margin-left: calc(var(--card-padding) * -1);
  padding-left: 24px;
  position: absolute;
  top: calc(var(--callout-height) * -1);
  width: 100%;
`;

export const StyledSummary = styled.summary`
  ${cssVars}
  color: var(--purple);
  font-size: 14px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledDetail = styled.details`
  ${cssVars}
  &[open] ${StyledSummary} {
    margin-bottom: 16px;
  }
  &[open] ${StyledSummary}::after {
    content: attr(data-open);
  }
  &:not([open]) ${StyledSummary}::after {
    content: attr(data-close);
  }
`;

export const PriceAndTermsContainer = styled.div`
  align-items: center;
  border-bottom: var(--grey-border);
  border-top: var(--grey-border);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > * {
    flex: 1;
    height: 100%;
    padding: 1.25rem 0;
  }

  & > :first-child {
    border-right: var(--grey-border);
  }
`;

export const PriceContainer = styled.span`
  align-items: center;
  display: inline-flex;
  padding-right: 1rem;

  & > p[data-desktop] {
    display: none;
  }

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    & > p[data-mobile] {
      display: none;
    }

    & > p[data-desktop] {
      display: unset;
    }
  }
`;

export const StyledButton = styled(RhButton)`
  &.MuiButton-root {
    width: 200px;
  }
`;

export const StyledListItem = styled.li`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;

  & > img {
    height: 24px;
    width: 24px;
  }
`;

export const StyledUnorderedList = styled.ul`
  border-top: var(--grey-border);
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: armenian;
  padding-top: 16px;
`;

export const ProductTierCardPricePerFrequency = styled(RhTypography)`
  white-space: nowrap;
`;
