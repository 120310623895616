import { RhButton } from "@design-system/components/RhButton/RhButton";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledGetStartedButton = styled(RhButton)`
  &.MuiButton-root {
    margin-top: ${rhSpacingPx(4)};
    width: 100%;

    @media (min-width: ${RhythmBreakpoints.SM}px) {
      width: 330px;
    }
  }
`;
