import { AppBrands } from "@common/enums/appBrands.enum";
import { getAppBrand } from "@common/services/getEnvApplicationSettings.service";
import { CreditCheckStatus } from "@enroll-utils/types/creditCheckTypes";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { getStepAfterCreditCheckNotApproved } from "@portal-enroll/utils/getStepAfterCreditCheckNotApproved";
import { useCallback } from "react";
import { useSelector } from "react-redux";

export const useGetSignUpBillingPreferencesNextStepV2 = () => {
  const signUpState = useSelector(selectSignUpState);
  const { hasCustomerEmailConflict } = useProspectFromContext();
  const appBrand = getAppBrand();

  const getBillingPreferencesNextStepV2 = useCallback(
    ({ autopay }: { autopay: boolean }) => {
      const { creditCheckResponseV2 } = signUpState;

      if (
        creditCheckResponseV2 &&
        creditCheckResponseV2?.status !== CreditCheckStatus.APPROVED
      ) {
        return getStepAfterCreditCheckNotApproved(creditCheckResponseV2);
      } else if (hasCustomerEmailConflict) {
        return "provide-additional-information";
      } else if (autopay) {
        return "payment";
      } else {
        return appBrand === AppBrands.ENERGY_TEXAS ? "summary" : "add-ons";
      }
    },
    [appBrand, signUpState, hasCustomerEmailConflict]
  );

  return { getBillingPreferencesNextStepV2 };
};
