import { prospectApi } from "@common/api/prospectApi";
import { FeatureFlagMetric } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.types";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useCreateCreditCheckMutation } from "@enroll-data/hooks/mutations/useCreateCreditCheck.mutation";
import { CreditCheckProgress } from "@enroll-utils/types/prospectTypes";
import { enrollConfirmAddressFormTranslations } from "@portal-enroll/components/EnrollConfirmAddressForm/EnrollConfirmAddressForm.en.i18n";
import { StyledConfirmButton } from "@portal-enroll/components/EnrollConfirmAddressForm/EnrollConfirmAddressForm.styled";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import {
  ActionType,
  CategoryType,
  EnrollmentEvents,
  track,
} from "@portal-enroll/services/segment.service";
import { formatCreditCheckForTracking } from "@portal-enroll/utils/formatCreditCheckForTracking";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const EnrollConfirmAddressForm = () => {
  const { translate } = useTranslations();

  const { tEnrollConfirmAddressFormConfirm, tEnrollConfirmAddressFormError } =
    translate(enrollConfirmAddressFormTranslations);

  const {
    creditCheckProgress,
    addressLine1,
    unitNumber,
    state,
    zipCode,
    city,
    id,
    email,
  } = useProspectFromContext();
  const flash = useRhFlash();
  const { signUpClickNextStepHandler } = useSignUpFlow();

  const { featureFlagClient } = useFeatureFlagClient();

  const createCreditCheckMutation = useCreateCreditCheckMutation();

  const handleAddressConfirm = () => {
    prospectApi.trackEnteredSignup(id, email || "");

    if (
      !creditCheckProgress ||
      creditCheckProgress === CreditCheckProgress.NOT_STARTED ||
      creditCheckProgress === CreditCheckProgress.STALE
    ) {
      createCreditCheckMutation.mutate(
        {},
        {
          onError: () => flash.error(tEnrollConfirmAddressFormError),
          onSuccess: (response) => {
            track({
              action: ActionType.clickedNextPage,
              category: "confirm-address.creditCheckComplete",
              event: EnrollmentEvents.enrollmentClick,
              label: "Credit Worthiness",
              ...formatCreditCheckForTracking(response),
            });

            track({
              action: ActionType.premiseSubmitted,
              addressLine1: addressLine1 || "",
              category: CategoryType.Premise,
              city: city || "",
              event: EnrollmentEvents.enrollmentClick,
              label: "Premise Information",
              state: state || "",
              unitNumber: unitNumber || "",
              zipCode: zipCode || "",
            });

            featureFlagClient.trackMetric(
              FeatureFlagMetric.PortalProspectServiceAddressSubmitted
            );

            signUpClickNextStepHandler({
              nextStep: "details",
              signUpData: {
                creditCheckResponseV2: response,
              },
              track: true,
            });
          },
        }
      );
    } else {
      signUpClickNextStepHandler({
        nextStep: "details",
        track: true,
      });
    }
  };

  return (
    <StyledConfirmButton
      size="large"
      disabled={createCreditCheckMutation.isPending}
      onClick={handleAddressConfirm}
      data-tracking-click={{ event: "User confirms address" }}
    >
      {tEnrollConfirmAddressFormConfirm}
    </StyledConfirmButton>
  );
};
