import { featureFlagSetAtom } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.atoms";
import { FeatureFlagValues } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.types";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { useFeatureFlagUserId } from "@portal-shared/hooks/useFeatureFlagUserId/useFeatureFlagUserId";
import { sentFlagToDataLayerAtom } from "@portal-shared/hooks/useSendFlagToDataLayer/useSendFlagToDataLayer.atom";
import { useAtom, useAtomValue } from "jotai";
import { kebabCase } from "lodash";
import { useEffect } from "react";

export const useSendFlagToDataLayer = (flagName: keyof FeatureFlagValues) => {
  const [sentFlagToDataLayer, setSentFlagToDataLayer] = useAtom(
    sentFlagToDataLayerAtom
  );
  const { featureFlagClient } = useFeatureFlagClient();
  const featureFlagUserId = useFeatureFlagUserId();
  const flags = useAtomValue(featureFlagSetAtom);

  useEffect(() => {
    const flagValue = flags[flagName];

    if (
      !featureFlagClient.isFetchingFlags() &&
      !sentFlagToDataLayer[flagName] &&
      flagValue &&
      featureFlagUserId
    ) {
      window.dataLayer.push({
        event: "feature_flag",
        feature_flag_user_id: featureFlagUserId,
        flag_name: kebabCase(flagName),
        flag_variation: `${flagValue}`,
      });

      setSentFlagToDataLayer((prev) => ({
        ...prev,
        [flagName]: true,
      }));
    }
  }, [
    flagName,
    flags,
    sentFlagToDataLayer,
    setSentFlagToDataLayer,
    featureFlagClient,
    featureFlagUserId,
  ]);
};
