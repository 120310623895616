import { usePortalSharedCookies } from "@portal-shared/hooks/usePortalSharedCookies";
import { signOutPath } from "@portal-shared/routes/routePaths";
import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface SessionGuardProps {}

export const SessionGuard = (props: PropsWithChildren<SessionGuardProps>) => {
  const { children } = props;
  const navigate = useNavigate();
  const {
    cookies: { portal_session_state: portalSessionState },
    cookiesLoading,
  } = usePortalSharedCookies();

  const checkLogout = useCallback(() => {
    if (cookiesLoading) {
      return;
    }

    if (portalSessionState !== "logged_in") {
      navigate(signOutPath());
    }
  }, [cookiesLoading, portalSessionState]);

  useEffect(() => {
    // Execute checkLogout immediately once
    checkLogout();

    const oneMinuteInMilliseconds = 60 * 1000;
    const checkLogoutIntervalId = setInterval(
      checkLogout,
      oneMinuteInMilliseconds
    );

    return () => clearInterval(checkLogoutIntervalId);
  }, [checkLogout]);

  return <>{children}</>;
};
