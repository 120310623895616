import { IdType, UUIDType } from "@common/types/apiTypes";
import { CampaignTypeEnum } from "@portal-shared/types/campaignTypes";
import { PriceScheduleBreakdownVisualType } from "@portal-shared/types/priceScheduleBreakdownVisualTypes";
import {
  GenerationPriceTierSnapshotType,
  PriceTierSnapshotType,
} from "@portal-shared/types/priceTierSnapshotTypes";

export enum PriceMethodTypes {
  Fixed = "FIXED",
  Margin = "MARGIN",
}

export enum RecProduct {
  Any = "AnyREC/100%",
  Chapman100 = "Chapman/100%",
  Solar100 = "Solar/100%",
  TXWind100 = "TXWind/100%",
}

export interface OfferSnapshotTags {
  calloutColor?: string | null;
  calloutEn?: string | null;
  calloutEs?: string | null;
}

export interface OfferPromoType {
  code: string;
  expirationDate: string | null;
  futureValue: number | null;
  id: IdType;
  presentationValue: number | null;
  value: number;
}

export interface OfferSnapshotType {
  active: boolean;
  autopayPaperlessMonthlyDiscount?: string | null;
  baseChargeAmount: string;
  campaignId: IdType;
  campaignSlug: string;
  campaignType: CampaignTypeEnum;
  descriptionEn: string;
  descriptionEs: string;
  dunsNumber: string;
  endDate?: string;
  etfAmount: string;
  generationPriceScheduleBreakdown: PriceScheduleBreakdownVisualType[] | null;
  generationPriceTierSnapshots: GenerationPriceTierSnapshotType[] | null;
  grossMargin: number | null;
  id: IdType;
  longDescriptionEn: string;
  longDescriptionEs: string;
  offerLabels: string[] | null;
  offerPromoId: IdType | null;
  planId: IdType;
  price: string;
  price1000Kwh: string;
  price2000Kwh: string;
  price500Kwh: string;
  priceMethod: PriceMethodTypes;
  pricePerMwh: number | null;
  priceScheduleBreakdown: PriceScheduleBreakdownVisualType[] | null;
  priceTierSnapshots: PriceTierSnapshotType[] | null;
  priority?: string;
  promo: OfferPromoType | null;
  recProduct: RecProduct;
  rhythmAverageKwhRate: string;
  rhythmKwhRate: string;
  solarCreditKwhRate: string;
  solarEligible: boolean;
  solarGenerationCapped: boolean;
  solarGenerationToConsumptionRatio: string | number | null;
  startDate: string;
  tags: OfferSnapshotTags | null;
  tdspKwhRate: string;
  tdspMonthlyCharge: string;
  termMonths: number;
  termsOfServiceEn: string;
  termsOfServiceEs: string;
  thermostatManagementMonthlyDiscount: string | null | undefined;
  title: string;
  uuid: UUIDType;
  yracEnUrl: string;
  yracEsUrl: string;
  zuoraRatePlanId: IdType;
}
