import { prospectApi } from "@common/api/prospectApi";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { maskSSN, unmaskSSN } from "@common/forms/ssn.mask";
import {
  CreditEvaluationDefinition,
  determineCreditEvaluationDefinition,
} from "@common/services/creditEvaluation.service";
import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { RhApiError } from "@common/types/errorTypes";
import { ProspectCreditCheckRequestType } from "@common/types/prospectTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useCreateCreditCheckMutation } from "@enroll-data/hooks/mutations/useCreateCreditCheck.mutation";
import { SignUpStepType } from "@enroll-utils/constants/routePaths";
import { CreditCheckStatus } from "@enroll-utils/types/creditCheckTypes";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { RunCreditCheckType } from "@portal-enroll/pages/SignUpSSNRequiredPage/RunCreditCheckType.enum";
import { signUpSSNRequiredPageTranslations } from "@portal-enroll/pages/SignUpSSNRequiredPage/SignUpSSNRequiredPage.en.i18n";
import {
  StyledSignUpSSNRequirePagedRadioContainers,
  StyledSignUpSSNRequiredPageSSNForm,
} from "@portal-enroll/pages/SignUpSSNRequiredPage/SignUpSSNRequiredPage.styled";
import {
  selectCreditCheckValues,
  selectProspectId,
  selectSignUpAcquisition,
  selectSignUpState,
} from "@portal-enroll/selectors/signUpSelectors";
import {
  ActionType,
  EnrollmentEvents,
  track,
} from "@portal-enroll/services/segment.service";
import { getStepAfterCreditCheckNotApproved } from "@portal-enroll/utils/getStepAfterCreditCheckNotApproved";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { PortalInput } from "@portal-shared/components/PortalInput/PortalInput.styled";
import { PortalInputErrorMessage } from "@portal-shared/components/PortalInputErrorMessage/PortalInputErrorMessage.styled";
import { PortalInputLabel } from "@portal-shared/components/PortalInputLabel/PortalInputLabel.styled";
import {
  PortalRadioButton,
  PortalRadioLabel,
} from "@portal-shared/components/PortalRadioButton/PortalRadioButton";
import { isValidSSN } from "@portal-shared/forms/validators";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { FORM_ERROR } from "final-form";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

interface SSNFormValues {
  runCreditCheckType: RunCreditCheckType;
  ssn: string;
}

export const SignUpSSNRequiredPage = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const customerContactValues = useSelector(selectCreditCheckValues);
  const prospectId = useSelector(selectProspectId);
  const { acquisitionMedium } = useSelector(selectSignUpAcquisition);
  const flash = useRhFlash();

  const [isCreditCheckPending, setIsCreditCheckPending] = useState(false);

  const {
    depositAmount,
    ssnRequired,
    autopay,
    creditEvaluation,
    creditCheckResponseV2,
  } = useSelector(selectSignUpState);
  const {
    register,
    formState,
    setValue,
    watch,
    handleSubmit: handleSubmitTemp,
  } = useForm<SSNFormValues>({
    defaultValues: {
      runCreditCheckType: RunCreditCheckType.RunCheck,
      ssn: "",
    },
    mode: "onChange",
  });
  const ssnValue = watch("ssn");
  const runCreditCheckValue = watch("runCreditCheckType");

  const { isValid, errors } = formState;

  const { featureFlagClient } = useFeatureFlagClient();

  const { prospectCreditFlowV2 } = featureFlagClient.getFlags([
    ["prospectCreditFlowV2", false],
  ]);

  const createCreditCheckMutation = useCreateCreditCheckMutation();

  const { translate } = useTranslations();
  const {
    tSignUpSSNRequiredPageCreditScoreApiError,
    tSignUpSSNRequiredPageNext,
    tSignUpSSNRequiredPageSocialSecurityNumber,
    tSignUpSSNRequiredPageTimeForQuickCreditCheck,
    tSignUpSSNRequiredPageVerifyYourIdentity,
    tSignUpSSNRequiredPageRunCreditCheck,
    tSignUpSSNRequiredPageSkipCreditCheck,
  } = translate(signUpSSNRequiredPageTranslations);

  const creditEvaluationDefinition = determineCreditEvaluationDefinition({
    creditEvaluation,
    depositAmount,
    ssnRequired,
  });

  const nextStep = useCallback(
    (outcome: string, newDepositAmount: number | null): SignUpStepType => {
      if (outcome === CreditScoreOutcome.manualReview) {
        return "call-us";
      } else if (newDepositAmount) {
        return "deposit";
      } else if (
        !autopay &&
        creditEvaluationDefinition ===
          CreditEvaluationDefinition.ApprovedNoDeposit
      ) {
        return "summary";
      } else {
        return "payment";
      }
    },
    [autopay, creditEvaluationDefinition]
  );

  const handleCreditCheckV2 = (ssn: string) => {
    createCreditCheckMutation.mutate(
      { ssn },
      {
        onError: () => flash.error(tSignUpSSNRequiredPageCreditScoreApiError),
        onSuccess: (response) => {
          const nextStepv2 =
            response.status === CreditCheckStatus.APPROVED
              ? "summary"
              : getStepAfterCreditCheckNotApproved(response);

          track({
            action: ActionType.clickedNextPage,
            category: "credit-check.creditCheckComplete",
            event: EnrollmentEvents.enrollmentClick,
            label: "Credit Worthiness",
            value: response.status,
          });

          signUpClickNextStepHandler({
            nextStep: nextStepv2,
            signUpData: {
              creditCheckResponseV2: response,
            },
            track: true,
          });
        },
      }
    );
  };

  const onSubmit = (data: SSNFormValues) => {
    const { runCreditCheckType } = data;
    const ssn = unmaskSSN(data.ssn);

    if (prospectCreditFlowV2.value) {
      return handleCreditCheckV2(ssn);
    }

    if (runCreditCheckType === RunCreditCheckType.SkipCheck) {
      signUpClickNextStepHandler({
        nextStep: nextStep("", depositAmount),
        track: true,
      });
    } else {
      const contactValuesWithSSN: ProspectCreditCheckRequestType = {
        ...customerContactValues,
        acquisitionMedium,
        prospectId,
        ssn,
      };

      setIsCreditCheckPending(true);

      return prospectApi
        .creditScoreEvaluation(contactValuesWithSSN)
        .then(({ depositAmount: newDepositAmount, outcome, ssnProvided }) => {
          if (outcome) {
            const deposit = {
              depositAmount: newDepositAmount ?? 0,
              depositRequired: Boolean(newDepositAmount),
            };

            track({
              action: ActionType.clickedNextPage,
              category: "credit-check.creditCheckComplete",
              event: EnrollmentEvents.enrollmentClick,
              label: "Credit Worthiness",
              value: determineCreditEvaluationDefinition({
                creditEvaluation: outcome,
                depositAmount: newDepositAmount,
              }),
              ...deposit,
              ssnProvided,
            });
          }

          signUpClickNextStepHandler({
            nextStep: nextStep(outcome, newDepositAmount),
            signUpData: {
              creditEvaluation: outcome,
              depositAmount: newDepositAmount,
            },
            track: true,
          });
        })
        .catch((_error: RhApiError) => {
          flash.error(tSignUpSSNRequiredPageCreditScoreApiError);

          return { [FORM_ERROR]: [tSignUpSSNRequiredPageCreditScoreApiError] };
        })
        .finally(() => {
          setIsCreditCheckPending(false);
        });
    }
  };

  const showDepositRadioGroup = prospectCreditFlowV2.value
    ? creditCheckResponseV2?.requirements.find(
        (req) => req.requirementType === "DEPOSIT"
      )
    : creditEvaluation !== CreditScoreOutcome.manualReview;

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue("runCreditCheckType", event.target.value as RunCreditCheckType, {
      shouldValidate: true,
    });

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerText={tSignUpSSNRequiredPageTimeForQuickCreditCheck}
        subHeaderText={tSignUpSSNRequiredPageVerifyYourIdentity}
      />
      <StyledSignUpSSNRequiredPageSSNForm onSubmit={handleSubmitTemp(onSubmit)}>
        <PortalInputLabel>
          {tSignUpSSNRequiredPageSocialSecurityNumber}
          <PortalInput
            {...register("ssn", {
              validate: (value) => {
                if (runCreditCheckValue === RunCreditCheckType.SkipCheck) {
                  return true;
                }
                return isValidSSN(unmaskSSN(value));
              },
            })}
            autoComplete="ssn"
            aria-invalid={Boolean(errors.ssn)}
            placeholder="123-45-6789"
            inputMode="numeric"
            value={maskSSN(ssnValue)}
            onChange={(e) =>
              setValue("ssn", e.target.value, {
                shouldValidate: true,
              })
            }
            onFocus={() =>
              trackEvent({ action: ActionType.focus, label: "ssn" })
            }
          />
          {errors.ssn && (
            <PortalInputErrorMessage>
              {errors.ssn.message}
            </PortalInputErrorMessage>
          )}
        </PortalInputLabel>
        {showDepositRadioGroup ? (
          <StyledSignUpSSNRequirePagedRadioContainers>
            <PortalRadioLabel>
              <PortalRadioButton
                type="radio"
                value={RunCreditCheckType.RunCheck}
                {...register("runCreditCheckType")}
                onChange={handleRadioChange}
              />
              {tSignUpSSNRequiredPageRunCreditCheck}
            </PortalRadioLabel>
            <PortalRadioLabel>
              <PortalRadioButton
                type="radio"
                value={RunCreditCheckType.SkipCheck}
                {...register("runCreditCheckType")}
                onChange={handleRadioChange}
              />
              {tSignUpSSNRequiredPageSkipCreditCheck}
            </PortalRadioLabel>
          </StyledSignUpSSNRequirePagedRadioContainers>
        ) : null}
        <RhButton
          data-tracking-click={{ event: "User submits SSN" }}
          size="large"
          fullWidth
          type="submit"
          disabled={
            !isValid ||
            (prospectCreditFlowV2.value
              ? createCreditCheckMutation.isPending
              : isCreditCheckPending)
          }
        >
          {tSignUpSSNRequiredPageNext}
        </RhButton>
      </StyledSignUpSSNRequiredPageSSNForm>
    </SignUpPageLayout>
  );
};
