import { api } from "@common/api/api";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { NotificationMessagesResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import {
  ChannelSubscriptionStatus,
  IterableOptionType,
} from "@common/types/notificationTypes";
import { useEffect } from "react";

export const useNotificationFetchOptions = (customerId?: IdType) => {
  const [{ requestMonitor, data }, { setFailure, setSuccess, setPending }] =
    useAjaxState<
      [NotificationMessagesResponseType, ChannelSubscriptionStatus]
    >();

  useEffect(() => {
    if (customerId) {
      setPending();
      const channels = api.customers.notificationMessages.list(customerId);
      const subscriptions = api.customers.unsubscribedChannelIds(customerId);

      Promise.all([channels, subscriptions]).then(setSuccess).catch(setFailure);
    }
  }, [customerId]);

  if (!data) {
    return {
      notificationOptions: null,
      requestMonitor,
      unsubscribedChannelIds: null,
    };
  }

  const [notificationChannels, { unsubscribedChannelIds }] = data;

  // TODO: fetch notificationOptions from iterable when channel and message patterns are established
  // this is a hardcoded list for now with plans to fetch and construct it from Iterable in the future
  // If you change these ID's make sure to check the backend (`iterable_types.py`) and change there
  // at the same time.

  const notificationOptions: IterableOptionType[] = [
    {
      channelNameIntlId: "NotificationOptionSwitches.myBillIsHigher",
      commMedium: "email",
      messageId: notificationChannels?.emailBillSpike,
      subscriptionGroup: "messageType",
    },
    {
      channelNameIntlId: "NotificationOptionSwitches.myWeeklyEnergySummary",
      commMedium: "email",
      messageId: notificationChannels?.emailWeeklySummary,
      subscriptionGroup: "messageType",
    },
    {
      channelNameIntlId: "NotificationOptionSwitches.sendMarketingPromos",
      commMedium: "email",
      messageId: notificationChannels?.emailMarketingAndPromos,
      subscriptionGroup: "messageType",
    },
    {
      channelNameIntlId: "NotificationOptionSwitches.myBillIsAvailable",
      commMedium: "sms",
      messageId: notificationChannels?.smsBillingAndPayments,
      subscriptionGroup: "messageType",
    },
    {
      channelNameIntlId: "NotificationOptionSwitches.myBillIsHigher",
      commMedium: "sms",
      messageId: notificationChannels?.smsBillSpike,
      subscriptionGroup: "messageType",
    },
    {
      channelNameIntlId: "NotificationOptionSwitches.myWeeklyEnergySummary",
      commMedium: "sms",
      messageId: notificationChannels?.smsWeeklySummary,
      subscriptionGroup: "messageType",
    },
  ].filter((option) => option.messageId != null) as IterableOptionType[]; // NOTE: this order is intentional. check with product before changing this order

  return { notificationOptions, requestMonitor, unsubscribedChannelIds };
};
