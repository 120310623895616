import { Player } from "@lottiefiles/react-lottie-player";
import {
  LoadingOverlayContainer,
  LoadingOverlayWrapper,
} from "@portal-account/components/LoadingOverlay/LoadingOverlay.styled";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import React from "react";

export const LoadingOverlay = () => {
  const { portalLoaderLottie } = useBrandImageUrls();

  return (
    <LoadingOverlayContainer role="progressbar">
      <LoadingOverlayWrapper>
        <Player
          src={portalLoaderLottie}
          autoplay
          loop
          rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
        />
      </LoadingOverlayWrapper>
    </LoadingOverlayContainer>
  );
};
