import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { DevicesEnrollPageLayout } from "@portal-account/components/DevicesEnrollPageLayout/DevicesEnrollPageLayout";
import {
  RESIDEO_API_CODE_PARAM,
  RESIDEO_API_STATE_PARAM,
  RESIDEO_SESSION_STORAGE_KEY,
} from "@portal-account/components/DevicesEnrollSelectDevicesForm/constants";
import { DevicesEnrollSelectDevicesForm } from "@portal-account/components/DevicesEnrollSelectDevicesForm/DevicesEnrollSelectDevicesForm";
import { useListResideoDevicesQuery } from "@portal-account/hooks/queries/useListResideoDevices.query";
import { resideoAuthQueriedOnceAtom } from "@portal-account/pages/DevicesEnrollSelectDevicesPage/DevicesEnrollSelectDevicesPage.atoms";
import { devicesEnrollSelectDevicesPageTranslations } from "@portal-account/pages/DevicesEnrollSelectDevicesPage/DevicesEnrollSelectDevicesPage.en.i18n";
import { StyledLoadingContainer } from "@portal-account/pages/DevicesEnrollSelectDevicesPage/DevicesEnrollSelectDevicesPage.styled";
import { devicesEnrollStepPath } from "@portal-account/routes/routePaths";
import {
  DeviceBrands,
  ResideoDeviceListStorageType,
} from "@portal-account/types/devicesTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useAtom } from "jotai";
import { RESET } from "jotai/utils";
import React, { useEffect } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

export const DevicesEnrollSelectDevicesPage = () => {
  const flash = useRhFlash();
  const navigate = useNavigate();
  const { translate } = useTranslations();
  const [resideoAuthQueriedOnce, setResideoAuthQueriedOnce] = useAtom(
    resideoAuthQueriedOnceAtom
  );
  const [resideoDeviceListFromStorage, setResideoDeviceListToStorage] =
    useSessionStorage<ResideoDeviceListStorageType | null>(
      RESIDEO_SESSION_STORAGE_KEY,
      null
    );
  const [searchParams, setSearchParams] = useSearchParams();
  const oauthCode = searchParams.get(RESIDEO_API_CODE_PARAM) as string;
  const deviceBrand = searchParams.get(RESIDEO_API_STATE_PARAM) as DeviceBrands;

  const {
    data: listResideoDevicesData,
    isSuccess,
    error,
    isPending,
  } = useListResideoDevicesQuery({
    deviceBrand,
    oauthCode,
    queryOptions: {
      enabled: Boolean(oauthCode && deviceBrand),
    },
  });

  const {
    tDevicesEnrollSelectDevicesPageTitle,
    tDevicesEnrollSelectDevicesPageTokenExpired,
    tDevicesEnrollSelectDevicesPageDescription,
    tDevicesEnrollSelectDevicesPageDescriptionNoDevices,
    tDevicesEnrollSelectDevicesPageTitleNoDevices,
    tDevicesEnrollSelectDevicesPageStartOver,
  } = translate(devicesEnrollSelectDevicesPageTranslations);

  // For persistance, save the resideo device list to sessionStorage
  useEffect(() => {
    if (oauthCode && listResideoDevicesData) {
      setResideoDeviceListToStorage({ deviceBrand, ...listResideoDevicesData });
    }
  }, [
    listResideoDevicesData,
    resideoDeviceListFromStorage,
    deviceBrand,
    oauthCode,
    setResideoDeviceListToStorage,
  ]);

  // If there is an error and we don't have local storage, kick the user back to first step
  useEffect(() => {
    if (error && !resideoDeviceListFromStorage) {
      flash.error(tDevicesEnrollSelectDevicesPageTokenExpired);

      return navigate(devicesEnrollStepPath("choose-your-device-brand"));
    }
  }, [
    error,
    resideoDeviceListFromStorage,
    flash,
    navigate,
    tDevicesEnrollSelectDevicesPageTokenExpired,
  ]);

  // If a user backward navigates from Summary, we should immediately kick them back to "Choose your device",
  // and reset our queriedOnce atom
  useEffect(() => {
    if (resideoAuthQueriedOnce && !resideoDeviceListFromStorage) {
      setResideoAuthQueriedOnce(RESET);

      return navigate(devicesEnrollStepPath("choose-your-device-brand"));
    }
  }, [
    resideoAuthQueriedOnce,
    resideoDeviceListFromStorage,
    navigate,
    setResideoAuthQueriedOnce,
  ]);

  // Delete the urlSearchParams so if a users tries to navigate back to this page it will kick them
  useEffect(() => {
    if (isSuccess || resideoDeviceListFromStorage) {
      setResideoAuthQueriedOnce(true);
      setSearchParams(undefined);
    }
  }, [
    isSuccess,
    resideoDeviceListFromStorage,
    setSearchParams,
    setResideoAuthQueriedOnce,
  ]);

  // Direct traffic to /select-devices without oauthCode or no previous query should redirect to first step
  if (!resideoAuthQueriedOnce && !oauthCode) {
    return <Navigate to={devicesEnrollStepPath("choose-your-device-brand")} />;
  }

  if (isPending && !resideoDeviceListFromStorage) {
    return (
      <StyledLoadingContainer>
        <RhCircularProgress />
      </StyledLoadingContainer>
    );
  }

  if (resideoDeviceListFromStorage?.thermostats.length === 0) {
    return (
      <DevicesEnrollPageLayout
        containerMaxWidth={700}
        description={tDevicesEnrollSelectDevicesPageDescriptionNoDevices}
        step="select-devices"
        title={tDevicesEnrollSelectDevicesPageTitleNoDevices}
      >
        <div>
          <RhLinkButton
            href={devicesEnrollStepPath("choose-your-device-brand")}
            data-tracking-click={{ event: "Start Device Enrollment Again" }}
            size="large"
          >
            {tDevicesEnrollSelectDevicesPageStartOver}
          </RhLinkButton>
        </div>
      </DevicesEnrollPageLayout>
    );
  }

  return (
    <DevicesEnrollPageLayout
      containerMaxWidth={700}
      description={tDevicesEnrollSelectDevicesPageDescription}
      step="select-devices"
      title={tDevicesEnrollSelectDevicesPageTitle}
    >
      <DevicesEnrollSelectDevicesForm />
    </DevicesEnrollPageLayout>
  );
};
