export const createPasswordFormTranslations = {
  tCreatePasswordFormCreatePassword: "Create Password",
  tCreatePasswordFormCreatingPassword: "Creating your password...",
  tCreatePasswordFormErrorCreatingPassword:
    "Password creation failed. Please meet the minimum requirements and try again.",
  tCreatePasswordFormErrorPasswordRequirementsNotMet:
    "Your password does not meet minimum requirements. Use a not common password with 8 or more characters with a mix of letters, numbers and symbols.",
  tCreatePasswordFormPassword: "Password",
  tCreatePasswordFormPasswordDoesNotMeetRequirements:
    "Password does not meet minimum requirements.",
  tCreatePasswordFormPasswordRequired: "Password is required.",
  tCreatePasswordFormPasswordRequirements:
    "Use 8 or more characters with a mix of letters, numbers and symbols.",
};
