import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  justify-content: center;
  text-align: center;
`;

export const StyledHighFiveContainer = styled.div`
  max-width: 140px;

  img {
    width: 100%;
  }
`;
