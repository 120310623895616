import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useAccountModals } from "@portal-account/components/AccountModalsManager/useAccountModals";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { ProductAddOnsOptInConfirmation } from "@portal-account/components/ProductAddOnsOptIn/ProductAddOnsOptInConfirmation/ProductAddOnsOptInConfirmation";
import { useCreateProductContractMutation } from "@portal-account/hooks/mutations/useCreateProductContract.mutation";
import { useProductTiersQuery } from "@portal-account/hooks/queries/useProductTiers.query";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { MyAccountEvents } from "@portal-account/services/segment.service";
import { selectProductAddOns } from "@portal-account/slices/productAddOnSlice";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { customerHomePath } from "@portal-shared/routes/routePaths";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

export const PortalProductAddOnsOptInConfirmationPage = () => {
  const { t } = useRhIntl();
  const flash = useRhFlash();
  const navigate = useNavigate();
  const createProductContractMutation = useCreateProductContractMutation();
  const { selectedProductPriceIds } = useSelector(selectProductAddOns);
  const { addModal } = useAccountModals();

  const productTiersQuery = useProductTiersQuery();
  const { premise } = usePremiseFromContext();
  const track = useTrackMyAccountEvents();

  if (selectedProductPriceIds.length === 0) {
    return <Navigate to=".." />;
  }

  if (productTiersQuery.isError) {
    const errorFetchingTiers = t(
      "PortalProductAddOnsOptInConfirmationPage.errorFetchingTiers"
    );

    flash.error(errorFetchingTiers);
    return (
      <PortalPageLayout>
        <Error500Page />
      </PortalPageLayout>
    );
  }

  if (productTiersQuery.isPending) {
    return (
      <PortalPageLayout>
        <RhCircularProgress />;
      </PortalPageLayout>
    );
  }

  const onConfirmationClick = () => {
    // As of now, we are only allowing one product price, change this once we have more
    const [priceId] = selectedProductPriceIds;

    createProductContractMutation.mutate(
      { premiseId: premise.id, priceId },
      {
        onError: (error) => {
          flash.error(
            t("PortalProductAddOnsOptInConfirmationPage.errorAddingAddon")
          );
        },
        onSuccess: (response) => {
          addModal({
            contractsAdded: {
              ids: [response.id],
            },
          });

          track({
            event: MyAccountEvents.protectionPlanPurchased,
            label: "Purchased protection plan",
          });

          navigate(customerHomePath());
        },
      }
    );
  };

  return (
    <PortalPageLayout>
      <ProductAddOnsOptInConfirmation
        productTiers={productTiersQuery.data}
        selectedProductPriceIds={selectedProductPriceIds}
        onConfirmationClick={onConfirmationClick}
      />
    </PortalPageLayout>
  );
};
