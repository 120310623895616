export enum EnrollmentType {
  MOVE_IN = "MOVE_IN",
  SWITCH = "SWITCH",
}

export enum EnrollmentStatus {
  CANCELED = "CANCELED",
  CANCEL_PENDING = "CANCEL_PENDING",
  DATE_CHANGE_ACCEPTED = "DATE_CHANGE_ACCEPTED",
  DATE_CHANGE_REJECTED = "DATE_CHANGE_REJECTED",
  DISCONNECTED = "DISCONNECTED",
  ENROLLMENT_ACCEPTED = "ENROLLMENT_ACCEPTED",
  ENROLLMENT_ENROLLED = "ENROLLMENT_ENROLLED",
  ENROLLMENT_PERMIT_REQUIRED = "ENROLLMENT_PERMIT_REQUIRED",
  ENROLLMENT_REJECTED = "ENROLLMENT_REJECTED",
  ENROLLMENT_REQUESTED = "ENROLLMENT_REQUESTED",
  ENROLLMENT_WITHDRAWN = "ENROLLMENT_WITHDRAWN",
  MOVE_OUT_ACCEPTED = "MOVE_OUT_ACCEPTED",
  MOVE_OUT_DATE_CHANGE_REQUESTED = "MOVE_OUT_DATE_CHANGE_REQUESTED",
  MOVE_OUT_REJECTED = "MOVE_OUT_REJECTED",
  MOVE_OUT_REQUESTED = "MOVE_OUT_REQUESTED",
  PRE_ENROLL = "PRE_ENROLL",
  STATUS_DISCONNECTED = "STATUS_DISCONNECTED",
  SWITCH_CANCEL_REQUESTED = "SWITCH_CANCEL_REQUESTED",
}

export enum CustomerStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

export enum AccountType {
  RESIDENTIAL = "Residential",
  SMALL_COMMERCIAL = "Small Commercial",
}
