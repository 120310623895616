import { ajax } from "@common/services/ajax";
import {
  CreditCheckRequestV2,
  CreditCheckResponseV2,
} from "@enroll-utils/types/creditCheckTypes";
import {
  EnrollProspectType,
  ProspectCreateRequest,
  ProspectUpdateRequestType,
} from "@enroll-utils/types/prospectTypes";
import { OfferSnapshotType } from "@portal-shared/types/offerSnapshotTypes";
import { RawAxiosRequestConfig } from "axios";

const PORTAL_PROSPECT_BASE_URL = "/api/portal/prospects";

export const prospectApi = {
  availableOfferSnapshots: () =>
    ajax.get<OfferSnapshotType[]>(
      `${PORTAL_PROSPECT_BASE_URL}/available-offersnapshots`
    ),
  create: <R = void>(body: ProspectCreateRequest): Promise<R> => {
    return ajax.post(`${PORTAL_PROSPECT_BASE_URL}/`, body);
  },
  createCreditCheck: (body: CreditCheckRequestV2) =>
    ajax.post<CreditCheckResponseV2>(
      `${PORTAL_PROSPECT_BASE_URL}/credit-check`,
      body
    ),
  fetchCreditCheck: () =>
    ajax.get<CreditCheckResponseV2>(`${PORTAL_PROSPECT_BASE_URL}/credit-check`),
  retrieve: <R = EnrollProspectType>(axiosConfig?: RawAxiosRequestConfig) => {
    return ajax.get<R>(`${PORTAL_PROSPECT_BASE_URL}/me`, axiosConfig);
  },
  update: <R = void>({
    axiosConfig,
    data,
  }: {
    axiosConfig?: RawAxiosRequestConfig;
    data: ProspectUpdateRequestType;
  }) => {
    return ajax.patch<R>(`${PORTAL_PROSPECT_BASE_URL}/me`, data, axiosConfig);
  },
};
