import { FIVE_DIGIT_ZIP_CODE } from "@common/constants/regex.constant";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { RhTooltip } from "@design-system/components/RhTooltip/RhTooltip";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  OrSeparator,
  StyledForm,
  StyledGuestPayAccountLookup,
  StyledGuestPayButton,
  StyledInformationIcon,
  StyledLabelContainer,
  StyledSubtitle,
} from "@portal-guest/components/GuestPayAccountLookup/GuestPayAccountLookup.styled";
import { useGuestPayAccountSummaryLookupMutation } from "@portal-guest/hooks/mutations/useGuestPayAccountSummaryLookup.mutation";
import { useGuestPayAccountBalanceByTokenQuery } from "@portal-guest/hooks/queries/useGuestPayAccountBalanceByToken.query";
import { guestPayAccountSummaryPath } from "@portal-guest/routes/routePaths";
import {
  ActionType,
  GuestEvents,
  track,
} from "@portal-guest/services/segment.service";
import { GuestPayAccountSummaryType } from "@portal-guest/types/guestPayTypes";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { signInPath } from "@portal-shared/routes/routePaths";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const useGoToAccountSummary = (
  accountSummary: GuestPayAccountSummaryType | undefined
) => {
  const navigate = useNavigate();

  // Preload data if available
  useGuestPayAccountBalanceByTokenQuery({
    initialData: accountSummary,
    token: accountSummary?.token,
  });

  useEffect(() => {
    // No need to check for isPending or isError as in this case we
    // are sure we are setting the data via initial data, so we are
    // actually not waiting for a BE call
    if (accountSummary) {
      const queryParams = new URLSearchParams();

      queryParams.append("token", accountSummary.token);

      navigate({
        pathname: guestPayAccountSummaryPath(),
        search: `?${queryParams.toString()}`,
      });
    }
  }, [accountSummary, navigate]);
};

const useGuestPayAccountLookupTranslations = () => {
  const { t } = useRhIntl();

  const tTitle = t("GuestPayAccountLookup.title");
  const tSubtitle = t("GuestPayAccountLookup.subTitle");
  const tSubmitCta = t("GuestPayAccountLookup.submitCta");
  const tAccountNumber = t("GuestPayAccountLookup.accountNumber");
  const tZipCode = t("GuestPayAccountLookup.zipCode");
  const tAccountNumberTooltipInfo = t(
    "GuestPayAccountLookup.accountNumberTooltipInfo"
  );
  const tAccountNumberTooltipIconAriaLabel = t(
    "GuestPayAccountLookup.accountNumberTooltipIconAriaLabel"
  );
  const tZipCodeTooltipInfo = t("GuestPayAccountLookup.zipCodeTooltipInfo");
  const tZipCodeTooltipIconAriaLabel = t(
    "GuestPayAccountLookup.zipCodeTooltipIconAriaLabel"
  );
  const tErrorFindingAccount = t("GuestPayAccountLookup.errorFindingAccount");
  const tErrorGeneric = t("GuestPayAccountLookup.errorGeneric");
  const tLoginCta = t("GuestPayAccountLookup.loginCta");
  const tOr = t("GuestPayAccountLookup.or");
  const tAccountLookupForm = t("GuestPayAccountLookup.accountLookupForm");

  return {
    tAccountLookupForm,
    tAccountNumber,
    tAccountNumberTooltipIconAriaLabel,
    tAccountNumberTooltipInfo,
    tErrorFindingAccount,
    tErrorGeneric,
    tLoginCta,
    tOr,
    tSubmitCta,
    tSubtitle,
    tTitle,
    tZipCode,
    tZipCodeTooltipIconAriaLabel,
    tZipCodeTooltipInfo,
  };
};

export interface AccountLookupFormValues {
  accountNumber: string;
  zipCode: string;
}

export const GuestPayAccountLookup = () => {
  const {
    handleSubmit,
    register,
    formState: { isValid, isDirty },
  } = useForm<AccountLookupFormValues>();
  const navigate = useNavigate();
  const guestPayAccountSummaryLookupMutation =
    useGuestPayAccountSummaryLookupMutation();
  const flash = useRhFlash();
  const [accountSummary, setAccountSummary] = useState<
    GuestPayAccountSummaryType | undefined
  >(undefined);

  useGoToAccountSummary(accountSummary);

  const {
    tAccountNumber,
    tAccountNumberTooltipIconAriaLabel,
    tAccountNumberTooltipInfo,
    tErrorFindingAccount,
    tErrorGeneric,
    tSubmitCta,
    tSubtitle,
    tTitle,
    tZipCode,
    tZipCodeTooltipIconAriaLabel,
    tZipCodeTooltipInfo,
    tLoginCta,
    tOr,
    tAccountLookupForm,
  } = useGuestPayAccountLookupTranslations();

  const onSubmit = handleSubmit((formValues) => {
    track({
      action: ActionType.click,
      event: GuestEvents.quickPay,
      label: "Guest Pay account lookup",
    });

    return guestPayAccountSummaryLookupMutation.mutate(
      {
        accountNumber: formValues.accountNumber,
        zipCode: formValues.zipCode,
      },
      {
        onError: (error) => {
          const errorMessage = error.status?.toString().startsWith("5")
            ? tErrorGeneric
            : tErrorFindingAccount;

          flash.error(errorMessage);
        },
        onSuccess: (data) => {
          setAccountSummary(data);
        },
      }
    );
  });

  const onLogin = () => {
    navigate(signInPath());
  };

  const accountNumberProps = register("accountNumber", { required: true });
  const zipCodeProps = register("zipCode", {
    maxLength: 5,
    minLength: 5,
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    pattern: FIVE_DIGIT_ZIP_CODE,
    required: true,
  });

  return (
    <StyledGuestPayAccountLookup>
      <RhTypography variant="h1">{tTitle}</RhTypography>
      <StyledSubtitle variant="subtitle2">{tSubtitle}</StyledSubtitle>

      <StyledForm onSubmit={onSubmit} aria-label={tAccountLookupForm}>
        <RhTextInput
          placeholder="XYZ-123456789"
          color="primary"
          autoFocus
          {...accountNumberProps}
        >
          <StyledLabelContainer>
            {tAccountNumber}

            <RhTooltip
              ariaLabel={tAccountNumberTooltipIconAriaLabel}
              content={<>{tAccountNumberTooltipInfo}</>}
            >
              <StyledInformationIcon />
            </RhTooltip>
          </StyledLabelContainer>
        </RhTextInput>
        <RhTextInput
          inputProps={{
            maxLength: 5,
            minLength: 5,
          }}
          color="primary"
          placeholder=""
          required
          {...zipCodeProps}
        >
          <StyledLabelContainer>
            {tZipCode}

            <RhTooltip
              ariaLabel={tZipCodeTooltipIconAriaLabel}
              content={<>{tZipCodeTooltipInfo}</>}
            >
              <StyledInformationIcon />
            </RhTooltip>
          </StyledLabelContainer>
        </RhTextInput>
        <StyledGuestPayButton
          data-tracking-click={{
            event: "Guest Pay sending account number and zip code",
          }}
          type="submit"
          disabled={
            !isValid ||
            guestPayAccountSummaryLookupMutation.isPending ||
            !isDirty
          }
          color="primary"
        >
          {guestPayAccountSummaryLookupMutation.isPending ? (
            <RhCircularProgress size={14} marginBottom={0} marginTop={0} />
          ) : (
            tSubmitCta
          )}
        </StyledGuestPayButton>
      </StyledForm>
      <OrSeparator>- {tOr} -</OrSeparator>
      <StyledGuestPayButton
        data-tracking-click={{
          event: "Guest Pay customer opting to login instead",
        }}
        variant="outlined"
        color="primary"
        onClick={onLogin}
      >
        {tLoginCta}
      </StyledGuestPayButton>
    </StyledGuestPayAccountLookup>
  );
};
