export const signUpPremisePageTranslations = {
  tSignUpPremisePageUnknownErrorApiMeterAvailability:
    "We are having difficulty verifying your address. Please check spelling or try again.",
  tSignUpPremisePageUnknownErrorProspect:
    "We are having difficulty saving your information. Please try again.",
  tSignUpPremisePageUtilityChanged:
    "The address provided is a {utilityName} meter. Please select a new plan available within that utility.",
  tSignUpPremisePageWhereShouldWeSendEnergy:
    "Where should we send your 100% renewable energy?",
};
