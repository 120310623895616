import { AppBrands } from "@common/enums/appBrands.enum";

type ImageUrlSlugs =
  | "billFactorsCalendar"
  | "billFactorsColdWeather"
  | "billFactorsContractChanges"
  | "billFactorsHotWeather"
  | "billFactorsLightBulb"
  | "billFactorsPastDue"
  | "billFactorsTDSPChange"
  | "enrollCloudPlug"
  | "enrollHouse1000"
  | "enrollHouse1500"
  | "enrollHouse2000"
  | "enrollHouse2500"
  | "enrollHouse3000"
  | "enrollHouse3500"
  | "enrollHouse500"
  | "iconAutopay"
  | "iconBigMagnifier"
  | "iconCalculator"
  | "iconCalendar"
  | "iconCar"
  | "iconCart"
  | "iconCircularCheck"
  | "iconClock"
  | "iconGreen"
  | "iconHeart"
  | "iconLock"
  | "iconMagnifier"
  | "iconMapPin"
  | "iconPaperless"
  | "iconPerson2"
  | "iconPhone"
  | "iconPrice"
  | "iconScale"
  | "iconShield"
  | "iconSolar"
  | "iconSun"
  | "iconTag"
  | "iconThermostat"
  | "iconWallet"
  | "iconWind"
  | "pageOffersBackgroundImageLeft"
  | "pageOffersBackgroundImageRight"
  | "portalBigMagnifier"
  | "portalBull"
  | "portalCalculatorIcon"
  | "portalChangingLightBulb"
  | "portalChargeRate"
  | "portalCheckLoungeChair"
  | "portalCoinMan"
  | "portalGreyCircularHeartIcon"
  | "portalGreyCircularLockIcon"
  | "portalGreyCircularWalletIcon"
  | "portalHighFives"
  | "portalLightBulbIcon"
  | "portalLoaderLottie"
  | "portalMultiplyIcon"
  | "portalNewMoverWelcomeImage"
  | "portalPlusIcon"
  | "portalRewardsSuccessCoinExplode"
  | "portalSkyline"
  | "portalSolarBuyBack"
  | "portalStandardWelcomeImage"
  | "portalTDUIcon"
  | "portalTechnologyCloud"
  | "portalThermostat"
  | "portalWomanUnplug";

export type ImageUrlContainsBrand =
  `/assets/images/${AppBrands}/${ImageUrlSlugs}.${string}`;

export type BrandImageUrls = {
  [K in ImageUrlSlugs]: ImageUrlContainsBrand;
};

export const RhythmBrandImageUrls = {
  billFactorsCalendar: "/assets/images/rhythm/billFactorsCalendar.svg",
  billFactorsColdWeather: "/assets/images/rhythm/billFactorsColdWeather.svg",
  billFactorsContractChanges:
    "/assets/images/rhythm/billFactorsContractChanges.svg",
  billFactorsHotWeather: "/assets/images/rhythm/billFactorsHotWeather.svg",
  billFactorsLightBulb: "/assets/images/rhythm/billFactorsLightBulb.svg",
  billFactorsPastDue: "/assets/images/rhythm/billFactorsPastDue.svg",
  billFactorsTDSPChange: "/assets/images/rhythm/billFactorsTDSPChange.svg",
  enrollCloudPlug: "/assets/images/rhythm/enrollCloudPlug.svg",
  enrollHouse1000: "/assets/images/rhythm/enrollHouse1000.svg",
  enrollHouse1500: "/assets/images/rhythm/enrollHouse1500.svg",
  enrollHouse2000: "/assets/images/rhythm/enrollHouse2000.svg",
  enrollHouse2500: "/assets/images/rhythm/enrollHouse2500.svg",
  enrollHouse3000: "/assets/images/rhythm/enrollHouse3000.svg",
  enrollHouse3500: "/assets/images/rhythm/enrollHouse3500.svg",
  enrollHouse500: "/assets/images/rhythm/enrollHouse500.svg",
  iconAutopay: "/assets/images/rhythm/iconAutopay.svg",
  iconBigMagnifier: "/assets/images/rhythm/iconBigMagnifier.svg",
  iconCalculator: "/assets/images/rhythm/iconCalculator.svg",
  iconCalendar: "/assets/images/rhythm/iconCalendar.svg",
  iconCar: "/assets/images/rhythm/iconCar.svg",
  iconCart: "/assets/images/rhythm/iconCart.svg",
  iconCircularCheck: "/assets/images/rhythm/iconCircularCheck.svg",
  iconClock: "/assets/images/rhythm/iconClock.svg",
  iconGreen: "/assets/images/rhythm/iconGreen.svg",
  iconHeart: "/assets/images/rhythm/iconHeart.svg",
  iconLock: "/assets/images/rhythm/iconLock.svg",
  iconMagnifier: "/assets/images/rhythm/iconMagnifier.svg",
  iconMapPin: "/assets/images/rhythm/iconMapPin.svg",
  iconPaperless: "/assets/images/rhythm/iconPaperless.svg",
  iconPerson2: "/assets/images/rhythm/iconPerson2.svg",
  iconPhone: "/assets/images/rhythm/iconPhone.svg",
  iconPrice: "/assets/images/rhythm/iconPrice.svg",
  iconScale: "/assets/images/rhythm/iconScale.svg",
  iconShield: "/assets/images/rhythm/iconShield.svg",
  iconSolar: "/assets/images/rhythm/iconSolar.svg",
  iconSun: "/assets/images/rhythm/iconSun.svg",
  iconTag: "/assets/images/rhythm/iconTag.svg",
  iconThermostat: "/assets/images/rhythm/iconThermostat.svg",
  iconWallet: "/assets/images/rhythm/iconWallet.svg",
  iconWind: "/assets/images/rhythm/iconWind.svg",
  pageOffersBackgroundImageLeft:
    "/assets/images/rhythm/pageOffersBackgroundImageLeft.svg",
  pageOffersBackgroundImageRight:
    "/assets/images/rhythm/pageOffersBackgroundImageRight.svg",
  portalBigMagnifier: "/assets/images/rhythm/portalBigMagnifier.svg",
  portalBull: "/assets/images/rhythm/portalBull.svg",
  portalCalculatorIcon: "/assets/images/rhythm/portalCalculatorIcon.svg",
  portalChangingLightBulb: "/assets/images/rhythm/portalChangingLightBulb.svg",
  portalChargeRate: "/assets/images/rhythm/portalChargeRate.svg",
  portalCheckLoungeChair: "/assets/images/rhythm/portalCheckLoungeChair.svg",
  portalCoinMan: "/assets/images/rhythm/portalCoinMan.svg",
  portalGreyCircularHeartIcon:
    "/assets/images/rhythm/portalGreyCircularHeartIcon.svg",
  portalGreyCircularLockIcon:
    "/assets/images/rhythm/portalGreyCircularLockIcon.svg",
  portalGreyCircularWalletIcon:
    "/assets/images/rhythm/portalGreyCircularWalletIcon.svg",
  portalHighFives: "/assets/images/rhythm/portalHighFives.svg",
  portalLightBulbIcon: "/assets/images/rhythm/portalLightBulbIcon.svg",
  portalLoaderLottie: "/assets/images/rhythm/portalLoaderLottie.lottie.json",
  portalMultiplyIcon: "/assets/images/rhythm/portalMultiplyIcon.svg",
  portalNewMoverWelcomeImage:
    "/assets/images/rhythm/portalNewMoverWelcomeImage.svg",
  portalPlusIcon: "/assets/images/rhythm/portalPlusIcon.svg",
  portalRewardsSuccessCoinExplode:
    "/assets/images/rhythm/portalRewardsSuccessCoinExplode.svg",
  portalSkyline: "/assets/images/rhythm/portalSkyline.svg",
  portalSolarBuyBack: "/assets/images/rhythm/portalSolarBuyBack.svg",
  portalStandardWelcomeImage:
    "/assets/images/rhythm/portalStandardWelcomeImage.svg",
  portalTDUIcon: "/assets/images/rhythm/portalTDUIcon.svg",
  portalTechnologyCloud: "/assets/images/rhythm/portalTechnologyCloud.svg",
  portalThermostat: "/assets/images/rhythm/portalThermostat.svg",
  portalWomanUnplug: "/assets/images/rhythm/portalWomanUnplug.svg",
} satisfies BrandImageUrls;

export const EnergyTexasBrandImageUrls = {
  billFactorsCalendar: "/assets/images/energy-texas/billFactorsCalendar.svg",
  billFactorsColdWeather:
    "/assets/images/energy-texas/billFactorsColdWeather.svg",
  billFactorsContractChanges:
    "/assets/images/energy-texas/billFactorsContractChanges.svg",
  billFactorsHotWeather:
    "/assets/images/energy-texas/billFactorsHotWeather.svg",
  billFactorsLightBulb: "/assets/images/energy-texas/billFactorsLightBulb.svg",
  billFactorsPastDue: "/assets/images/energy-texas/billFactorsPastDue.svg",
  billFactorsTDSPChange:
    "/assets/images/energy-texas/billFactorsTDSPChange.svg",
  enrollCloudPlug: "/assets/images/energy-texas/enrollCloudPlug.svg",
  enrollHouse1000: "/assets/images/energy-texas/enrollHouse1000.svg",
  enrollHouse1500: "/assets/images/energy-texas/enrollHouse1500.svg",
  enrollHouse2000: "/assets/images/energy-texas/enrollHouse2000.svg",
  enrollHouse2500: "/assets/images/energy-texas/enrollHouse2500.svg",
  enrollHouse3000: "/assets/images/energy-texas/enrollHouse3000.svg",
  enrollHouse3500: "/assets/images/energy-texas/enrollHouse3500.svg",
  enrollHouse500: "/assets/images/energy-texas/enrollHouse500.svg",
  iconAutopay: "/assets/images/energy-texas/iconAutopay.svg",
  iconBigMagnifier: "/assets/images/energy-texas/iconBigMagnifier.svg",
  iconCalculator: "/assets/images/energy-texas/iconCalculator.svg",
  iconCalendar: "/assets/images/energy-texas/iconCalendar.svg",
  iconCar: "/assets/images/energy-texas/iconCar.svg",
  iconCart: "/assets/images/energy-texas/iconCart.svg",
  iconCircularCheck: "/assets/images/energy-texas/iconCircularCheck.svg",
  iconClock: "/assets/images/energy-texas/iconClock.svg",
  iconGreen: "/assets/images/energy-texas/iconGreen.svg",
  iconHeart: "/assets/images/energy-texas/iconHeart.svg",
  iconLock: "/assets/images/energy-texas/iconLock.svg",
  iconMagnifier: "/assets/images/energy-texas/iconMagnifier.svg",
  iconMapPin: "/assets/images/energy-texas/iconMapPin.svg",
  iconPaperless: "/assets/images/energy-texas/iconPaperless.svg",
  iconPerson2: "/assets/images/energy-texas/iconPerson2.svg",
  iconPhone: "/assets/images/energy-texas/iconPhone.svg",
  iconPrice: "/assets/images/energy-texas/iconPrice.svg",
  iconScale: "/assets/images/energy-texas/iconScale.svg",
  iconShield: "/assets/images/energy-texas/iconShield.svg",
  iconSolar: "/assets/images/energy-texas/iconSolar.svg",
  iconSun: "/assets/images/energy-texas/iconSun.svg",
  iconTag: "/assets/images/energy-texas/iconTag.svg",
  iconThermostat: "/assets/images/energy-texas/iconThermostat.svg",
  iconWallet: "/assets/images/energy-texas/iconWallet.svg",
  iconWind: "/assets/images/energy-texas/iconWind.svg",
  pageOffersBackgroundImageLeft:
    "/assets/images/energy-texas/pageOffersBackgroundImageLeft.svg",
  pageOffersBackgroundImageRight:
    "/assets/images/energy-texas/pageOffersBackgroundImageRight.svg",
  portalBigMagnifier: "/assets/images/energy-texas/portalBigMagnifier.svg",
  portalBull: "/assets/images/energy-texas/portalBull.svg",
  portalCalculatorIcon: "/assets/images/energy-texas/portalCalculatorIcon.svg",
  portalChangingLightBulb:
    "/assets/images/energy-texas/portalChangingLightBulb.svg",
  portalChargeRate: "/assets/images/energy-texas/portalChargeRate.svg",
  portalCheckLoungeChair:
    "/assets/images/energy-texas/portalCheckLoungeChair.svg",
  portalCoinMan: "/assets/images/energy-texas/portalCoinMan.svg",
  portalGreyCircularHeartIcon:
    "/assets/images/energy-texas/portalGreyCircularHeartIcon.svg",
  portalGreyCircularLockIcon:
    "/assets/images/energy-texas/portalGreyCircularLockIcon.svg",
  portalGreyCircularWalletIcon:
    "/assets/images/energy-texas/portalGreyCircularWalletIcon.svg",
  portalHighFives: "/assets/images/energy-texas/portalHighFives.svg",
  portalLightBulbIcon: "/assets/images/energy-texas/portalLightBulbIcon.svg",
  portalLoaderLottie:
    "/assets/images/energy-texas/portalLoaderLottie.lottie.json",
  portalMultiplyIcon: "/assets/images/energy-texas/portalMultiplyIcon.svg",
  portalNewMoverWelcomeImage:
    "/assets/images/energy-texas/portalNewMoverWelcomeImage.svg",
  portalPlusIcon: "/assets/images/energy-texas/portalPlusIcon.svg",
  portalRewardsSuccessCoinExplode:
    "/assets/images/energy-texas/portalRewardsSuccessCoinExplode.svg",
  portalSkyline: "/assets/images/energy-texas/portalSkyline.svg",
  portalSolarBuyBack: "/assets/images/energy-texas/portalSolarBuyBack.svg",
  portalStandardWelcomeImage:
    "/assets/images/energy-texas/portalStandardWelcomeImage.svg",
  portalTDUIcon: "/assets/images/energy-texas/portalTDUIcon.svg",
  portalTechnologyCloud:
    "/assets/images/energy-texas/portalTechnologyCloud.svg",
  portalThermostat: "/assets/images/energy-texas/portalThermostat.svg",
  portalWomanUnplug: "/assets/images/energy-texas/portalWomanUnplug.svg",
} satisfies Record<keyof typeof RhythmBrandImageUrls, ImageUrlContainsBrand>;
