import { scrollToOffsetPosition } from "@common/utils/scrollToOffsetPosition";
import { ReactComponent as RightChevron } from "@design-system/icons/RightChevron.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { getMyAccountNavItemValues } from "@portal-account/components/MyAccountNav/myAccountNaConfig";
import { myAccountNavTranslations } from "@portal-account/components/MyAccountNav/MyAccountNav.en.i18n";
import {
  StyledMyAccountLinkContainer,
  StyledNavContainer,
} from "@portal-account/components/MyAccountNav/MyAccountNav.styled";
import { NavHashLink } from "@portal-account/components/NavHashLink/NavHashLink";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { signOutPath } from "@portal-shared/routes/routePaths";
import React from "react";
import { Link } from "react-router-dom";

export const MyAccountNav = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const { translate } = useTranslations();

  const {
    tMyAccountNavLogout,
    tMyAccountNavBillingPreferences,
    tMyAccountNavChangePassword,
    tMyAccountNavCommunicationPreferences,
    tMyAccountNavContactInfo,
    tMyAccountNavLanguagePreference,
    tMyAccountNavPaymentMethods,
    tMyAccountNavProductAddOns,
    tMyAccountNavServiceInfo,
    tMyAccountNavYourPlan,
  } = translate(myAccountNavTranslations);

  const myAccountNavItemValues = getMyAccountNavItemValues();

  const navItemTranslationMap: Record<
    Exclude<keyof typeof myAccountNavTranslations, "tMyAccountNavLogout">,
    string
  > = {
    tMyAccountNavBillingPreferences,
    tMyAccountNavChangePassword,
    tMyAccountNavCommunicationPreferences,
    tMyAccountNavContactInfo,
    tMyAccountNavLanguagePreference,
    tMyAccountNavPaymentMethods,
    tMyAccountNavProductAddOns,
    tMyAccountNavServiceInfo,
    tMyAccountNavYourPlan,
  };

  return (
    <div role="navigation">
      <StyledNavContainer data-testid="myAccount__nav">
        {myAccountNavItemValues.map(({ anchorTag, translationId }) => {
          const title = navItemTranslationMap[translationId];

          return (
            <StyledMyAccountLinkContainer key={anchorTag}>
              <NavHashLink
                smooth
                to={anchorTag}
                scroll={scrollToOffsetPosition}
              >
                {title}
                {isMobile && <RightChevron title={title} />}
              </NavHashLink>
            </StyledMyAccountLinkContainer>
          );
        })}
        <StyledMyAccountLinkContainer>
          <Link to={signOutPath()}>
            {tMyAccountNavLogout}
            {isMobile && <RightChevron title={tMyAccountNavLogout} />}
          </Link>
        </StyledMyAccountLinkContainer>
      </StyledNavContainer>
    </div>
  );
};
