import { useSignUpProductTiersQuery } from "@portal-enroll/hooks/queries/useSignUpProductTiers.query";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { ProductTier } from "@portal-shared/models/ProductTier.model";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useSelectedProductTiers = (): ProductTier[] => {
  const productTiersQuery = useSignUpProductTiersQuery();
  const { productPrices } = useSelector(selectSignUpState);

  const selectedProductTiers = useMemo(
    () =>
      productTiersQuery.data?.filter((productTier) =>
        productTier.prices.some((price) => productPrices.includes(price.id))
      ) ?? [],
    [productTiersQuery.data, productPrices]
  );

  return selectedProductTiers;
};
