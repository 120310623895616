import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { RadioGroup, useMediaQuery, useTheme } from "@mui/material";
import { LanguagePreferenceRadioField } from "@portal-account/components/MyAccountLanguageCard/LanguagePreferenceRadioField";
import {
  StyledMyAccountSubmitButtonWrap,
  StyledRadioGroupWrap,
} from "@portal-account/components/MyAccountLanguageCard/MyAccountLanguageCard.styled";
import { MyAccountSubmitButton } from "@portal-account/components/MyAccountSubmitButton/MyAccountSubmitButton";
import { useCustomerLegacyUpdateMutation } from "@portal-account/hooks/mutations/useCustomerLegacyUpdate.mutation";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { Customer } from "@portal-account/models/Customer.model";
import { MyAccountEvents } from "@portal-account/services/segment.service";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import { getTranslation } from "@portal-shared/hooks/useDynamicIntl";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { AllSupportedLocales, Locale } from "@portal-shared/types/LocaleTypes";
import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";

type MyAccountLanguageCardProps = {
  customer: Customer;
};

export type MyAccountLanguageCardFormValues = {
  languagePreference: Locale;
};

export const MyAccountLanguageCard = ({
  customer,
}: MyAccountLanguageCardProps) => {
  const { t } = useRhIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const flash = useRhFlash();
  const [locale, setLocale] = useLocaleAtom();
  const contactFormInitialValues: MyAccountLanguageCardFormValues = {
    languagePreference: locale,
  };
  const track = useTrackMyAccountEvents();
  const customerUpdateMutation = useCustomerLegacyUpdateMutation();

  const changeLanguage = (
    formattedValues: MyAccountLanguageCardFormValues,
    formApi: FormApi<MyAccountLanguageCardFormValues>
  ) => {
    const values: MyAccountLanguageCardFormValues = {
      ...formattedValues,
    };
    const { languagePreference } = values;

    customerUpdateMutation.mutate(
      {
        customerData: values,
        customerId: customer.id,
      },
      {
        onError: () => {
          flash.error(t("MyAccountLanguageCard.errorSwitchingLocale"));
        },
        onSuccess: (data) => {
          formApi.initialize(formattedValues);
          setLocale(languagePreference);

          // t("account.contact.preferencesUpdated")
          // normally you should not access translations directly but...
          // useIntl will have a stale languagePreference, so cannot be trusted here
          flash.success(
            getTranslation(languagePreference)[
              "MyAccountLanguageCard.preferencesUpdated"
            ]
          );

          track({
            event: MyAccountEvents.languagePreferencesUpdated,
            label: "Updated language preference",
            language: languagePreference,
          });
        },
      }
    );
  };

  const title = t("MyAccountLanguageCard.languagePreference");

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>{title}</PortalCardTitle>
      </PortalCardHeader>

      <Form<MyAccountLanguageCardFormValues>
        initialValues={contactFormInitialValues}
        onSubmit={changeLanguage}
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <StyledRadioGroupWrap>
              <RadioGroup>
                {AllSupportedLocales.map((languageCode) => (
                  <LanguagePreferenceRadioField
                    key={languageCode}
                    value={languageCode}
                    translationId={`MyAccountLanguageCard.${languageCode}`}
                  />
                ))}
              </RadioGroup>
            </StyledRadioGroupWrap>
            <StyledMyAccountSubmitButtonWrap>
              <MyAccountSubmitButton isMobile={isMobile} />
            </StyledMyAccountSubmitButtonWrap>
          </form>
        )}
      />
    </PortalCard>
  );
};
