import { PremiseListItemType } from "@common/types/premiseTypes";
import { ReactComponent as ChevronDown } from "@design-system/icons/ChevronDown.svg";
import {
  StyledChevronIconContainer,
  StyledPortalSelectorBackdrop,
  StyledPortalSelectorContainer,
  StyledPortalSelectorMenu,
  StyledPortalSelectorMenuContainer,
  StyledPortalSelectorMenuItem,
  StyledPortalSelectorMenuItemLink,
  StyledSelectedItemShown,
} from "@portal-account/components/PortalSelector/PortalSelector.styled";
import { SESSION_STORAGE_PREMISE_ID_KEY } from "@portal-account/components/PremiseIdChooser/PremiseIdChooser.constants";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { PremiseListItem } from "@portal-account/components/PremiseSelector/PremiseSelectorListItem";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import {
  ActionType,
  MyAccountEvents,
} from "@portal-account/services/segment.service";
import React, { MouseEvent, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

interface PremiseSelectorProps {
  premises: PremiseListItemType[] | undefined;
}

export const PremiseSelector = (props: PremiseSelectorProps) => {
  const { premises } = props;
  const { premise } = usePremiseFromContext();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const track = useTrackMyAccountEvents();
  const [, setPremiseIdInSessionStorage] = useSessionStorage<string | null>(
    SESSION_STORAGE_PREMISE_ID_KEY,
    null
  );

  const { pathname } = location;

  const togglePremiseSelector = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    setIsOpen((prev) => !prev);
  };

  const setPremiseToSessionStorage = (prem: PremiseListItemType) => {
    track({
      action: ActionType.click,
      event: MyAccountEvents.premiseChanged,
      label: "Premise changed",
      newPremiseId: prem.id,
    });

    setPremiseIdInSessionStorage(prem.id);
  };

  const hasMultiplePremises = premises && premises.length > 1;

  return (
    <>
      <StyledPortalSelectorBackdrop
        data-testid="RhHeader__Backdrop"
        open={isOpen}
        style={{
          opacity: 0.25,
        }}
        onClick={togglePremiseSelector}
        transitionDuration={0}
      />
      <StyledPortalSelectorContainer $minWidth="300px">
        <StyledSelectedItemShown
          aria-controls="PremiseSelectorMenu"
          aria-haspopup="true"
          onClick={togglePremiseSelector}
          $hasMenuItems={Boolean(hasMultiplePremises)}
          aria-label="PremiseSelector"
          id="PremiseSelector"
        >
          <PremiseListItem showMapIcon premise={premise} />
          {hasMultiplePremises ? (
            <StyledChevronIconContainer
              $isOpen={isOpen}
              data-testid="PremiseSelector__ChevronDownIcon"
            >
              <ChevronDown height="10px" width="10px" />
            </StyledChevronIconContainer>
          ) : null}
        </StyledSelectedItemShown>
        {isOpen && hasMultiplePremises ? (
          <StyledPortalSelectorMenuContainer
            id="PremiseSelectorMenu"
            aria-hidden={!isOpen}
          >
            <StyledPortalSelectorMenu role="menu">
              {premises.map((prem) => (
                <StyledPortalSelectorMenuItem
                  key={prem.id}
                  role="menuitem"
                  $isSelected={prem.id === premise.id}
                >
                  <StyledPortalSelectorMenuItemLink
                    href={`${pathname}?premise=${prem.id}`}
                    target="_self"
                    onClick={() => setPremiseToSessionStorage(prem)}
                  >
                    <PremiseListItem
                      premise={prem}
                      isSelectedPremise={prem.id === premise.id}
                      isInMenu
                    />
                  </StyledPortalSelectorMenuItemLink>
                </StyledPortalSelectorMenuItem>
              ))}
            </StyledPortalSelectorMenu>
          </StyledPortalSelectorMenuContainer>
        ) : null}
      </StyledPortalSelectorContainer>
    </>
  );
};
