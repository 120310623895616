import { authnApi } from "@portal-shared/api/authnApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useLogoutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => authnApi.logout(),
    onSuccess: () => {
      queryClient.clear();
    },
  });
};
