import { LONG_FRIENDLY_DATE_FORMAT } from "@common/constants/date.constant";
import { useModalState } from "@common/hooks/useModalState";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { CompetitorPlanChart } from "@portal-shared/components/CompetitorPlanComparisonChart/CompetitorPlanChart";
import { competitorPlanComparisonChartTranslations } from "@portal-shared/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.en.i18n";
import {
  ChartContainer,
  ChartUpdatedTime,
  ComparisonChartContainer,
  NoCompetitorsChartOverlay,
  NoCompetitorsText,
} from "@portal-shared/components/CompetitorPlanComparisonChart/CompetitorPlanComparisonChart.styled";
import {
  getTopCompetitors,
  groupByCompetitorAndCalculateAverage,
} from "@portal-shared/components/CompetitorPlanComparisonChart/helpers";
import { CompetitorPlanComparisonPlansModal } from "@portal-shared/components/CompetitorPlanComparisonPlansModal/CompetitorPlanComparisonPlansModal";
import { useCompetitorPlansQuery } from "@portal-shared/hooks/queries/useCompetitorPlans.query";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import dayjs from "dayjs";
import React from "react";

interface CompetitorPlanComparisonChartProps {
  offerSnapshot: OfferSnapshot;
  onSelectPlanClick: (OfferSnapshot: OfferSnapshot) => void;
  showCompetitorPlanList?: boolean;
}

export const CompetitorPlanComparisonChart = (
  props: CompetitorPlanComparisonChartProps
) => {
  const {
    offerSnapshot,
    showCompetitorPlanList = true,
    onSelectPlanClick,
  } = props;

  const { portalBigMagnifier } = useBrandImageUrls();

  const { translate, translateJsx } = useTranslations();

  const {
    isOpen: modalOpen,
    close: closeModal,
    open: openModal,
  } = useModalState();

  const {
    tCompetitorPlanComparisonChartNoCompetitorPlans,
    tCompetitorPlanComparisonChartError,
    tCompetitorPlanComparisonChartViewCompetitors,
  } = translate(competitorPlanComparisonChartTranslations);

  const competitorPlansQuery = useCompetitorPlansQuery({
    searchOptions: {
      offerSnapshotId: offerSnapshot.uuid,
    },
  });

  if (competitorPlansQuery.isPending) {
    return (
      <ChartContainer>
        <NoCompetitorsChartOverlay>
          <RhCircularProgress />
        </NoCompetitorsChartOverlay>
      </ChartContainer>
    );
  }

  if (competitorPlansQuery.isError) {
    return (
      <ChartContainer>
        <NoCompetitorsChartOverlay>
          <NoCompetitorsText>
            {tCompetitorPlanComparisonChartError}
          </NoCompetitorsText>
        </NoCompetitorsChartOverlay>
      </ChartContainer>
    );
  }

  const competitors = groupByCompetitorAndCalculateAverage(
    competitorPlansQuery.data.results
  );

  // Take the 4 most expensive because reasons
  const topCompetitors = getTopCompetitors({
    competitors,
    count: 4,
    offerSnapshot,
  });

  if (topCompetitors.length === 0) {
    return (
      <ChartContainer>
        <NoCompetitorsChartOverlay>
          <img src={portalBigMagnifier} alt="" aria-hidden />
          <NoCompetitorsText>
            {tCompetitorPlanComparisonChartNoCompetitorPlans}
          </NoCompetitorsText>
        </NoCompetitorsChartOverlay>
      </ChartContainer>
    );
  }

  const { tCompetitorPlanComparisonChartLastUpdated } = translateJsx({
    tCompetitorPlanComparisonChartLastUpdated: {
      date: dayjs(competitorPlansQuery.data.results[0]?.parsedDatetime).format(
        LONG_FRIENDLY_DATE_FORMAT
      ),
    },
  });

  return (
    <ComparisonChartContainer>
      <CompetitorPlanChart
        offerSnapshot={offerSnapshot}
        competitorPlans={topCompetitors}
      />

      {showCompetitorPlanList ? (
        <RhButton
          color="primary"
          variant="outlined"
          size="small"
          onClick={openModal}
          data-tracking-click={{
            event: "Competitor plans modal opened",
            offerSnapshotUuid: offerSnapshot.uuid,
          }}
        >
          {tCompetitorPlanComparisonChartViewCompetitors}
        </RhButton>
      ) : null}

      <ChartUpdatedTime>
        {tCompetitorPlanComparisonChartLastUpdated}
      </ChartUpdatedTime>

      {modalOpen && showCompetitorPlanList ? (
        <CompetitorPlanComparisonPlansModal
          offerSnapshot={offerSnapshot}
          competitorPlans={topCompetitors}
          handleClose={closeModal}
          onSelectPlanClick={onSelectPlanClick}
        />
      ) : null}
    </ComparisonChartContainer>
  );
};
