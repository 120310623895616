import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useOneClickRenewalPageOfferSnapshotAtom } from "@portal-guest/atoms/useOneClickRenewalPageOfferSnapshotAtom";
import { useOneClickRenewalQuery } from "@portal-guest/hooks/queries/useOneClickRenewal.query";
import { useOneClickRenewalOfferSnapshotsQuery } from "@portal-guest/hooks/queries/useOneClickRenewalOfferSnapshots.query";
import { oneClickRenewalsPageTranslations } from "@portal-guest/pages/OneClickRenewalPage/OneClickRenewalPage.en.i18n";
import { oneClickRenewalsSummaryPath } from "@portal-guest/routes/routePaths";
import { googleAnalyticsDataAtom } from "@portal-shared/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking.atoms";
import { LoggedOutPageLayout } from "@portal-shared/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { RenewalPageLayout } from "@portal-shared/components/RenewalPageLayout/RenewalPageLayout";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const OneClickRenewalPage = () => {
  const [, setOfferSnapshot] = useOneClickRenewalPageOfferSnapshotAtom();
  const navigate = useNavigate();
  const { renewalToken } = useParams<{
    renewalToken: string;
  }>();
  const setGoogleAnalyticsDataAtom = useSetAtom(googleAnalyticsDataAtom);

  const renewalTokenQuery = useOneClickRenewalQuery({
    enabled: true,
    renewalToken,
  });
  const allOneClickRenewalOfferSnapshots =
    useOneClickRenewalOfferSnapshotsQuery({
      enabled: true,
      renewalToken,
    });
  const [, setLocale] = useLocaleAtom();

  const { translate, translateJsx } = useTranslations();
  const {
    tOneClickRenewalsPageTranslationsExplore,
    tOneClickRenewalsPageTranslationsTitle,
  } = translate(oneClickRenewalsPageTranslations);

  const { tOneClickRenewalsPageTranslationsChoose } = translateJsx({
    tOneClickRenewalsPageTranslationsChoose: {
      address: renewalTokenQuery.data?.address?.deliveryLine1,
    },
  });

  useEffect(() => {
    if (renewalTokenQuery.data?.languagePreference) {
      setLocale(renewalTokenQuery.data?.languagePreference);
    }
  }, [renewalTokenQuery.data?.languagePreference, setLocale]);

  useEffect(() => {
    if (renewalTokenQuery.data) {
      setGoogleAnalyticsDataAtom({
        customerUuid: renewalTokenQuery.data.customerUuid,
        premiseUuid: renewalTokenQuery.data.premiseUuid,
        type: "tokenAuth",
      });
    }
  }, [renewalTokenQuery.data, setGoogleAnalyticsDataAtom]);

  if (
    renewalTokenQuery.isPending ||
    allOneClickRenewalOfferSnapshots.isPending
  ) {
    return (
      <LoggedOutPageLayout>
        <RhCircularProgress />
      </LoggedOutPageLayout>
    );
  }

  if (
    renewalTokenQuery.isError ||
    allOneClickRenewalOfferSnapshots.isError ||
    !renewalTokenQuery?.data?.order ||
    !renewalTokenQuery?.data?.orderOfferSnapshot ||
    !allOneClickRenewalOfferSnapshots?.data?.oneClickRenewalOfferSnapshot
  ) {
    return (
      <LoggedOutPageLayout>
        <Error500Page />
      </LoggedOutPageLayout>
    );
  }

  const handleSelectOfferSnapshot = (offerSnapshot: OfferSnapshot) => {
    setOfferSnapshot(offerSnapshot);
    navigate(oneClickRenewalsSummaryPath(renewalToken));
  };

  return (
    <RenewalPageLayout
      currentOrder={renewalTokenQuery.data.order}
      currentOrderOfferSnapshot={renewalTokenQuery.data.orderOfferSnapshot}
      exploreOtherOffersTitle={tOneClickRenewalsPageTranslationsExplore}
      handleSelectOfferSnapshot={handleSelectOfferSnapshot}
      otherOfferSnapshots={
        allOneClickRenewalOfferSnapshots.data?.myAccountOfferSnapshots
      }
      subtitle={tOneClickRenewalsPageTranslationsChoose}
      title={tOneClickRenewalsPageTranslationsTitle}
      renewalOfferSnapshot={
        allOneClickRenewalOfferSnapshots.data?.oneClickRenewalOfferSnapshot
      }
      estimatedSavings={
        allOneClickRenewalOfferSnapshots.data?.potentialSavingsOneClickOffer
      }
    />
  );
};
