import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { StyledBillFactor } from "@portal-account/components/BillFactors/BillFactors.styled";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface BillFactorTDSPChangeProps {
  differencePercentage: number;
  utilityName: string;
}

export const BillFactorTDSPChange = ({
  differencePercentage,
  utilityName,
}: BillFactorTDSPChangeProps) => {
  const { translateJsx } = useTranslations();
  const { billFactorsTDSPChange } = useBrandImageUrls();
  const {
    tBillFactorsDeliveryChargeChanges,
    tBillFactorsUtilityWhichControls,
  } = translateJsx({
    tBillFactorsDeliveryChargeChanges: { utilityName },
    tBillFactorsUtilityWhichControls: {
      percentage: differencePercentage,
      utilityName,
    },
  });

  return (
    <StyledBillFactor>
      <img src={billFactorsTDSPChange} alt="" aria-hidden />
      <RhTypography fontWeight="Bold">
        {tBillFactorsDeliveryChargeChanges}
      </RhTypography>
      <RhTypography variant="body2">
        {tBillFactorsUtilityWhichControls}
      </RhTypography>
    </StyledBillFactor>
  );
};
