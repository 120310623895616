import { UtilitySearchRequestType } from "@common/types/ediTypes";

export const OneClickRenewalQueryKeys = {
  oneClickRenewal: (renewalToken: string) => [renewalToken, "oneClickRenewal"],
  oneClickRenewalDefaultPaymentMethod: (renewalToken: string) => [
    renewalToken,
    "defaultPaymentMethod",
  ],
  oneClickRenewalOfferSnapshots: (renewalToken: string) => [
    renewalToken,
    "oneClickRenewalOfferSnapshots",
  ],
  utilities: {
    search: (options: UtilitySearchRequestType) => [
      "utilities",
      "search",
      options,
    ],
  },
};
