import { CSSClasses } from "@design-system/components/CssReset/CssReset";
import { ModalPlacement } from "@design-system/components/RhModal/RhModal.styled";
import { CartDialog } from "@portal-enroll/components/CartDialog/CartDialog";
import { CartToggle } from "@portal-enroll/components/CartToggle/CartToggle";
import { useSelectedProductTiers } from "@portal-enroll/hooks/useSelectedProductTiers";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { usePortalOfferSnapshotQuery } from "@portal-shared/hooks/queries/usePortalOfferSnapshot.query";
import React, { useState } from "react";
import { useSelector } from "react-redux";

interface CartDesktopProps {
  invisibleBadgeTimeout?: number;
}

export const CartDesktop = (props: CartDesktopProps) => {
  const { invisibleBadgeTimeout = 750 } = props;
  const selectedProductTiers = useSelectedProductTiers();
  const { offerSnapshotUuid } = useSelector(selectSignUpState);
  const [cartOpen, setCartOpen] = useState<boolean>(false);

  const offerSnapshotQuery = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(offerSnapshotUuid),
    },
  });

  const toggleCart = () => setCartOpen((open) => !open);
  const closeCart = () => {
    document.body.classList.remove(CSSClasses.OverflowHidden);

    setCartOpen(false);
  };

  const offerSnapshot = offerSnapshotQuery.data;

  return offerSnapshot ? (
    <>
      <CartToggle
        offerSnapshot={offerSnapshot}
        handleClick={toggleCart}
        cartOpen={cartOpen}
        invisibleBadgeTimeout={invisibleBadgeTimeout}
        itemCount={selectedProductTiers.length + 1}
      />
      {cartOpen && (
        <CartDialog
          offerSnapshot={offerSnapshot}
          dialogPlacement={ModalPlacement.Right}
          hideModalCloseButton
          closeCart={closeCart}
          selectedProductTiers={selectedProductTiers}
        />
      )}
    </>
  ) : null;
};
