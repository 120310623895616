import { ediApi } from "@common/api/ediApi";
import { prospectApi } from "@common/api/prospectApi";
import { FeatureFlagMetric } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.types";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { determineCreditEvaluationDefinition } from "@common/services/creditEvaluation.service";
import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { AddressFormType } from "@common/types/customerTypes";
import {
  AREA_NOT_SERVICED,
  IRONHIDE_CUSTOMER,
  METER_NOT_SERVICEABLE,
  MULTIPLE_METERS,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
  RhApiError,
} from "@common/types/errorTypes";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useCreateCreditCheckMutation } from "@enroll-data/hooks/mutations/useCreateCreditCheck.mutation";
import { useProspectUpdateMutation } from "@enroll-data/hooks/mutations/useProspectUpdate.mutation";
import { enrollOffersPath } from "@enroll-utils/constants/routePaths";
import { CreditCheckProgress } from "@enroll-utils/types/prospectTypes";
import { EnrollPremiseForm } from "@portal-enroll/components/EnrollPremiseForm/EnrollPremiseForm";
import { IronhideCustomerMessage } from "@portal-enroll/components/IronhideCustomerMessage/IronhideCustomerMessage";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { DUNS_NOT_FOUND } from "@portal-enroll/constants/offer.constant";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { signUpPremisePageTranslations } from "@portal-enroll/pages/SignUpPremisePage/SignUpPremisePage.en.i18n";
import { selectCreditCheckContactValues } from "@portal-enroll/selectors/signUpSelectors";
import {
  ActionType,
  CategoryType,
  EnrollmentEvents,
  track,
} from "@portal-enroll/services/segment.service";
import { formatCreditCheckForTracking } from "@portal-enroll/utils/formatCreditCheckForTracking";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { ESI_ID_NOT_FOUND } from "@portal-shared/constants/offer.constant";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { FORM_ERROR } from "final-form";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export interface SignUpPremiseFormValues {
  additionalInformationRequired: boolean;
  creditEvaluation: CreditScoreOutcome | null;
  depositAlternativeAmount: string | null;
  depositAlternativeElected: boolean;
  depositAmount: number | null;
  dunsNumber: string;
  esiId: string | null;
  serviceAddress: AddressFormType;
  ssnRequired: boolean;
}

const MULTIPLE_METER_ERRORS = new Set([
  MULTIPLE_METERS,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
]);

export const SignUpPremisePage = () => {
  const flash = useRhFlash();
  const { signUpClickNextStepHandler } = useSignUpFlow();
  const { announceError } = useRhAnnouncement();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const customerContactValues = useSelector(selectCreditCheckContactValues);

  const {
    acquisitionMedium,
    creditCheckProgress,
    id: prospectId,
    email,
    dunsNumber: currentDunsNumber,
  } = useProspectFromContext();

  const { featureFlagClient } = useFeatureFlagClient();

  const prospectUpdateMutation = useProspectUpdateMutation();
  const createCreditCheckMutation = useCreateCreditCheckMutation();

  const { prospectCreditFlowV2 } = featureFlagClient.getFlags([
    ["prospectCreditFlowV2", false],
  ]);

  const { translate, translateJsx } = useTranslations();
  const {
    tSignUpPremisePageUnknownErrorProspect,
    tSignUpPremisePageUnknownErrorApiMeterAvailability,
    tSignUpPremisePageWhereShouldWeSendEnergy,
  } = translate(signUpPremisePageTranslations);

  const onSubmit = async (serviceAddress: AddressFormType) => {
    try {
      setIsSubmitting(true);

      const { esiId, dunsNumber, utilityName } = await ediApi.meterAvailability(
        {
          city: serviceAddress.city,
          secondary: serviceAddress.unitNumber,
          state: serviceAddress.state,
          streetLine: serviceAddress.addressLine1,
          zipcode: serviceAddress.zipCode,
        }
      );

      if (currentDunsNumber && dunsNumber !== currentDunsNumber) {
        // the user changed their address enough that
        // we the current offers they were given are no
        // longer valid.
        // So send the user back to choose a new plan
        await prospectUpdateMutation.mutateAsync({
          data: {
            dunsNumber,
            ...serviceAddress,
          },
        });

        const { tSignUpPremisePageUtilityChanged } = translateJsx({
          tSignUpPremisePageUtilityChanged: { utilityName },
        });

        flash.error(tSignUpPremisePageUtilityChanged);
        navigate(enrollOffersPath());
      } else if (esiId) {
        const {
          depositAmount,
          depositAlternativeAmount,
          outcome,
          ssnRequired,
          nosRequired,
          additionalInformationRequired,
        } = await prospectApi.creditScoreEvaluation({
          ...customerContactValues,
          ...serviceAddress,
          acquisitionMedium,
          prospectId,
        });

        await prospectApi.trackEnteredSignup(
          prospectId,
          customerContactValues.email
        );

        if (outcome) {
          const deposit = {
            depositAlternativeAmount: depositAlternativeAmount ?? "0",
            depositAmount: depositAmount ?? 0,
            depositRequired: Boolean(depositAmount),
          };

          track({
            action: ActionType.clickedNextPage,
            category: "address.creditCheckComplete",
            event: EnrollmentEvents.enrollmentClick,
            label: "Credit Worthiness",
            value: determineCreditEvaluationDefinition({
              creditEvaluation: outcome,
              depositAmount,
              ssnRequired,
            }),
            ...deposit,
            additionalInformationRequired,
            nosRequired,
            ssnRequired,
          });
        }

        track({
          action: ActionType.premiseSubmitted,
          category: CategoryType.Premise,
          event: EnrollmentEvents.enrollmentClick,
          label: "Premise Information",
          ...serviceAddress,
        });

        featureFlagClient.trackMetric(
          FeatureFlagMetric.PortalProspectServiceAddressSubmitted
        );

        signUpClickNextStepHandler({
          nextStep: "details",
          signUpData: {
            additionalInformationRequired,
            creditEvaluation: outcome,
            depositAlternativeAmount,
            depositAmount,
            dunsNumber,
            esiId,
            nosRequired,
            serviceAddress,
            ssnRequired,
          },
          track: true,
        });
      } else {
        signUpClickNextStepHandler({
          nextStep: "confirm-address",
          signUpData: {
            dunsNumber,
            esiId: ESI_ID_NOT_FOUND,
            serviceAddress,
          },
          track: true,
        });
      }
    } catch (caughtError: unknown) {
      const error = caughtError as RhApiError;

      if (
        error.data.errorCode === AREA_NOT_SERVICED ||
        error.data.errorCode === METER_NOT_SERVICEABLE
      ) {
        await prospectUpdateMutation.mutateAsync({
          data: {
            zipCode: serviceAddress.zipCode,
          },
        });

        navigate(enrollOffersPath());
      } else if (MULTIPLE_METER_ERRORS.has(error.data.errorCode || "")) {
        signUpClickNextStepHandler({
          nextStep: "confirm-address",
          signUpData: {
            dunsNumber: DUNS_NOT_FOUND,
            esiId: ESI_ID_NOT_FOUND,
            serviceAddress,
          },
          track: true,
        });
      } else if (error.data.errorCode === IRONHIDE_CUSTOMER) {
        announceError(<IronhideCustomerMessage />);
      } else {
        flash.error(tSignUpPremisePageUnknownErrorApiMeterAvailability);

        return {
          [FORM_ERROR]: [tSignUpPremisePageUnknownErrorApiMeterAvailability],
        };
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitNewCreditCheck = async (serviceAddress: AddressFormType) => {
    try {
      const { esiId, dunsNumber, utilityName } = await ediApi.meterAvailability(
        {
          city: serviceAddress.city,
          secondary: serviceAddress.unitNumber,
          state: serviceAddress.state,
          streetLine: serviceAddress.addressLine1,
          zipcode: serviceAddress.zipCode,
        }
      );

      if (currentDunsNumber && dunsNumber !== currentDunsNumber) {
        try {
          await prospectUpdateMutation.mutateAsync({
            data: {
              dunsNumber,
              ...serviceAddress,
            },
          });
        } catch {
          flash.error(tSignUpPremisePageUnknownErrorProspect);
        }

        const { tEnrollPremiseFormUtilityChanged } = translateJsx({
          tEnrollPremiseFormUtilityChanged: { utilityName },
        });

        flash.error(tEnrollPremiseFormUtilityChanged);
        navigate(enrollOffersPath());
      } else if (esiId) {
        try {
          await prospectUpdateMutation.mutateAsync({
            data: { ...serviceAddress },
          });
        } catch {
          flash.error(tSignUpPremisePageUnknownErrorProspect);
        }

        await prospectApi.trackEnteredSignup(prospectId, email || "");

        if (
          !creditCheckProgress ||
          creditCheckProgress === CreditCheckProgress.NOT_STARTED ||
          creditCheckProgress === CreditCheckProgress.STALE
        ) {
          createCreditCheckMutation.mutate(
            {},
            {
              onSuccess: (response) => {
                track({
                  action: ActionType.clickedNextPage,
                  category: "address.creditCheckComplete",
                  event: EnrollmentEvents.enrollmentClick,
                  label: "Credit Worthiness",
                  ...formatCreditCheckForTracking(response),
                });

                track({
                  action: ActionType.premiseSubmitted,
                  category: CategoryType.Premise,
                  event: EnrollmentEvents.enrollmentClick,
                  label: "Premise Information",
                  ...serviceAddress,
                });

                featureFlagClient.trackMetric(
                  FeatureFlagMetric.PortalProspectServiceAddressSubmitted
                );

                signUpClickNextStepHandler({
                  nextStep: "details",
                  signUpData: {
                    creditCheckResponseV2: response,
                    dunsNumber,
                    esiId,
                  },
                  track: true,
                });
              },
            }
          );
        } else {
          signUpClickNextStepHandler({
            nextStep: "details",
            signUpData: {
              dunsNumber,
              esiId,
            },
            track: true,
          });
        }
      }
    } catch (caughtError: unknown) {
      const error = caughtError as RhApiError;

      if (
        error.data.errorCode === AREA_NOT_SERVICED ||
        error.data.errorCode === METER_NOT_SERVICEABLE
      ) {
        try {
          await prospectUpdateMutation.mutateAsync({
            data: {
              zipCode: serviceAddress.zipCode,
            },
          });
        } catch {
          flash.error(tSignUpPremisePageUnknownErrorProspect);
        }

        navigate(enrollOffersPath());
      } else if (
        MULTIPLE_METERS === error.data.errorCode ||
        PRICING_OFFERS_MULTIPLE_UTILITIES === error.data.errorCode
      ) {
        signUpClickNextStepHandler({
          nextStep: "confirm-address",
          signUpData: {
            dunsNumber: DUNS_NOT_FOUND,
            esiId: ESI_ID_NOT_FOUND,
            serviceAddress,
          },
          track: true,
        });
      } else if (error.data.errorCode === IRONHIDE_CUSTOMER) {
        announceError(<IronhideCustomerMessage />);
      } else {
        flash.error(tSignUpPremisePageUnknownErrorApiMeterAvailability);
      }
    }
  };

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerText={tSignUpPremisePageWhereShouldWeSendEnergy}
      />
      <EnrollPremiseForm
        onSubmit={
          prospectCreditFlowV2.value ? onSubmitNewCreditCheck : onSubmit
        }
        disableSubmit={
          prospectCreditFlowV2.value
            ? createCreditCheckMutation.isPending
            : isSubmitting
        }
      />
    </SignUpPageLayout>
  );
};
