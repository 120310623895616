import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import styled from "styled-components";

export const PortalInputLabel = styled.label`
  color: ${CSSVariables.COLOR_GREY_600};
  display: block;
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  padding-bottom: 0.25rem;
`;
