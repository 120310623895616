import { IdType, UUIDType } from "@common/types/apiTypes";
import { PriceTierSnapshotType } from "@common/types/priceTierSnapshotType";
import { Locale } from "@portal-shared/types/LocaleTypes";

export interface EnrollProspectMeterType {
  duns: string;
  premiseType: string;
  utilityName: string;
}
export interface EnrollProspectOfferSnapshotType {
  price2000Kwh: string;
  priceTierSnapshots: PriceTierSnapshotType[];
  promoCode?: string | null;
  promoValue?: number | null;
  rhythmKwhRate: number;
  solarCreditKwhRate: string;
  solarEligible: boolean;
  termMonths: number;
  termsOfServiceEn: string;
  termsOfServiceEs: string;
  title: string;
}

export interface EnrollCampaignPromoType {
  code: string;
  expirationDate?: string;
  id: IdType;
  oneTimeValue: string;
  recurringValue: string;
}

export enum CreditCheckProgress {
  NOT_STARTED = "NOT_STARTED",
  STALE = "STALE",
  SUBMITTED = "SUBMITTED",
}

export type EnrollProspectType = {
  acquisitionCampaign: string | null;
  acquisitionContent: string | null;
  acquisitionMedium: string | null;
  acquisitionSource: string | null;
  addressLine1: string | null;
  areaNotCovered: boolean | null;
  autopay: boolean;
  billingPaymentMethodId?: string | null;
  campaignPromo: EnrollCampaignPromoType | null;
  city: string | null;
  creditCheckProgress: CreditCheckProgress | null;
  dateOfBirth: string | null;
  depositAlternativeElected: boolean;
  dunsNumber: string | null;
  eBillOnly: boolean | null;
  email: string | null;
  enrollmentType?: "MOVE_IN" | "SWITCH" | null;
  estimatedUsageKilowattHours: number | null;
  featureFlagUserId: string | null;
  fingerprintId: string | null;
  firstName: string | null;
  googleAnalyticsUserPseudoId: string | null;
  googleClientId: string | null;
  hasCustomerEmailConflict: boolean;
  id: string;
  isPaperless: boolean;
  languagePreference: Locale | null;
  lastName: string | null;
  marketingEmailOptIn: boolean | null;
  meter: EnrollProspectMeterType | null;
  meterId: string | null;
  offerSnapshotUuid: UUIDType | null;
  offersnapshot: EnrollProspectOfferSnapshotType | null;
  offersnapshotId: IdType | null;
  phone: string | null;
  productPrices: UUIDType[] | null;
  rcid: string | null;
  referralCode: string | null;
  segmentAnonId: string | null;
  serviceStartDate: string | null;
  state: string | null;
  unitNumber: string | null;
  uuid: string;
  zipCode: string | null;
};

export interface ProspectCreateRequest {
  acquisitionCampaign?: string | null;
  acquisitionContent?: string | null;
  acquisitionMedium?: string | null;
  acquisitionSource?: string | null;
  featureFlagUserId?: string | null;
  googleAnalyticsUserPseudoId?: string | null;
  googleClientId?: string | null;
  languagePreference?: string | null;
  rcid?: string | null;
  referralCode?: string | null;
  segmentAnonId?: string | null;
  zipCode?: string | null;
}

export interface ProspectUpdateRequestType {
  acquisitionCampaign?: string;
  acquisitionContent?: string;
  acquisitionMedium?: string;
  acquisitionSource?: string;
  addressLine1?: string;
  areaNotCovered?: boolean | null;
  autopay?: boolean;
  billingPaymentMethodId?: string | null;
  city?: string;
  dateOfBirth?: string;
  depositAlternativeElected?: boolean;
  dunsNumber?: string;
  eBillOnly?: boolean;
  email?: string;
  enrollmentType?: "MOVE_IN" | "SWITCH";
  estimatedUsageKilowattHours?: number;
  featureFlagUserId?: string;
  fingerprintId?: string;
  firstName?: string;
  googleAnalyticsUserPseudoId?: string;
  googleClientId?: string;
  isPaperless?: boolean;
  languagePreference?: string | null;
  lastName?: string;
  marketingEmailOptIn?: boolean;
  meterId?: string;
  offersnapshotId?: string;
  phone?: string;
  productPrices?: string[];
  rcid?: string;
  referralCode?: string;
  segmentAnonId?: string;
  serviceStartDate?: string;
  state?: string;
  unitNumber?: string;
  zipCode?: string;
}
