import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { AppBrands } from "@common/enums/appBrands.enum";
import { getAppBrand } from "@common/services/getEnvApplicationSettings.service";
import { AdditionalServicesDevices } from "@portal-account/components/AdditionalServicesDevices/AdditionalServicesDevices";
import { AdditionalServicesProductAddons } from "@portal-account/components/AdditionalServicesProductAddons/AdditionalServicesProductAddons";
import { StyledAdditionalServicesSection } from "@portal-account/components/AdditionalServicesSection/AdditionalServicesSection.styled";
import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { SwiperCarousel } from "@portal-account/components/SwiperCarousel/SwiperCarousel";
import { useDeviceEligibilityInformationQuery } from "@portal-account/hooks/queries/useDeviceEligibilityInformation.query";
import { usePremiseAttachedDevicesQuery } from "@portal-account/hooks/queries/usePremiseAttachedDevices.query";
import { useProductContractsQuery } from "@portal-account/hooks/queries/useProductContracts.query";
import { usePortalOfferSnapshotQuery } from "@portal-shared/hooks/queries/usePortalOfferSnapshot.query";
import React from "react";

export const AdditionalServicesSection = () => {
  const customer = useCustomerFromContext();
  const { premise } = usePremiseFromContext();
  const appBrand = getAppBrand();
  const { featureFlagClient } = useFeatureFlagClient();

  const { portalDeviceManagement } = featureFlagClient.getFlags([
    ["portalDeviceManagement", false],
  ]);

  const productContractQuery = useProductContractsQuery(premise.id);

  const attachedDevicesQuery = usePremiseAttachedDevicesQuery({
    premiseId: premise.id,
  });

  const {
    data: deviceEligibilityInformationData,
    isPending: deviceEligibleDataIsPending,
    isError: deviceEligibleDataIsError,
  } = useDeviceEligibilityInformationQuery({
    customerId: customer.id,
  });

  const {
    data: currentOrderOfferSnapshotData,
    isPending: currentOrderOfferSnapshotIsPending,
  } = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: premise.currentOrder?.offerSnapshotUuid ?? "",
  });

  if (deviceEligibleDataIsPending || currentOrderOfferSnapshotIsPending) {
    return null;
  }

  if (productContractQuery.isPending || productContractQuery.isError) {
    return null;
  }

  if (attachedDevicesQuery.isPending || attachedDevicesQuery.isError) {
    return null;
  }

  // Hide additional services section if the customer has a device and is on Energy Texas
  if (
    appBrand !== AppBrands.RHYTHM &&
    attachedDevicesQuery.data.thermostats.length > 0
  ) {
    return null;
  }

  if (
    productContractQuery.data.count > 0 &&
    attachedDevicesQuery.data.thermostats.length > 0
  ) {
    return null;
  }

  const hasMultipleSlides =
    productContractQuery.data.count === 0 &&
    attachedDevicesQuery.data.thermostats.length === 0 &&
    deviceEligibilityInformationData?.thermostatEligible;

  return (
    <StyledAdditionalServicesSection>
      <SwiperCarousel
        hasPagination={Boolean(hasMultipleSlides)}
        swiperProps={{
          "autoplay-delay": "10000",
          "autoplay-disable-on-interaction": "false",
          navigation: "true",
          pagination: "true",
          "pagination-clickable": "true",
        }}
      >
        {portalDeviceManagement.value &&
        deviceEligibilityInformationData?.thermostatEligible &&
        attachedDevicesQuery.data.thermostats.length === 0 &&
        !deviceEligibleDataIsError ? (
          <swiper-slide>
            <AdditionalServicesDevices
              credit={premise.formattedThermostatManagementMonthlyDiscount}
              thermostatRequired={
                currentOrderOfferSnapshotData?.isThermostatOffer ?? false
              }
            />
          </swiper-slide>
        ) : null}
        {productContractQuery.data.count === 0 &&
        appBrand === AppBrands.RHYTHM ? (
          <swiper-slide>
            <AdditionalServicesProductAddons />
          </swiper-slide>
        ) : null}
      </SwiperCarousel>
    </StyledAdditionalServicesSection>
  );
};
