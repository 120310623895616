import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import React, { PropsWithChildren } from "react";

interface YRACLinkProps {
  dataTrackingClick: {
    [key: string]: string;
    location: string;
  };
  onClick?: () => void;
  yracUrl: string;
}

export const YRACLink = (props: PropsWithChildren<YRACLinkProps>) => {
  const { dataTrackingClick, onClick, yracUrl, children } = props;

  return (
    <RhAnchor
      data-tracking-click={JSON.stringify({
        event: "YRAC download",
        ...dataTrackingClick,
      })}
      rel="noopener noreferrer"
      target="_blank"
      href={yracUrl}
      onClick={onClick}
    >
      {children}
    </RhAnchor>
  );
};
