import { useAccountModals } from "@portal-account/components/AccountModalsManager/useAccountModals";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { useGetCustomerRewards } from "@portal-account/hooks/useGetCustomerRewards";
import { usePortalCookies } from "@portal-account/hooks/usePortalCookies";
import { usePortalOfferSnapshotQuery } from "@portal-shared/hooks/queries/usePortalOfferSnapshot.query";
import { useEffect } from "react";

export enum SwitchPrintToPaperlessModalStates {
  HIDDEN = "hidden",
  SHOW = "show",
}

export const useSwitchPrintToPaperlessModal = () => {
  const { premise } = usePremiseFromContext();
  const { rewards } = useGetCustomerRewards();
  const { cookies } = usePortalCookies();

  const { rhSwitchPrintToPaperlessModal } = cookies;

  const offerSnapshotQuery = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: premise.currentOrder?.offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offerSnapshotUuid),
    },
  });

  const { addModal } = useAccountModals();

  useEffect(() => {
    if (!rewards || !offerSnapshotQuery.isSuccess) {
      return;
    }

    if (premise.isPrint && rhSwitchPrintToPaperlessModal === "show") {
      const hasPaperlessOrEBillReward = rewards.history.some(
        (reward) => reward.name === "eBill" || reward.name === "Paperless"
      );

      const offerSnapshot = offerSnapshotQuery.data;

      const showRewardsIncentive =
        !hasPaperlessOrEBillReward &&
        !offerSnapshot.isAutoPayPaperlessDiscountOffer;

      addModal({
        switchPrintToPaperless: {
          premiseId: premise.id,
          showRewardsIncentive,
        },
      });
    }
  }, [
    addModal,
    offerSnapshotQuery.isSuccess,
    premise,
    rewards,
    rhSwitchPrintToPaperlessModal,
  ]);

  return null;
};
