import { IdType } from "@common/types/apiTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacing } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { FormUploadClaim } from "@portal-account/components/ProductContractClaimManagement/FormUploadClaim/FormUploadClaim";
import { PhoneClaim } from "@portal-account/components/ProductContractClaimManagement/PhoneClaim";
import { useProductContractClaimOptionsQuery } from "@portal-account/hooks/queries/useProductContractClaimOptions.query";
import { ProductContractType } from "@portal-account/types/productContractTypes";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React from "react";
import styled from "styled-components";

interface ProductContractClaimManagementProps {
  premiseId: IdType;
  productContract: ProductContractType;
}

const components = {
  FORM: FormUploadClaim,
  PHONE: PhoneClaim,
};

const StyledDetails = styled.details`
  &:not(:last-child) {
    margin-bottom: ${rhSpacing(4)}px;
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    & > summary {
      list-style: none;
    }
    &[data-disabled="true"] {
      & > summary {
        pointer-events: none;
      }
    }
  }
`;

const StyledTypography = styled(RhTypography)`
  &.MuiTypography-root {
    margin-bottom: ${rhSpacing(1)}px;
  }
`;

const ClaimOptionContainer = styled.div`
  & > * {
    margin-top: ${rhSpacing(2)}px;
  }
`;

export const ProductContractClaimManagement = ({
  productContract,
  premiseId,
}: ProductContractClaimManagementProps) => {
  const theme = useTheme();
  const { tJsx } = useRhIntl();
  const isDesktop = useMediaQuery(theme.breakpoints.up(RhythmBreakpoints.SM));

  const claimsQuery = useProductContractClaimOptionsQuery(
    premiseId,
    productContract.id
  );

  const claimOptions = claimsQuery.data ?? [];

  return (
    <>
      {claimOptions.map((claim) => {
        const fileAClaim = tJsx("ProductContractClaimManagement.fileAClaim", {
          claimName: claim.name,
        });

        return (
          <StyledDetails
            key={`${productContract.id}-Claim-${claim.name}`}
            // If on desktop force the detail open and disable collapsing
            {...(isDesktop && { "data-disabled": true, open: true })}
          >
            <StyledTypography variant="h3" component="summary">
              {fileAClaim}
            </StyledTypography>
            <StyledTypography variant="body1">
              {claim.description}
            </StyledTypography>
            <ClaimOptionContainer>
              {claim.contactMethods.map((contactMethod) => {
                const ClaimComponent = components[contactMethod.type];

                return (
                  <ClaimComponent
                    key={contactMethod.type}
                    phoneNumber={contactMethod.value}
                    downloadUrl={contactMethod.value}
                    productContract={productContract}
                    premiseId={premiseId}
                  />
                );
              })}
            </ClaimOptionContainer>
          </StyledDetails>
        );
      })}
    </>
  );
};
