import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { enrollOffersPageHeaderTranslations } from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageHeader/EnrollOffersPageHeader.en.i18n";
import {
  EnrollOffersPageHeaderContainer,
  StyledHeader,
} from "@portal-enroll/pages/EnrollOffersPage/EnrollOffersPageHeader/EnrollOffersPageHeader.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const EnrollOffersPageHeader = () => {
  const { pathname } = useLocation();

  const { translate, translateJsx } = useTranslations();

  const { tEnrollOffersPageHeaderTitle } = translate(
    enrollOffersPageHeaderTranslations
  );
  const { tEnrollOffersPageHeaderSubtitle } = translateJsx({
    tEnrollOffersPageHeaderSubtitle: {
      link: (text: string) => (
        <HashLink
          to={{
            hash: "#see-for-yourself",
            pathname,
          }}
        >
          {text}
        </HashLink>
      ),
    },
  });

  return (
    <EnrollOffersPageHeaderContainer>
      <StyledHeader>
        <RhTypography variant="h1">{tEnrollOffersPageHeaderTitle}</RhTypography>
        <RhTypography variant="subtitle1">
          {tEnrollOffersPageHeaderSubtitle}
        </RhTypography>
      </StyledHeader>
    </EnrollOffersPageHeaderContainer>
  );
};
