export const myAccountNavTranslations = {
  tMyAccountNavBillingPreferences: "Billing Preferences",
  tMyAccountNavChangePassword: "Change Password",
  tMyAccountNavCommunicationPreferences: "Communication Preferences",
  tMyAccountNavContactInfo: "Contact Info",
  tMyAccountNavLanguagePreference: "Language Preference",
  tMyAccountNavLogout: "Log Out",
  tMyAccountNavPaymentMethods: "Payment Methods",
  tMyAccountNavProductAddOns: "Additional Services",
  tMyAccountNavServiceInfo: "Service Info",
  tMyAccountNavYourPlan: "Your Plan",
};
