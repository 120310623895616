import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export const CreatePasswordFormContainer = styled.div`
  margin-top: 30px;
`;

export const CreatePasswordFormCtaButton = styled(RhButton)`
  &.MuiButton-root {
    height: 50px;
    margin-bottom: ${rhSpacingPx(3)};
    width: 100%;
  }
`;

export const CreatePasswordFormCircularProgress = styled(CircularProgress)`
  margin-right: ${rhSpacingPx(1)};
`;

export const CreatePasswordFormPasswordRequirements = styled(RhTypography)`
  &.MuiTypography-root {
    display: block;
    margin-bottom: ${rhSpacingPx(4)};
    margin-top: ${rhSpacingPx(2)};
    text-align: center;
  }
`;
