import { CommunicationPreferenceType } from "@common/types/premiseTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { MyAccountCommunicationPreferences } from "@portal-account/components/MyAccountCommunicationPreferences/MyAccountCommunicationPreferences";
import { myAccountCommunicationPreferencesCardTranslations } from "@portal-account/components/MyAccountCommunicationPreferencesCard/MyAccountCommunicationPreferencesCard.en.i18n";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import { usePortalOfferSnapshotQuery } from "@portal-shared/hooks/queries/usePortalOfferSnapshot.query";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const MyAccountCommunicationPreferencesCard = () => {
  const { translate, translateJsx } = useTranslations();

  const { premise } = usePremiseFromContext();
  const offerSnapshotQuery = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: premise.currentOrder?.offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offerSnapshotUuid),
    },
  });

  const {
    tMyAccountCommunicationPreferencesCardTitle,
    tMyAccountCommunicationPreferencesCardErrorFetching,
  } = translate(myAccountCommunicationPreferencesCardTranslations);

  if (offerSnapshotQuery.isPending) {
    return (
      <PortalCard>
        <PortalCardHeader>
          <PortalCardTitle>
            {tMyAccountCommunicationPreferencesCardTitle}
          </PortalCardTitle>
        </PortalCardHeader>
        <RhCircularProgress />
      </PortalCard>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <PortalCard>
        <PortalCardHeader>
          <PortalCardTitle>
            {tMyAccountCommunicationPreferencesCardTitle}
          </PortalCardTitle>
        </PortalCardHeader>
        <RhTypography>
          {tMyAccountCommunicationPreferencesCardErrorFetching}
        </RhTypography>
      </PortalCard>
    );
  }

  const offerSnapshot = offerSnapshotQuery.data;

  const { tMyAccountCommunicationPreferencesCardPaperlessDisabledWarning } =
    translateJsx({
      tMyAccountCommunicationPreferencesCardPaperlessDisabledWarning: {
        discountAmount: formatCurrency(
          offerSnapshot.autopayPaperlessMonthlyDiscount ?? 0
        ),
      },
    });

  const showPaperlessWarning =
    offerSnapshot.isAutoPayPaperlessDiscountOffer &&
    premise.communicationPreference !== CommunicationPreferenceType.PAPERLESS;

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>
          {tMyAccountCommunicationPreferencesCardTitle}
        </PortalCardTitle>

        {showPaperlessWarning ? (
          <RhTypography variant="body1" color="textSecondary">
            {tMyAccountCommunicationPreferencesCardPaperlessDisabledWarning}
          </RhTypography>
        ) : null}
      </PortalCardHeader>
      <MyAccountCommunicationPreferences
        premise={premise}
        offerSnapshot={offerSnapshot}
      />
    </PortalCard>
  );
};
