import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  LOGGED_OUT_FORM_WIDTH,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { EnrollContactForm } from "@portal-enroll/components/EnrollContactForm/EnrollContactForm";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { signUpContactPageTranslations } from "@portal-enroll/pages/SignUpContactPage/SignUpContactPage.en.i18n";
import { LOGGED_OUT_FIELD_SPACING } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.constants";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const SignUpContactPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));

  const { firstName } = useProspectFromContext();
  const { translate, translateJsx } = useTranslations();

  const { tSignUpContactPageCreditCheckExplainer } = translate(
    signUpContactPageTranslations
  );

  const { tSignUpContactPageLetsKeepInTouch } = translateJsx({
    tSignUpContactPageLetsKeepInTouch: { firstName },
  });

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader headerText={tSignUpContactPageLetsKeepInTouch} />
      <Box paddingBottom={LOGGED_OUT_FIELD_SPACING}>
        <EnrollContactForm />
      </Box>
      <Box width={isMobile ? "100%" : LOGGED_OUT_FORM_WIDTH}>
        <RhTypography variant="body2" color="textSecondary">
          {tSignUpContactPageCreditCheckExplainer}
        </RhTypography>
      </Box>
    </SignUpPageLayout>
  );
};
