import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

const labelPercentageWidth = 38;
const valuePercentageWidth = 100 - labelPercentageWidth;

export const StyledSummaryInfoContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  width: 100%;
`;

export const StyledLabelContainer = styled.div`
  display: flex;
  flex-basis: ${labelPercentageWidth}%;
  justify-content: flex-end;
  margin-bottom: ${rhSpacingPx(0.5)};
  margin-inline-start: 0;
  overflow: hidden;
`;

export const StyledValueContainer = styled.div`
  display: flex;
  flex-basis: ${valuePercentageWidth}%;
  gap: 1rem;
  margin-bottom: ${rhSpacingPx(2)};
  margin-inline-start: 0;
  overflow: hidden;
  text-transform: capitalize;
`;

export const StyledCell = styled(RhTypography)`
  &.MuiTypography-root {
    /* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
    word-break: break-word;
  }
`;
