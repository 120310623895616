import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled, { css } from "styled-components";

const calculateMDGridTemplateColumns = (offerCount?: number): string => {
  if (offerCount && offerCount < 3) {
    return `repeat(${offerCount}, 1fr)`;
  }

  if (offerCount === 4) {
    return "repeat(4, 1fr)";
  }

  return "repeat(3, 1fr)";
};

interface RhOffersGridProps {
  $maxWidth?: number;
  $noPadding?: boolean;
  $offerCount?: number;
}

export const StyledRhOffersGrid = styled.div<RhOffersGridProps>`
  align-items: stretch;
  display: grid;
  grid-gap: ${rhSpacingPx(3)};
  justify-items: center;
  margin: 0 auto;
  max-width: 100%;
  padding: ${({ $noPadding }) => ($noPadding ? 0 : `0 ${rhSpacingPx(3)}`)};
  width: 100%;

  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      @media screen and (min-width: ${RhythmBreakpoints.XS}px) {
        max-width: ${$maxWidth}px;
      }
    `};

  ${({ $offerCount }) => `
    @media (min-width: ${RhythmBreakpoints.XS}px) {
      grid-template-columns: ${$offerCount === 1 ? "1fr" : "repeat(2, 1fr)"};
    }

    @media (min-width: ${RhythmBreakpoints.SM}px) {
      grid-template-columns: ${
        $offerCount && $offerCount < 3
          ? `repeat(${$offerCount}, 1fr)`
          : "repeat(3, 1fr)"
      };
    }

    @media (min-width: ${RhythmBreakpoints.MD}px) {
      grid-template-columns: ${calculateMDGridTemplateColumns($offerCount)};
    }
  `}
`;
