export const enrollIdUploadPageTranslations = {
  tEnrollIdUploadPageError:
    "We weren't able to get your credit check results. Try again later!",
  tEnrollIdUploadPageGetStarted: "Get Started",
  tEnrollIdUploadPageGotIt: "Got it. Hang tight.",
  tEnrollIdUploadPageItLooks: "It looks like we need a bit more info.",
  tEnrollIdUploadPageWeAreVerifying:
    "We're verifying your documents and should be done in a few seconds.",
  tEnrollIdUploadPageWeNeed:
    "We need to verify your government-issued photo ID, which you can complete in a few minutes by clicking below.",
};
