import { IdType } from "@common/types/apiTypes";

export const urls = {
  chat: {
    availability: () => "/api/chat-availability",
  },
  contracts: {
    expirationNotices: {
      download: (premiseId: IdType, id: IdType) =>
        `/api/premises/${premiseId}/contracts/expirations-notices/${id}/download/`,
      list: (premiseId: IdType) =>
        `/api/premises/${premiseId}/contracts/expirations-notices/`,
    },
  },
  customers: {
    create: () => "/api/customers/",
    index: () => "/api/customers/",
    loyaltyProgram: {
      retrieve: (id: IdType) => `/api/customers/${id}/rewards/`,
    },
    me: () => "/api/customers/me/",
    password: {
      change: (customerId: IdType) =>
        `/api/customers/${customerId}/change_password`,
      getStateToken: () => "/api/customers/get_set_password_state_token/",
      sendResetEmail: () => "/api/customers/reset_password_email/",
    },
    retrieve: (id: IdType) => `/api/customers/${id}/`,
    search: (query: string) =>
      `/api/customers/search/?search=${encodeURIComponent(query)}`,
    tearDown: (id: IdType) => `/api/customers/${id}/tear-down`,
    unsubscribedChannelIds: (id: IdType) =>
      `/api/customers/${id}/unsubscribed_channel_ids/`,
    update: (id: IdType) => urls.customers.retrieve(id),
    updateSubscriptionStatus: (id: IdType) =>
      `/api/customers/${id}/update_subscription_status/`,
  },
  ediProvider: {
    getAllowableInboundEDIActions: () =>
      "/api/edi-provider/allowable-inbound-actions",
    triggerEdiGeneration: () => "/api/edi-provider/generate-edi/",
  },
  pricing: {
    campaigns: {
      index: (): string => "/api/pricing/campaigns/",
    },
    offerSnapshots: {
      availability: (): string => "/api/pricing/offer-snapshots/availability/",
      list: (): string => "/api/pricing/offer-snapshots/",
      retrieve: (id: IdType): string => `/api/pricing/offer-snapshots/${id}`,
    },
    offers: {
      create: () => urls.pricing.offers.list(),
      list: (): string => "/api/pricing/offers/",
      retrieve: (id: IdType) => `/api/pricing/offers/${id}/`,
      update: (id: IdType) => urls.pricing.offers.retrieve(id),
    },
  },
};
