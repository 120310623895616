import { BrandLogo } from "@common/components/BrandLogo";
import { DynamicLink } from "@common/components/DynamicLink/DynamicLink";
import { ReactComponent as FacebookLogo } from "@common/images/FacebookLogo.svg";
import { ReactComponent as InstagramLogo } from "@common/images/InstagramLogo.svg";
import { ReactComponent as LinkedInLogo } from "@common/images/LinkedInLogo.svg";
import { ReactComponent as TwitterLogo } from "@common/images/TwitterLogo.svg";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { Grid, Link, ListItem } from "@mui/material";
import { AccountFooterLoggedInTranslations } from "@portal-account/components/AccountFooterLoggedIn/AccountFooterLoggedIn.en.i18n";
import {
  StyledAllRightsReserved,
  StyledAllRightsReservedContainer,
  StyledFlexPortalFooterColumn,
  StyledPortalFooter,
  StyledPortalFooterInner,
  StyledPortalFooterLogoRoot,
  StyledPortalFooterResourcesLink,
  StyledPortalFooterResourcesLinkList,
  StyledPortalFooterSocialContainer,
  StyledResourcesContainer,
  StyledRhythmLogoContainer,
} from "@portal-account/components/AccountFooterLoggedIn/AccountFooterLoggedIn.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { customerHomePath } from "@portal-shared/routes/routePaths";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React from "react";

export const AccountFooterLoggedIn = () => {
  const { translate } = useTranslations();

  const {
    tAccountFooterLoggedInAllRightsReserved,
    tAccountFooterLoggedInFAQ,
    tAccountFooterLoggedInFacebook,
    tAccountFooterLoggedInHome,
    tAccountFooterLoggedInInstagram,
    tAccountFooterLoggedInLinkedin,
    tAccountFooterLoggedInPrivacyPolicy,
    tAccountFooterLoggedInResources,
    tAccountFooterLoggedInTermsOfUse,
    tAccountFooterLoggedInTwitter,
  } = translate(AccountFooterLoggedInTranslations);

  return (
    <StyledPortalFooter>
      <StyledPortalFooterInner>
        <StyledFlexPortalFooterColumn>
          <Grid container>
            <Grid item xs={12} md={2}>
              <StyledRhythmLogoContainer>
                <DynamicLink
                  to={customerHomePath()}
                  external={false}
                  aria-label={tAccountFooterLoggedInHome}
                >
                  <BrandLogo />
                </DynamicLink>
              </StyledRhythmLogoContainer>
            </Grid>
            <Grid item xs={12} md={2}>
              <StyledResourcesContainer>
                <RhTypography variant="h3">
                  {tAccountFooterLoggedInResources}
                </RhTypography>
              </StyledResourcesContainer>
              <StyledPortalFooterResourcesLinkList dense>
                <ListItem>
                  <StyledPortalFooterResourcesLink
                    href={brandInformation.urlsPrivacyPolicy}
                    color="textPrimary"
                  >
                    <RhTypography>
                      {tAccountFooterLoggedInPrivacyPolicy}
                    </RhTypography>
                  </StyledPortalFooterResourcesLink>
                </ListItem>
                <ListItem>
                  <StyledPortalFooterResourcesLink
                    href={brandInformation.urlsTermsOfUse}
                    color="textPrimary"
                  >
                    <RhTypography>
                      {tAccountFooterLoggedInTermsOfUse}
                    </RhTypography>
                  </StyledPortalFooterResourcesLink>
                </ListItem>
                <ListItem>
                  <StyledPortalFooterResourcesLink
                    href={brandInformation.urlsFaqsHome}
                    color="textPrimary"
                  >
                    <RhTypography>{tAccountFooterLoggedInFAQ}</RhTypography>
                  </StyledPortalFooterResourcesLink>
                </ListItem>
              </StyledPortalFooterResourcesLinkList>
            </Grid>
            <Grid item xs={false} md={5} />
            <Grid item xs={12} md={3}>
              <StyledPortalFooterLogoRoot>
                <StyledPortalFooterSocialContainer>
                  <Link
                    href={brandInformation.socialMediaFacebook}
                    component="a"
                    aria-label={tAccountFooterLoggedInFacebook}
                  >
                    <FacebookLogo />
                  </Link>
                </StyledPortalFooterSocialContainer>
                <StyledPortalFooterSocialContainer>
                  <Link
                    href={brandInformation.socialMediaTwitter}
                    component="a"
                    aria-label={tAccountFooterLoggedInTwitter}
                  >
                    <TwitterLogo />
                  </Link>
                </StyledPortalFooterSocialContainer>
                <StyledPortalFooterSocialContainer>
                  <Link
                    href={brandInformation.socialMediaLinkedIn}
                    component="a"
                    aria-label={tAccountFooterLoggedInLinkedin}
                  >
                    <LinkedInLogo />
                  </Link>
                </StyledPortalFooterSocialContainer>
                <StyledPortalFooterSocialContainer>
                  <Link
                    href={brandInformation.socialMediaInstagram}
                    component="a"
                    aria-label={tAccountFooterLoggedInInstagram}
                  >
                    <InstagramLogo />
                  </Link>
                </StyledPortalFooterSocialContainer>
              </StyledPortalFooterLogoRoot>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <StyledAllRightsReservedContainer>
              <StyledAllRightsReserved
                color="textSecondary"
                align="center"
                variant="body1"
              >
                {tAccountFooterLoggedInAllRightsReserved}
              </StyledAllRightsReserved>
            </StyledAllRightsReservedContainer>
          </Grid>
        </StyledFlexPortalFooterColumn>
      </StyledPortalFooterInner>
    </StyledPortalFooter>
  );
};
