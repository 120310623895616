import { ajax } from "@common/services/ajax";
import { customerUrls } from "@portal-account/api/customerUrls";
import { Customer } from "@portal-account/models/Customer.model";
import {
  CustomerType,
  CustomerUpdateContactInfoType,
} from "@portal-account/types/customerTypes";
import { DeviceEligibilityInformationType } from "@portal-account/types/deviceEligibilityInformationTypes";
import {
  DeviceBrands,
  ResideoAttachDevicesType,
  ResideoDeviceResponse,
} from "@portal-account/types/devicesTypes";
import {
  ResideoOauthResponseType,
  ResideoOauthType,
} from "@portal-account/types/resideoOauthTypes";

export const customerApi = {
  attachResideoDevices: ({
    deviceBrand,
    thermostats,
    userId,
  }: ResideoAttachDevicesType) => {
    return ajax.post(customerUrls.attachResideoDevices(), {
      deviceBrand,
      thermostats,
      userId,
    });
  },
  deviceEligibilityInformation: () => {
    return ajax.get<DeviceEligibilityInformationType>(
      customerUrls.deviceEligibilityInformation()
    );
  },
  getResideoOauthUrl: (deviceBrand: DeviceBrands | null) => {
    return ajax.get<ResideoOauthResponseType>(
      customerUrls.getResideoOauthUrl(deviceBrand)
    );
  },
  listResideoDevices: ({ oauthCode, deviceBrand }: ResideoOauthType) => {
    return ajax.get<ResideoDeviceResponse>(
      customerUrls.listResideoDevices({
        deviceBrand,
        oauthCode,
      })
    );
  },
  me: async (): Promise<Customer> => {
    const response = await ajax.get<CustomerType>(customerUrls.me());

    if (import.meta.env.VITE_CONTRACT_API_ENV) {
      return response;
    }
    return new Customer(response);
  },
  trackCustomerLogin: () => {
    ajax.post(customerUrls.trackCustomerLogin(), {});
  },
  updateContactInfo: (customerUpdateData: CustomerUpdateContactInfoType) => {
    return ajax.patch(customerUrls.updateContactInfo(), customerUpdateData);
  },
};
