import { useDebounce } from "@common/hooks/useDebounce";
import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { zuora } from "@common/utils/zuora.util";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { loggedOutZuoraCardFormTranslations } from "@portal-shared/components/LoggedOutZuoraCardForm/LoggedOutZuoraCardForm.en.i18n";
import { StyledLoggedOutZuoraCardForm } from "@portal-shared/components/LoggedOutZuoraCardForm/LoggedOutZuoraCardForm.styled";
import { useFetchRSASignatureQuery } from "@portal-shared/hooks/queries/useFetchRSASignature.query";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import {
  CreditCardFormError,
  ZuoraInlineFormError,
} from "@portal-shared/services/ZuoraInlineFormError.service";
import { getZuoraParams } from "@portal-shared/utils/baseZuoraParams.util";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";

interface LoggedOutZuoraCardFormProps {
  onFailure?(error: string): void;
  onSuccess(zuoraResponse: ZuoraAddPaymentResponseType): void;
}

export const LoggedOutZuoraCardForm = ({
  onSuccess,
  onFailure,
}: LoggedOutZuoraCardFormProps) => {
  const flash = useRhFlash();
  const { translate } = useTranslations();
  const translations = translate(loggedOutZuoraCardFormTranslations);
  const [isZuoraFormLoading, setZuoraFormLoading] = useState<boolean>(true);

  const zuoraCardForm = useRef<HTMLDivElement>(null);

  const zuoraParams = getZuoraParams(dayjs().locale());
  const fetchRSASignatureQuery = useFetchRSASignatureQuery(zuoraParams.id);

  const {
    tLoggedOutZuoraCardFormAuthorizationFailed,
    tLoggedOutZuoraCardFormErrorAddingCard,
  } = translations;

  /**
   * Zuora can return multiple empty error messages (like when a customer hits submit without filling cc form)
   * We are just debouncing this so at most only one flash error is shown. If form is partially filled
   * then another message will be shown
   */
  const debouncedClientErrorMessageCallback = useDebounce(
    (errorType: string, errorCode: string, errorDescription: string) => {
      const zuoraInlineFormErrorService = new ZuoraInlineFormError(
        errorType,
        errorCode,
        errorDescription
      );

      const { error } = zuoraInlineFormErrorService;

      const errorMessage =
        translations[`tLoggedOutZuoraCardFormInlineFormError.${error}`];

      if (error === CreditCardFormError.tooManyTries) {
        onFailure?.(errorMessage);
      } else {
        flash.error(errorMessage);
      }
    },
    50
  );

  useEffect(() => {
    if (fetchRSASignatureQuery.isPending) {
      return;
    }

    zuora.setEventHandler("onloadCallback", () => {
      setZuoraFormLoading(false);
    });

    zuora.renderWithErrorHandler(
      {
        ...zuoraParams,
        ...fetchRSASignatureQuery.data,
      },
      {},
      (response: ZuoraAddPaymentResponseType) => {
        const errorMessage = tLoggedOutZuoraCardFormErrorAddingCard;

        if (!response.success) {
          onFailure?.(errorMessage);

          return;
        }

        // Everything went well
        onSuccess(response);
      },
      debouncedClientErrorMessageCallback
    );
  }, [fetchRSASignatureQuery.isPending]);

  if (fetchRSASignatureQuery.isError) {
    onFailure?.(tLoggedOutZuoraCardFormAuthorizationFailed);
    return null;
  }

  return (
    <>
      {isZuoraFormLoading && <RhCircularProgress marginBottom={4} />}
      <div ref={zuoraCardForm}>
        <StyledLoggedOutZuoraCardForm
          id="zuora_payment"
          data-testid="zuoraPaymentForm"
        />
      </div>
    </>
  );
};
