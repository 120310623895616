import { AppBrands } from "@common/enums/appBrands.enum";
import { EnvTypes } from "@common/enums/envTypes.enum";
import { rhWindow } from "@common/utils/rhWindow";

export function getEnv(): EnvTypes {
  const appBrand = getAppBrand();

  if (import.meta.env.VITE_CONTRACT_API_ENV) {
    return import.meta.env.VITE_CONTRACT_API_ENV as EnvTypes;
  }

  let baseDomain = "gotrhythm.com";

  if (appBrand === AppBrands.ENERGY_TEXAS) {
    baseDomain = "energytexas.com";
  }

  const { hostname } = rhWindow.location;

  // Run local frontend with the staging backend: `nx serve <app> -c local-staging`
  if (
    hostname.endsWith(`local.${baseDomain}`) &&
    import.meta.env.VITE_LOCAL_STAGING === "true"
  ) {
    return EnvTypes.localStaging;
  }

  if (hostname.endsWith(`local.${baseDomain}`)) {
    return EnvTypes.local;
  }

  if (hostname.endsWith(`staging.${baseDomain}`)) {
    return EnvTypes.staging;
  }

  // Handle production after other environments or else test and staging will return as Production.
  if (hostname.endsWith(baseDomain)) {
    return EnvTypes.production;
  }

  return EnvTypes.local;
}

export function getAppBrand(): AppBrands {
  if (import.meta.env.VITE_APP_BRAND === AppBrands.ENERGY_TEXAS) {
    return AppBrands.ENERGY_TEXAS;
  }

  return AppBrands.RHYTHM;
}

export function isLocal(): boolean {
  return getEnv() === EnvTypes.local || getEnv() === EnvTypes.localStaging;
}

export function isStaging(): boolean {
  return getEnv() === EnvTypes.staging;
}

export function isProduction(): boolean {
  return getEnv() === EnvTypes.production;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEnvApplicationSettings<T = any>(
  allEnvApplicationSettings: Record<EnvTypes, T>
): T {
  const env = getEnv();

  return allEnvApplicationSettings[env];
}
