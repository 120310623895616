import { ajax } from "@common/services/ajax";
import {
  CreatePasswordRequest,
  LoginRequest,
  ResetPasswordRequest,
} from "@portal-shared/types/authnTypes";

const BASE_URL = "/api/portal/authn";

export const authnApi = {
  createPassword: <R = void>(data: CreatePasswordRequest): Promise<R> => {
    return ajax.post(`${BASE_URL}/create-password`, data);
  },
  login: <R = void>(data: LoginRequest): Promise<R> => {
    return ajax.post(`${BASE_URL}/login`, data);
  },
  logout: <R = void>(): Promise<R> => {
    return ajax.post(`${BASE_URL}/logout`, {});
  },
  resetPassword: (data: ResetPasswordRequest): Promise<void> => {
    return ajax.post(`${BASE_URL}/reset-password`, data);
  },
};
