import { prospectApi } from "@enroll-data/api/prospectApi";
import { prospectQueryKeys } from "@enroll-data/queryKeys/prospectQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseCreditCheckQueryProps {
  queryOptions?: {
    enabled?: boolean;
  };
}

export const useCreditCheckQuery = (props: UseCreditCheckQueryProps) => {
  const { queryOptions: { enabled = true } = {} } = props;

  const query = useQuery({
    enabled,
    queryFn: () => prospectApi.fetchCreditCheck(),
    queryKey: prospectQueryKeys.creditCheck(),
  });

  return query;
};
