import { EMAIL_VALIDATION_REGEX } from "@common/constants/regex.constant";
import { maskPhone, unmaskPhone } from "@common/forms/phone.mask";
import { z } from "zod";

export function asOptionalField<T extends z.ZodTypeAny>(schema: T) {
  return schema.optional().or(z.literal("").transform(() => undefined));
}

const enrollContactFormSchema = z.object({
  confirmEmail: z.string(),
  email: z.string(),
  marketingEmailOptIn: asOptionalField(z.boolean()).default(false),
  phone: z.string().transform((value) => {
    const unmaskedValue = unmaskPhone(value);
    const limitedValue = unmaskedValue.slice(0, 10);

    return maskPhone(limitedValue);
  }),
});

interface EnrollContactFormErrorMessages {
  errorEmailsMustMatch: string;
  errorInvalidConfirmEmail: string;
  errorInvalidEmail: string;
  errorInvalidPhoneNumber: string;
}

export const getEnrollContactFormValidation = (
  errorMessages: EnrollContactFormErrorMessages
) => {
  return enrollContactFormSchema
    .refine((data) => EMAIL_VALIDATION_REGEX.test(data.email), {
      message: errorMessages.errorInvalidEmail,
      path: ["email"],
    })
    .refine((data) => EMAIL_VALIDATION_REGEX.test(data.confirmEmail), {
      message: errorMessages.errorInvalidConfirmEmail,
      path: ["confirmEmail"],
    })
    .refine((data) => unmaskPhone(data.phone).length === 10, {
      message: errorMessages.errorInvalidPhoneNumber,
      path: ["phone"],
    })
    .superRefine((data, context) => {
      if (data.email !== data.confirmEmail) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: errorMessages.errorEmailsMustMatch,
          path: ["email"],
        });
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: errorMessages.errorEmailsMustMatch,
          path: ["confirmEmail"],
        });
      }
    });
};

export type EnrollContactFormValues = z.infer<typeof enrollContactFormSchema>;
