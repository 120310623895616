import { customerApi } from "@portal-account/api/customerApi";
import { customerQueryKeys } from "@portal-account/queryKeys/customerQuerykeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCustomerUpdateContactInfoMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: customerApi.updateContactInfo,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: customerQueryKeys.BASE,
      });
    },
  });
};
