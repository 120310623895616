import { prospectApi } from "@common/api/prospectApi";
import { FeatureFlagMetric } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.types";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { determineCreditEvaluationDefinition } from "@common/services/creditEvaluation.service";
import { CreditScoreDataType } from "@common/types/creditCheckTypes";
import { AddressFormType } from "@common/types/customerTypes";
import { RhApiError } from "@common/types/errorTypes";
import { RhPaper } from "@design-system/components/RhPaper/RhPaper";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { EnrollConfirmAddressForm } from "@portal-enroll/components/EnrollConfirmAddressForm/EnrollConfirmAddressForm";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { signUpConfirmAddressPageTranslations } from "@portal-enroll/pages/SignUpConfirmAddressPage/SignUpConfirmAddressPage.en.i18n";
import {
  StyledAddressContainer,
  StyledHeaderContainer,
} from "@portal-enroll/pages/SignUpConfirmAddressPage/SignUpConfirmAddressPage.styled";
import {
  selectCreditCheckContactValues,
  selectCreditCheckValues,
  selectProspectId,
  selectSignUpAcquisition,
} from "@portal-enroll/selectors/signUpSelectors";
import {
  ActionType,
  CategoryType,
  EnrollmentEvents,
  track,
} from "@portal-enroll/services/segment.service";
import { LoggedOutForm } from "@portal-shared/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

const ServiceAddress = ({
  addressLine1,
  unitNumber,
  city,
  state,
  zipCode,
}: AddressFormType) => (
  <RhPaper>
    <RhTypography variant="subtitle1" color="textSecondary">
      {addressLine1}
      {unitNumber ? ` ${unitNumber}` : ""}
      <br />
      {city}, {state}
      <br />
      {zipCode}
    </RhTypography>
  </RhPaper>
);

export const SignUpConfirmAddressPage = () => {
  const creditCheckValues = useSelector(selectCreditCheckValues);
  const { email } = useSelector(selectCreditCheckContactValues);
  const { acquisitionMedium } = useSelector(selectSignUpAcquisition);
  const prospectId = useSelector(selectProspectId);
  const flash = useRhFlash();
  const { featureFlagClient } = useFeatureFlagClient();
  const { prospectCreditFlowV2 } = featureFlagClient.getFlags([
    ["prospectCreditFlowV2", false],
  ]);

  const { signUpClickNextStepHandler } = useSignUpFlow();

  const { translate } = useTranslations();
  const {
    tSignUpConfirmAddressPageErrorCreditScoreApi,
    tSignUpConfirmAddressPageConfirm,
    tSignUpConfirmAddressPageAreYouSure,
    tSignUpConfirmAddressPageBeVerySure,
  } = translate(signUpConfirmAddressPageTranslations);

  const serviceAddress: AddressFormType = {
    addressLine1: creditCheckValues.addressLine1,
    city: creditCheckValues.city,
    state: creditCheckValues.state,
    unitNumber: creditCheckValues.unitNumber,
    zipCode: creditCheckValues.zipCode,
  };

  const onAddressConfirmation = (values: CreditScoreDataType) => {
    return prospectApi
      .creditScoreEvaluation({ ...values, acquisitionMedium, prospectId })
      .then(
        ({
          depositAmount,
          outcome,
          ssnRequired,
          additionalInformationRequired,
          depositAlternativeAmount,
        }) => {
          return prospectApi.trackEnteredSignup(prospectId, email).then(() => {
            if (outcome) {
              const deposit = {
                depositAlternativeAmount: depositAlternativeAmount ?? "0",
                depositAmount: depositAmount ?? 0,
                depositRequired: Boolean(depositAmount),
              };

              track({
                action: ActionType.clickedNextPage,
                category: "confirm-address.creditCheckComplete",
                event: EnrollmentEvents.enrollmentClick,
                label: "Credit Worthiness",
                value: determineCreditEvaluationDefinition({
                  creditEvaluation: outcome,
                  depositAmount,
                  ssnRequired,
                }),
                ...deposit,
                ssnRequired,
              });
            }

            track({
              action: ActionType.premiseSubmitted,
              category: CategoryType.Premise,
              event: EnrollmentEvents.enrollmentClick,
              label: "Premise Information",
              ...serviceAddress,
            });

            featureFlagClient.trackMetric(
              FeatureFlagMetric.PortalProspectServiceAddressSubmitted
            );

            signUpClickNextStepHandler({
              nextStep: "details",
              signUpData: {
                additionalInformationRequired,
                creditEvaluation: outcome,
                depositAlternativeAmount,
                depositAmount,
                ssnRequired,
              },
              track: true,
            });
          });
        }
      )
      .catch((_error: RhApiError) => {
        const errorMessage = tSignUpConfirmAddressPageErrorCreditScoreApi;

        flash.error(errorMessage);

        return { [FORM_ERROR]: errorMessage };
      });
  };

  return (
    <SignUpPageLayout>
      <StyledHeaderContainer>
        <LoggedOutPageHeader
          headerText={tSignUpConfirmAddressPageAreYouSure}
          subHeaderText={tSignUpConfirmAddressPageBeVerySure}
        />
      </StyledHeaderContainer>
      <StyledAddressContainer>
        <ServiceAddress {...serviceAddress} />
      </StyledAddressContainer>

      {prospectCreditFlowV2.value ? (
        <EnrollConfirmAddressForm />
      ) : (
        <Form<CreditScoreDataType>
          initialValues={creditCheckValues}
          onSubmit={onAddressConfirmation}
          render={({ handleSubmit }) => (
            <LoggedOutForm
              onSubmit={handleSubmit}
              submitButtonText={tSignUpConfirmAddressPageConfirm}
            />
          )}
        />
      )}
    </SignUpPageLayout>
  );
};
