import { IdType } from "@common/types/apiTypes";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { billBreakdownCardTranslations } from "@portal-account/components/BillBreakdownCard/BillBreakdownCard.en.i18n";
import { BillBreakdownCardSkeleton } from "@portal-account/components/BillBreakdownCard/BillBreakdownCard.skeleton";
import {
  StyledArithmeticIconContainer,
  StyledBillBreakdownCard,
  StyledBillBreakdownContainer,
  StyledBillBreakdownDivider,
  StyledBillBreakdownImageContainer,
  StyledBillBreakdownRow,
  StyledBillBreakdownTitle,
  StyledLearnMoreContainer,
} from "@portal-account/components/BillBreakdownCard/BillBreakdownCard.styled";
import { useInvoiceOrdersQuery } from "@portal-account/hooks/queries/useInvoiceOrders.query";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React from "react";

interface BillBreakdownCardProps {
  invoiceId: IdType;
}

export const BillBreakdownCard = (props: BillBreakdownCardProps) => {
  const { invoiceId } = props;

  const {
    portalLightBulbIcon,
    portalPlusIcon,
    portalTDUIcon,
    portalMultiplyIcon,
    portalCalculatorIcon,
  } = useBrandImageUrls();

  const {
    data: orders,
    isPending,
    isError,
  } = useInvoiceOrdersQuery({ invoiceId });

  const { translate, translateJsx } = useTranslations();
  const {
    tBillBreakdownCardHeresBreakdown,
    tBillBreakdownCardEnergyCharge,
    tBillBreakdownCardThisIsAmountOfElectricity,
    tBillBreakdownCardThisIsWhatYourLocalUtility,
    tBillBreakdownCardTransmission,
    tBillBreakdownCardUsage,
    tBillBreakdownCardLearnMore,
    tBillBreakdownCardLearnMoreAbout,
    tBillBreakdownCardOrderError,
  } = translate(billBreakdownCardTranslations);
  const {
    tBillBreakdownCardYourPlanFixed,
    tBillBreakdownCardYourPlanVariable,
  } = translateJsx({
    tBillBreakdownCardYourPlanFixed: { planName: orders?.[0]?.title ?? "" },
    tBillBreakdownCardYourPlanVariable: { planName: orders?.[0]?.title ?? "" },
  });

  if (isPending) {
    return <BillBreakdownCardSkeleton />;
  }

  if (isError) {
    return (
      <StyledBillBreakdownCard>
        <RhTypography>{tBillBreakdownCardOrderError}</RhTypography>
      </StyledBillBreakdownCard>
    );
  }

  return (
    <StyledBillBreakdownCard>
      <RhTypography variant="subtitle2" fontWeight="Bold">
        {tBillBreakdownCardHeresBreakdown}
      </RhTypography>
      <StyledBillBreakdownDivider />
      <StyledBillBreakdownContainer>
        <StyledBillBreakdownRow>
          <StyledBillBreakdownImageContainer>
            <img src={portalLightBulbIcon} alt="" aria-hidden />
          </StyledBillBreakdownImageContainer>
          <StyledBillBreakdownTitle>
            {tBillBreakdownCardEnergyCharge}
          </StyledBillBreakdownTitle>
          <RhTypography>
            {orders[0]?.isVariableRate
              ? tBillBreakdownCardYourPlanVariable
              : tBillBreakdownCardYourPlanFixed}
          </RhTypography>
        </StyledBillBreakdownRow>
        <StyledArithmeticIconContainer>
          <img src={portalPlusIcon} alt="" aria-hidden />
        </StyledArithmeticIconContainer>
        <StyledBillBreakdownRow>
          <StyledBillBreakdownImageContainer>
            <img src={portalTDUIcon} alt="" aria-hidden />
          </StyledBillBreakdownImageContainer>
          <StyledBillBreakdownTitle>
            {tBillBreakdownCardTransmission}
          </StyledBillBreakdownTitle>
          <RhTypography>
            {tBillBreakdownCardThisIsWhatYourLocalUtility}
          </RhTypography>
        </StyledBillBreakdownRow>
        <StyledArithmeticIconContainer>
          <img src={portalMultiplyIcon} alt="" aria-hidden />
        </StyledArithmeticIconContainer>
        <StyledBillBreakdownRow>
          <StyledBillBreakdownImageContainer>
            <img src={portalCalculatorIcon} alt="" aria-hidden />
          </StyledBillBreakdownImageContainer>
          <StyledBillBreakdownTitle>
            {tBillBreakdownCardUsage}
          </StyledBillBreakdownTitle>
          <RhTypography>
            {tBillBreakdownCardThisIsAmountOfElectricity}
          </RhTypography>
        </StyledBillBreakdownRow>
      </StyledBillBreakdownContainer>
      <StyledBillBreakdownDivider />
      <StyledLearnMoreContainer>
        <StyledBillBreakdownTitle>
          {tBillBreakdownCardLearnMoreAbout}
        </StyledBillBreakdownTitle>
        <RhLinkButton
          data-tracking-click={{
            event: "Customer going to Rhythm Marketing FAQs",
          }}
          fullWidth={false}
          href={brandInformation.urlsBlogFaqsHome}
          target="_blank"
        >
          {tBillBreakdownCardLearnMore}
        </RhLinkButton>
      </StyledLearnMoreContainer>
    </StyledBillBreakdownCard>
  );
};
