import { IdType } from "@common/types/apiTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { deviceConnectionActivityTranslations } from "@portal-account/components/DeviceConnectionActivity/DeviceConnectionActivity.en.i18n";
import { StyledLoadingContainer } from "@portal-account/components/DeviceConnectionActivity/DeviceConnectionActivity.styled";
import { DeviceConnectionActivityTable } from "@portal-account/components/DeviceConnectionActivityTable/DeviceConnectionActivityTable";
import { useThermostatConnectionActivityQuery } from "@portal-account/hooks/queries/useThermostatConnectionActivity.query";
import { CustomerHomeSectionInfoMessage } from "@portal-account/pages/CustomerHomePage/CustomerHomeSectionInfoMessage/CustomerHomeSectionInfoMessage";
import { CustomerHomeSectionInfoMessageContainer } from "@portal-account/pages/CustomerHomePage/CustomerHomeSectionInfoMessage/CustomerHomeSectionInfoMessage.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useEffect, useState } from "react";

interface DeviceConnectionActivityProps {
  deviceId: IdType;
  premiseId: IdType;
}

export const DeviceConnectionActivity = (
  props: DeviceConnectionActivityProps
) => {
  const { deviceId, premiseId } = props;
  const { translate } = useTranslations();
  const flash = useRhFlash();

  // Controlled table state
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 6,
  });

  const {
    data: thermostatConnectionActivityData,
    error,
    isPending,
  } = useThermostatConnectionActivityQuery({
    premiseId,
    queryOptions: {
      enabled: Boolean(premiseId && deviceId),
      keepPreviousData: false,
    },
    searchOptions: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      thermostatId: deviceId,
    },
  });

  const { tDeviceConnectionActivityError, tDeviceConnectionActivityNoData } =
    translate(deviceConnectionActivityTranslations);

  useEffect(() => {
    if (error) {
      flash.error(tDeviceConnectionActivityError);
    }
  }, [flash, error, tDeviceConnectionActivityError]);

  if (isPending || !thermostatConnectionActivityData) {
    return (
      <StyledLoadingContainer>
        <RhCircularProgress />
      </StyledLoadingContainer>
    );
  }

  if (!thermostatConnectionActivityData.results.length) {
    return (
      <CustomerHomeSectionInfoMessageContainer>
        <CustomerHomeSectionInfoMessage>
          {tDeviceConnectionActivityNoData}
        </CustomerHomeSectionInfoMessage>
      </CustomerHomeSectionInfoMessageContainer>
    );
  }

  const pageCount = thermostatConnectionActivityData?.count
    ? Math.ceil(thermostatConnectionActivityData.count / pageSize)
    : 0;

  return (
    <DeviceConnectionActivityTable
      connectionActivity={thermostatConnectionActivityData.results}
      pageCount={pageCount}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPagination={setPagination}
    />
  );
};
