import { UUIDType } from "@common/types/apiTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { ProductTierCard } from "@portal-shared/components/ProductTierCard/ProductTierCard";
import { TierCardVariant } from "@portal-shared/components/ProductTierCard/ProductTierCard.enums";
import { groupProductTiersByPresentationGroup } from "@portal-shared/components/ProductTierGrid/groupProductTiersByPresentationGroup";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { ProductTier } from "@portal-shared/models/ProductTier.model";
import { ProductTierPresentationGroupIconType } from "@portal-shared/types/productTypes";
import React, { Fragment, ReactNode, useMemo } from "react";
import styled from "styled-components";

interface ProductTierGridProps {
  onTierSelected: (priceId: UUIDType) => void;
  productTiers: ProductTier[];
  selectedProductPriceIds: UUIDType[];
}

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const StyledTierSection = styled.div`
  align-items: flex-start;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;
  @media (min-width: ${RhythmBreakpoints.XS}px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
`;

const StyledGroupHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const ProductTierGrid = (props: ProductTierGridProps) => {
  const { productTiers, onTierSelected, selectedProductPriceIds } = props;
  const { iconShield, iconWallet } = useBrandImageUrls();
  const sortedGroupsWithTiers = useMemo(() => {
    return groupProductTiersByPresentationGroup(productTiers);
  }, [productTiers]);

  if (productTiers.length === 0) {
    return null;
  }

  const groupIconMapping: Record<
    ProductTierPresentationGroupIconType,
    ReactNode
  > = {
    protection_plans: <img src={iconShield} alt="Shield" />,
    save_money: <img src={iconWallet} alt="Wallet" />,
  };

  return (
    <StyledSection>
      {sortedGroupsWithTiers.map((group) => {
        return (
          <Fragment key={group.presentationGroupId}>
            <StyledGroupHeaderContainer>
              {groupIconMapping[group.icon]}
              <RhTypography variant="h4">{group.name}</RhTypography>
            </StyledGroupHeaderContainer>

            <StyledTierSection>
              {group.productTiers.map((tier) => {
                const priceId = tier.prices[0].id;
                let variant = TierCardVariant.default;

                if (selectedProductPriceIds.includes(priceId)) {
                  variant = TierCardVariant.selected;
                } else if (selectedProductPriceIds.length > 0) {
                  variant = TierCardVariant.disabled;
                }
                return (
                  <ProductTierCard
                    key={`group-${group.name}-tier-${tier.id}`}
                    productTier={tier}
                    onClick={onTierSelected}
                    variant={variant}
                  />
                );
              })}
            </StyledTierSection>
          </Fragment>
        );
      })}
    </StyledSection>
  );
};
