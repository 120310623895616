import { useDetectMultipleSignUpFlowTabs } from "@portal-enroll/hooks/useDetectMultipleSignUpFlowTabs";
import { useFeatureFlagIdentifyCredit } from "@portal-enroll/hooks/useFeatureFlagIdentifyCredit";
import { useFingerPrintIdentify } from "@portal-enroll/hooks/useFingerPrintIdentify";
import { useFingerPrintJS } from "@portal-enroll/hooks/useFingerPrintJS";
import { useSetDjangoLanguageCookie } from "@portal-shared/hooks/useSetDjangoLanguageCookie";

export const EnrollGlobalLogic = () => {
  useFingerPrintIdentify();
  useFeatureFlagIdentifyCredit();
  useFingerPrintJS();
  useDetectMultipleSignUpFlowTabs();
  useSetDjangoLanguageCookie();

  return null;
};
