import { IdType } from "@common/types/apiTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { deviceDemandResponseActivityTranslations } from "@portal-account/components/DeviceDemandResponseActivity/DeviceDemandResponseActivity.en.i18n";
import { StyledLoadingContainer } from "@portal-account/components/DeviceDemandResponseActivity/DeviceDemandResponseActivity.styled";
import { DeviceDemandResponseActivityTable } from "@portal-account/components/DeviceDemandResponseActivityTable/DeviceDemandResponseActivityTable";
import { useThermostatDemandResponseActivityQuery } from "@portal-account/hooks/queries/useThermostatDemandResponseActivity.query";
import { CustomerHomeSectionInfoMessage } from "@portal-account/pages/CustomerHomePage/CustomerHomeSectionInfoMessage/CustomerHomeSectionInfoMessage";
import { CustomerHomeSectionInfoMessageContainer } from "@portal-account/pages/CustomerHomePage/CustomerHomeSectionInfoMessage/CustomerHomeSectionInfoMessage.styled";
import { DeviceDemandResponseActivityEventStatusTypes } from "@portal-account/types/devicesTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useEffect, useState } from "react";

interface DeviceConnectionActivityProps {
  deviceId: IdType;
  premiseId: IdType;
}

export const DeviceDemandResponseActivity = (
  props: DeviceConnectionActivityProps
) => {
  const { deviceId, premiseId } = props;
  const { translate } = useTranslations();
  const flash = useRhFlash();

  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 6,
  });

  const {
    data: thermostatDemandResponseActivityData,
    error,
    isPending,
  } = useThermostatDemandResponseActivityQuery({
    premiseId,
    queryOptions: {
      enabled: Boolean(premiseId && deviceId),
      keepPreviousData: false,
    },
    searchOptions: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      thermostatId: deviceId,
    },
  });

  const {
    tDeviceDemandResponseActivityError,
    tDeviceDemandResponseActivityNoData,
  } = translate(deviceDemandResponseActivityTranslations);

  useEffect(() => {
    if (error) {
      flash.error(tDeviceDemandResponseActivityError);
    }
  }, [flash, error, tDeviceDemandResponseActivityError]);

  if (isPending || !thermostatDemandResponseActivityData) {
    return (
      <StyledLoadingContainer>
        <RhCircularProgress />
      </StyledLoadingContainer>
    );
  }

  if (thermostatDemandResponseActivityData.results.length === 0) {
    return (
      <CustomerHomeSectionInfoMessageContainer>
        <CustomerHomeSectionInfoMessage>
          {tDeviceDemandResponseActivityNoData}
        </CustomerHomeSectionInfoMessage>
      </CustomerHomeSectionInfoMessageContainer>
    );
  }

  const pageCount = thermostatDemandResponseActivityData?.count
    ? Math.ceil(thermostatDemandResponseActivityData.count / pageSize)
    : 0;

  const sortedDemandResponseActivityWithoutDraft =
    thermostatDemandResponseActivityData.results
      .sort((a, b) => {
        return new Date(b.startAt).getTime() - new Date(a.startAt).getTime();
      })
      .filter(
        (activity) =>
          activity.status !== DeviceDemandResponseActivityEventStatusTypes.Draft
      );

  return (
    <DeviceDemandResponseActivityTable
      demandResponseActivity={sortedDemandResponseActivityWithoutDraft}
      pageCount={pageCount}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPagination={setPagination}
    />
  );
};
