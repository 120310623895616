// import { IdType } from "@common/types/apiTypes";
// import { brandInformation } from "@portal-shared/services/brandInformation.service";
// import { Utm, UtmMedium, UtmSource } from "@portal-account/constants/utm.constant";

// export const REFERRAL_CODE_QUERY_PARAM = "referralCode";

import { IdType } from "@common/types/apiTypes";
import {
  Utm,
  UtmMedium,
  UtmSource,
} from "@portal-account/constants/utm.constant";
import { brandInformation } from "@portal-shared/services/brandInformation.service";

export const DevicesEnrollSteps = [
  "choose-your-device-brand",
  "terms-and-conditions",
  "select-devices",
  "summary",
] as const;
export type DevicesEnrollStepType = (typeof DevicesEnrollSteps)[number];

export const signUpReferralUrl = (referralCode: string) => {
  const queryParams = new URLSearchParams({
    referralCode,
    [Utm.SOURCE]: UtmSource.RAF,
    [Utm.MEDIUM]: UtmMedium.MY_ACCOUNT,
  });

  return `${brandInformation.urlsHomePage}/raf?${queryParams.toString()}`;
};

export const billBreakdownBasePath = () => {
  return "/bill-breakdown";
};

export const billBreakdownPath = (invoiceId: IdType) => {
  return `${billBreakdownBasePath()}/${invoiceId}`;
};

export const myAccountPath = () => {
  return "/account";
};

export const productAddOnsOptInPath = () => {
  return "/additional-services";
};

export const payBillPath = () => {
  return "/pay-bill";
};

export const paymentExtensionPath = () => {
  return "/payment-extension";
};

export const rewardsPath = () => {
  return "/rewards";
};

export const notificationsPath = () => {
  return "/notifications";
};

export const accountRenewalsPath = () => {
  return "/renew";
};

export const accountRenewalsSummaryPath = () => {
  return `${accountRenewalsPath()}/summary`;
};

export const payBillSuccessPath = () => {
  return `${payBillPath()}/success`;
};

export const activatePath = (activationToken: string) => {
  return `/welcome/${activationToken}`;
};

export const expiredTokenPath = () => {
  return "/expired-password";
};

export const forgotPasswordPath = () => {
  return "/forgot-password";
};

export const accountCreationPendingPath = (): string => {
  return "/account-creation-pending";
};

export const loginCallbackPath = (): string => {
  return "/implicit/callback";
};

export const devicesBasePath = () => "/devices";

export const devicesEnrollPath = () => `${devicesBasePath()}/enroll`;

export const devicesEnrollStepPath = (step: DevicesEnrollStepType) =>
  `${devicesEnrollPath()}/${step}`;

export const devicesEnrollChooseYourBrandDeviceStep =
  (): DevicesEnrollStepType => "choose-your-device-brand";
export const devicesEnrollTermsStep = (): DevicesEnrollStepType =>
  "terms-and-conditions";
export const devicesEnrollSelectDevicesStep = (): DevicesEnrollStepType =>
  "select-devices";
export const devicesEnrollSummaryStep = (): DevicesEnrollStepType => "summary";
