import { EnvTypes } from "@common/enums/envTypes.enum";
import type { EnvSettings } from "@portal-shared/settings/envSettingsType";

export const energyTexasEnvApplicationSettings: Record<EnvTypes, EnvSettings> =
  {
    local: {
      appBaseUrl: "https://local.energytexas.com:3003",
      axiosBaseUrl: "",
      axiosTimeoutMs: 60000,
      cdnUrl: "https://cdn.staging.energytexas.com",
      faviconPath: "/favicons/energy-texas/favicon-red.ico",
      fingerPrintJsApiKey: "cG4bqea0i3kDWpSdSF9G",
      fingerPrintJsUrl: "https://fp.gotrhythm.com",
      googleTagManagerId: "GTM-5D86PX5W",
      launchDarklyClientId: "600b2fc3a99bda0a5faa967f",
      mapboxToken:
        "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
      marketingEnergyTexasUrl: "https://local.energytexas.com:1338",
      marketingRhythmUrl: "https://local.gotrhythm.com:1337",
      oktaClientId: "0oamxpidyk9CtuGXw1d5",
      oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
      oktaRedirectUri: "https://local.energytexas.com:3003/implicit/callback",
      purecloudOrgId: 10047,
      purecloudOrgName: "entrustenergy",
      purecloudQueueName: "Rhythm Chat/Email TEST",
      rhapsodyBaseUrl: "https://local.gotrhythm.com:3001",
      sentryDSN: "",
      sentryRelease: "",
      sentryTracesSampleRate: 0,
      zuoraAddBankAccountPageId: "8ad086fa92c1c5700192c410ddbb3b19",
      zuoraBaseUrl: "https://apisandbox.zuora.com",
      zuoraCreditCardPaymentPageId: "8ad0869c92c416d40192c43d49360499",
    },
    localStaging: {
      appBaseUrl: "https://app.staging.energytexas.com",
      axiosBaseUrl: "https://api.staging.energytexas.com",
      axiosTimeoutMs: 40000,
      cdnUrl: "https://cdn.staging.energytexas.com",
      faviconPath: "/favicons/energy-texas/favicon-yellow.ico",
      fingerPrintJsApiKey: "cG4bqea0i3kDWpSdSF9G",
      fingerPrintJsUrl: "https://fp.gotrhythm.com",
      googleTagManagerId: "GTM-5D86PX5W",
      launchDarklyClientId: "600b2fd042af4409f41221d8",
      mapboxToken:
        "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
      marketingEnergyTexasUrl: "https://staging.energytexas.com",
      marketingRhythmUrl: "https://staging.gotrhythm.com",
      oktaClientId: "0oamxpidyk9CtuGXw1d5",
      oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
      oktaRedirectUri: "https://local.energytexas.com:3003/implicit/callback",
      purecloudOrgId: 10047,
      purecloudOrgName: "entrustenergy",
      purecloudQueueName: "Rhythm Chat/Email TEST",
      rhapsodyBaseUrl: "https://ops.staging.gotrhythm.com",
      sentryDSN: "",
      sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
      sentryTracesSampleRate: 0.5,
      zuoraAddBankAccountPageId: "8ad086fa92c1c5700192c410ddbb3b19",
      zuoraBaseUrl: "https://apisandbox.zuora.com",
      zuoraCreditCardPaymentPageId: "8ad0869c92c416d40192c43d49360499",
    },
    production: {
      appBaseUrl: "https://app.energytexas.com",
      axiosBaseUrl: "https://api.energytexas.com",
      axiosTimeoutMs: 40000,
      cdnUrl: "https://cdn.energytexas.com",
      faviconPath: "/favicons/energy-texas/favicon.ico",
      fingerPrintJsApiKey: "cG4bqea0i3kDWpSdSF9G",
      fingerPrintJsUrl: "https://fp.gotrhythm.com",
      fullStoryOrgId: "ZPQE0",
      googleTagManagerId: "GTM-5NCKKFC8",
      launchDarklyClientId: "600b2e71df705609ed1d1e0a",
      mapboxToken:
        "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
      marketingEnergyTexasUrl: "https://energytexas.com",
      marketingRhythmUrl: "https://www.gotrhythm.com",
      oktaClientId: "0oa17m8ta3OQSmDmr4x7",
      oktaIssuer: "https://login.gotrhythm.com/oauth2/default",
      oktaRedirectUri: "https://app.gotrhythm.com/implicit/callback",
      purecloudOrgId: 10047,
      purecloudOrgName: "entrustenergy",
      purecloudQueueName: "Rhythm Organic Chat/Email",
      rhapsodyBaseUrl: "https://ops.gotrhythm.com",
      sentryDSN:
        "https://084dd7b410eb4f9c932cbde4a088e85e@o415325.ingest.sentry.io/5306261",
      sentryOrgSlug: "rhythm",
      sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
      sentryTracesSampleRate: 0.2,
      // TODO: Add zuora prod ids
      zuoraAddBankAccountPageId: "8a12959d9521cd070195395500f13117",
      zuoraBaseUrl: "https://zuora.com",
      // TODO: Add zuora prod ids
      zuoraCreditCardPaymentPageId: "8a1281759521b4080195395bee280526",
    },
    staging: {
      appBaseUrl: "https://app.staging.energytexas.com",
      axiosBaseUrl: "https://api.staging.energytexas.com",
      axiosTimeoutMs: 40000,
      cdnUrl: "https://cdn.staging.energytexas.com",
      faviconPath: "/favicons/energy-texas/favicon-yellow.ico",
      fingerPrintJsApiKey: "cG4bqea0i3kDWpSdSF9G",
      fingerPrintJsUrl: "https://fp.gotrhythm.com",
      googleTagManagerId: "GTM-5D86PX5W",
      launchDarklyClientId: "600b2fd042af4409f41221d8",
      mapboxToken:
        "pk.eyJ1IjoibWxjYW1pbGxpIiwiYSI6ImNrZ3FvY2NzczB3bWgyenBneW1nYmNvbzgifQ.zi2pT5hyYYOU9z-6GrPcaQ",
      marketingEnergyTexasUrl: "https://staging.energytexas.com",
      marketingRhythmUrl: "https://staging.gotrhythm.com",
      oktaClientId: "0oaj5ilgwWg4oAcF51d5",
      oktaIssuer: "https://login.staging.gotrhythm.com/oauth2/default",
      oktaRedirectUri: "https://app.staging.energytexas.com/implicit/callback",
      purecloudOrgId: 10047,
      purecloudOrgName: "entrustenergy",
      purecloudQueueName: "Rhythm Chat/Email TEST",
      rhapsodyBaseUrl: "https://ops.staging.gotrhythm.com",
      sentryDSN:
        "https://084dd7b410eb4f9c932cbde4a088e85e@o415325.ingest.sentry.io/5306261",
      sentryRelease: import.meta.env.VITE_SENTRY_RELEASE || "",
      sentryTracesSampleRate: 0.5,
      zuoraAddBankAccountPageId: "8ad086fa92c416dc0192c443a2fe0515",
      zuoraBaseUrl: "https://apisandbox.zuora.com",
      zuoraCreditCardPaymentPageId: "8ad0912492c42b2d0192c4472f5e01f3",
    },
  };
