import { addressStates } from "@common/constants/addressStates.constant";
import {
  DOB_DATE_FORMAT,
  ISO8601_DATE_FORMAT,
} from "@common/constants/date.constant";
import {
  EMAIL_VALIDATION_REGEX,
  SSN_VALIDATION_REGEX,
  ZIPCODE_VALIDATION_REGEX,
} from "@common/constants/regex.constant";
import { unmaskPhone } from "@common/forms/phone.mask";
import { ValidationDictionary, Validator } from "@common/forms/validators";
import { globalIntl } from "@portal-shared/translations/globalIntl";
import { EmailValidator } from "commons-validator-js";
import dayjs from "dayjs";

export const isValidAdultBirthday: Validator<string> = (value) => {
  if (!value) {
    return;
  }
  const dob = dayjs(value, [DOB_DATE_FORMAT, ISO8601_DATE_FORMAT], true);

  if (!dob.isValid()) {
    return globalIntl.messages
      .tValidatorInvalidBirthdayFieldDateFormat as string;
  }

  const today = dayjs().startOf("day");
  const earliest18thBirthday = today.subtract(18, "year");

  if (dob.isAfter(earliest18thBirthday)) {
    return globalIntl.messages.tValidatorInvalidAgeBirthday as string;
  }
};

export const isValidSSN: Validator<string | undefined> = (
  value,
  formValues
) => {
  if (formValues?.runCreditCheckType === "skipCheck") {
    return;
  }

  if (!value || !SSN_VALIDATION_REGEX.test(value)) {
    return globalIntl.messages.tValidatorInvalidSsn as string;
  }
};

export const isNotEqual = <T>(
  value: T,
  comparisonValue: T,
  errorMessage: string
) => {
  if (value === comparisonValue) {
    return errorMessage;
  }
};

export const isEqual = <T>(
  value: T,
  comparisonValue: T,
  errorMessage: string
) => {
  if (value !== comparisonValue) {
    return errorMessage;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRequired: Validator<any> = (
  value,
  _allValues,
  messageId = "tValidatorInfoRequired"
) => {
  if (!value) {
    return globalIntl.messages[messageId] as string;
  }
};

export const isValidUSState: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (
    !addressStates.find(
      ({ abbreviation }) => abbreviation.toLowerCase() === value?.toLowerCase()
    )
  ) {
    return globalIntl.messages.tValidatorInvalidUsState as string;
  }
};

export const isValidZipCode: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (!ZIPCODE_VALIDATION_REGEX.test(value)) {
    return globalIntl.messages.tValidatorInvalidZipCode as string;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidUSAddress: ValidationDictionary<Record<string, any>> = {
  addressLine1: [isRequired],
  city: [isRequired],
  state: [isRequired, isValidUSState],
  zipCode: [isRequired, isValidZipCode],
};

export const isValidEmail: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (!EMAIL_VALIDATION_REGEX.test(value)) {
    return globalIntl.messages.tValidatorInvalidEmail as string;
  }

  const emailValidator = new EmailValidator();

  if (!emailValidator.isValid(value)) {
    return globalIntl.messages.tValidatorInvalidEmail as string;
  }
};

export const isValidPhoneNumber: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (unmaskPhone(value).length !== 10) {
    return globalIntl.messages.tValidatorInvalidPhoneNumber as string;
  }
};
