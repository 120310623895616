import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { billFactorsTranslations } from "@portal-account/components/BillFactors/BillFactors.en.i18n";
import { StyledBillFactor } from "@portal-account/components/BillFactors/BillFactors.styled";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface BillFactorWeatherProps {
  numberOfColdDays: number;
  numberOfHotDays: number;
}

export const BillFactorWeather = ({
  numberOfColdDays,
  numberOfHotDays,
}: BillFactorWeatherProps) => {
  const { translate } = useTranslations();
  const { billFactorsColdWeather, billFactorsHotWeather } = useBrandImageUrls();
  const {
    tBillFactorsWeather,
    tBillFactorsColderDays,
    tBillFactorsHotterDays,
  } = translate(billFactorsTranslations);

  const isColdDays = numberOfColdDays > numberOfHotDays;

  return (
    <StyledBillFactor>
      <img
        src={isColdDays ? billFactorsColdWeather : billFactorsHotWeather}
        alt=""
        aria-hidden
      />
      <RhTypography fontWeight="Bold">{tBillFactorsWeather}</RhTypography>
      <RhTypography variant="body2">
        {isColdDays ? tBillFactorsColderDays : tBillFactorsHotterDays}
      </RhTypography>
    </StyledBillFactor>
  );
};
