import { SessionStorageItem } from "@common/utils/sessionStorage";
import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { LoadingOverlay } from "@portal-account/components/LoadingOverlay/LoadingOverlay";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { useDeviceEligibilityInformationQuery } from "@portal-account/hooks/queries/useDeviceEligibilityInformation.query";
import { usePremiseAttachedDevicesQuery } from "@portal-account/hooks/queries/usePremiseAttachedDevices.query";
import { devicesEnrollStepPath } from "@portal-account/routes/routePaths";
import { usePortalOfferSnapshotQuery } from "@portal-shared/hooks/queries/usePortalOfferSnapshot.query";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

export const CustomerHomeOutlet = () => {
  const { premise } = usePremiseFromContext();
  const customer = useCustomerFromContext();

  const [visitedThermostatEnroll] = useSessionStorage(
    SessionStorageItem.VisitedThermostatEnrollmentPage,
    false
  );

  const {
    data: premiseAttachedDevicesData,
    isPending: premiseAttachedDevicesDataPending,
  } = usePremiseAttachedDevicesQuery({
    premiseId: premise.id,
  });

  const {
    data: currentOrderOfferSnapshot,
    isPending: currentOrderOfferSnapshotPending,
  } = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: premise.currentOrder?.offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offerSnapshotUuid),
    },
  });

  const {
    data: deviceEligibilityInformationData,
    isPending: deviceEligibilityInformationDataPending,
  } = useDeviceEligibilityInformationQuery({
    customerId: customer.id,
  });

  if (
    premiseAttachedDevicesDataPending ||
    currentOrderOfferSnapshotPending ||
    deviceEligibilityInformationDataPending
  ) {
    return <LoadingOverlay />;
  }

  if (!deviceEligibilityInformationData?.thermostatEligible) {
    return <Outlet />;
  }

  if (visitedThermostatEnroll) {
    return <Outlet />;
  }

  const isThermostatOffer = currentOrderOfferSnapshot?.isThermostatOffer;
  const noAttachedDevices = !premiseAttachedDevicesData?.thermostats?.length;

  if (isThermostatOffer && noAttachedDevices) {
    return <Navigate to={devicesEnrollStepPath("choose-your-device-brand")} />;
  }

  return <Outlet />;
};
