export enum AppBrands {
  ENERGY_TEXAS = "energy-texas",
  RHYTHM = "rhythm",
}

export const AppBrandNameMap = {
  [AppBrands.ENERGY_TEXAS]: "Energy Texas",
  [AppBrands.RHYTHM]: "Rhythm",
};

export enum CamelCaseAppBrands {
  ENERGY_TEXAS = "energyTexas",
  RHYTHM = "rhythm",
}

export const camelCaseAppBrandNameMap = {
  [AppBrands.ENERGY_TEXAS]: CamelCaseAppBrands.ENERGY_TEXAS,
  [AppBrands.RHYTHM]: CamelCaseAppBrands.RHYTHM,
};
