import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { chooseYourDeviceBrandStepAtom } from "@portal-account/components/DevicesEnrollChooseBrandForm/DevicesEnrollChooseBrandForm.atoms";
import {
  StyledBackLink,
  StyledButtonContainer,
} from "@portal-account/components/DevicesEnrollPageLayout/DevicesEnrollPageLayout.styled";
import { devicesEnrollTermsFormTranslations } from "@portal-account/components/DevicesEnrollTermsForm/DevicesEnrollTermsForm.en.i18n";
import {
  StyledCheckbox,
  StyledContainer,
  StyledLabel,
  StyledLegalDocIcon,
  StyledLegalDocLinkContainer,
  StyledYourDocumentsContainer,
} from "@portal-account/components/DevicesEnrollTermsForm/DevicesEnrollTermsForm.styled";
import { useGetResideoOauthUrlQuery } from "@portal-account/hooks/queries/useGetResideoOauthUrl.query";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { customerQueryKeys } from "@portal-account/queryKeys/customerQuerykeys";
import { devicesEnrollStepPath } from "@portal-account/routes/routePaths";
import {
  ActionType,
  DeviceEnrollmentEvents,
} from "@portal-account/services/segment.service";
import { Skeleton } from "@portal-shared/components/Skeleton/Skeleton";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { getBrandInformation } from "@portal-shared/services/brandInformation.service";
import { getPortalEnvApplicationSettings } from "@portal-shared/settings/getPortalEnvApplicationSettings";
import { useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import React, { useCallback, useEffect, useState } from "react";

export const DevicesEnrollTermsForm = () => {
  const [locale] = useLocaleAtom();
  const brandInformation = getBrandInformation();
  const queryClient = useQueryClient();
  const deviceBrand = useAtomValue(chooseYourDeviceBrandStepAtom);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const flash = useRhFlash();
  const track = useTrackMyAccountEvents();
  const { translate } = useTranslations();
  const {
    tDevicesEnrollTermsDocumentLinkText,
    tDevicesEnrollTermsFormBack,
    tDevicesEnrollTermsFormCopy,
    tDevicesEnrollTermsError,
    tDevicesEnrollTermsFormNext,
    tDevicesEnrollTermsFormYourDocuments,
  } = translate(devicesEnrollTermsFormTranslations);
  const {
    data: resideoAuthUrlData,
    error,
    isPending,
  } = useGetResideoOauthUrlQuery({
    deviceBrand,
    queryOptions: {
      enabled: Boolean(deviceBrand),
    },
  });

  const handleNextTrackEvent = () =>
    track({
      action: ActionType.click,
      category: "devices.enroll.terms-and-conditions",
      event: DeviceEnrollmentEvents.DeviceTermsAgreed,
    });

  const handleBackClick = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: customerQueryKeys.getResideoOauthUrl(deviceBrand),
    });
  }, [deviceBrand, queryClient]);

  useEffect(() => {
    if (error) {
      flash.error(tDevicesEnrollTermsError);
    }
  }, [error, flash, tDevicesEnrollTermsError]);

  if (isPending) {
    return <Skeleton $height="30em" />;
  }

  return (
    <StyledContainer>
      <StyledLabel>
        <StyledCheckbox
          type="checkbox"
          onClick={() => setAgreedToTerms((prev) => !prev)}
        />
        <RhTypography variant="body1">
          {tDevicesEnrollTermsFormCopy}
        </RhTypography>
      </StyledLabel>
      <StyledYourDocumentsContainer>
        <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
          {tDevicesEnrollTermsFormYourDocuments}
        </RhTypography>
        <StyledLegalDocLinkContainer>
          <StyledLegalDocIcon />
          <RhAnchor
            href={
              locale === "en"
                ? `${getPortalEnvApplicationSettings().cdnUrl}/${brandInformation.deviceEnTos}`
                : `${getPortalEnvApplicationSettings().cdnUrl}/${brandInformation.deviceEsTos}`
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            {tDevicesEnrollTermsDocumentLinkText}
          </RhAnchor>
        </StyledLegalDocLinkContainer>
      </StyledYourDocumentsContainer>
      <StyledButtonContainer>
        <RhLinkButton
          data-tracking-click={{
            event: "Customer going to thermostat auth flow",
          }}
          disabled={!agreedToTerms || !resideoAuthUrlData?.redirectUrl}
          fullWidth
          href={resideoAuthUrlData?.redirectUrl ?? ""}
          label={tDevicesEnrollTermsFormNext}
          onClick={handleNextTrackEvent}
          size="medium"
        >
          {tDevicesEnrollTermsFormNext}
        </RhLinkButton>
        <StyledBackLink
          to={devicesEnrollStepPath("choose-your-device-brand")}
          onClick={handleBackClick}
        >
          {tDevicesEnrollTermsFormBack}
        </StyledBackLink>
      </StyledButtonContainer>
    </StyledContainer>
  );
};
