import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rhSpacingPx(4)};
  width: 100%;
`;

export const StyledTitle = styled(RhTypography)`
  &.MuiTypography-root {
    margin: auto auto ${rhSpacingPx(1.5)};
    max-width: 350px;
    text-align: center;
  }
`;

export const StyledMessage = styled(RhTypography)`
  &.MuiTypography-root {
    margin: auto;
    max-width: 400px;
    text-align: center;
  }
`;

export const StyledWarningImage = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${rhSpacingPx(1)};

  img {
    height: auto;
    max-width: 100%;
  }
`;
