import {
  CreditCheckResponseV2,
  CreditCheckStatus,
} from "@enroll-utils/types/creditCheckTypes";

export enum CreditEvaluationDefinition {
  Approved = "Approved",
  ApprovedWithRequirements = "Approved - With Requirements",
  NotApprovedManuelReviewRequired = "Not Approved - Manual Review required",
  unavailable = "Unavailable",
}

export const formatCreditCheckForTracking = (
  creditCheckResponse: CreditCheckResponseV2
) => {
  if (creditCheckResponse.status === CreditCheckStatus.APPROVED) {
    return {
      depositAlternativeAmount: 0,
      depositAmount: 0,
      nosRequired: false,
      ssnRequired: false,
      value: CreditEvaluationDefinition.Approved,
    };
  }

  if (creditCheckResponse.status === CreditCheckStatus.MANUAL_REVIEW) {
    return {
      depositAlternativeAmount: 0,
      depositAmount: 0,
      nosRequired: false,
      ssnRequired: false,
      value: CreditEvaluationDefinition.NotApprovedManuelReviewRequired,
    };
  }

  if (
    creditCheckResponse.status === CreditCheckStatus.APPROVED_WITH_REQUIREMENTS
  ) {
    const depositReq = creditCheckResponse.requirements.find(
      (req) => req.requirementType === "DEPOSIT"
    );

    return {
      depositAlternativeAmount:
        depositReq?.details.depositAlternativeAmount || 0,
      depositAmount: depositReq?.details.depositAmount || 0,
      identityCheck: creditCheckResponse.requirements.some(
        (req) => req.requirementType === "IDENTITY_CHECK"
      ),
      ssnRequired: creditCheckResponse.requirements.some(
        (req) => req.requirementType === "SSN"
      ),
      value: CreditEvaluationDefinition.ApprovedWithRequirements,
    };
  }

  return { value: CreditEvaluationDefinition.unavailable };
};
