import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  OFFER_CONTAINER_WIDTH_3CARD,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import styled from "styled-components";

export const PageContainer = styled.div<{ $noPadding?: boolean }>`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1440px;
  padding-top: ${({ $noPadding }) => ($noPadding ? 0 : "60px")};
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    padding-top: 0;
  }
`;

export const ZipCodeAndTitleHeader = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: center;
  margin-bottom: ${rhSpacingPx(5)};
  padding-top: ${rhSpacingPx(4)};
  text-align: center;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    gap: 2rem;
  }
`;

export const ReasonsToBelieveContainer = styled.div`
  max-width: ${OFFER_CONTAINER_WIDTH_3CARD}px;
  padding: 2rem 0.5rem;
  position: relative;
  scroll-margin-top: 50px;
  width: 100%;
  z-index: 2;

  @media (min-width: ${RhythmBreakpoints.XS}px) {
    padding: 2rem;
  }
`;
