import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { zuora } from "@common/utils/zuora.util";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { loggedOutZuoraAddBankAccountFormTranslations } from "@portal-shared/components/LoggedOutZuoraAddBankAccountForm/LoggedOutZuoraAddBankAccountForm.en.i18n";
import { LoggedOutZuoraAddBankAccountFormError } from "@portal-shared/components/LoggedOutZuoraAddBankAccountForm/LoggedOutZuoraAddBankAccountFormError.service";
import { PaymentMethodOptionsType } from "@portal-shared/components/PaymentMethodOptions/PaymentMethodOptions";
import { useFetchRSASignatureQuery } from "@portal-shared/hooks/queries/useFetchRSASignature.query";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { getZuoraParams } from "@portal-shared/utils/baseZuoraParams.util";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";

interface LoggedOutZuoraAddBankAccountFormProps {
  onFailure?(error: string): void;
  onSuccess(response: ZuoraAddPaymentResponseType): void;
  paymentOptions?: PaymentMethodOptionsType;
}

export const LoggedOutZuoraAddBankAccountForm = ({
  onFailure,
  onSuccess,
  paymentOptions = {
    isOneTimePayment: false,
    setDefaultPaymentMethod: false,
  },
}: LoggedOutZuoraAddBankAccountFormProps) => {
  const [isZuoraFormLoading, setIsZuoraFormLoading] = useState(true);
  const { translate } = useTranslations();
  const translations = translate(loggedOutZuoraAddBankAccountFormTranslations);
  const {
    tLoggedOutZuoraAddBankAccountFormAuthorizationFailed,
    tLoggedOutZuoraAddBankAccountFormErrorAddingBankAccount,
  } = translations;
  const flash = useRhFlash();
  const zuoraParams = getZuoraParams(dayjs().locale(), true);
  const fetchRSASignatureQuery = useFetchRSASignatureQuery(zuoraParams.id);

  const debounceErrorMessageCallback = debounce(
    (errorType: string, errorCode: string, errorDescription: string) => {
      const zuoraAddBankAccountErrorService =
        new LoggedOutZuoraAddBankAccountFormError(
          errorType,
          errorCode,
          errorDescription
        );

      const { error } = zuoraAddBankAccountErrorService;
      const errorMessage =
        translations[`tLoggedOutZuoraAddBankAccountFormFormError.${error}`];

      flash.error(errorMessage);
    },
    250
  );

  const handleZuoraResponse = (response: ZuoraAddPaymentResponseType) => {
    if (!response.success) {
      onFailure?.(tLoggedOutZuoraAddBankAccountFormErrorAddingBankAccount);

      return Promise.resolve();
    }

    onSuccess(response);

    return Promise.resolve();
  };

  useEffect(() => {
    if (fetchRSASignatureQuery.isPending) {
      return;
    }

    zuora.setEventHandler("onloadCallback", () => {
      setIsZuoraFormLoading(false);
    });

    zuora.renderWithErrorHandler(
      {
        ...zuoraParams,
        ...fetchRSASignatureQuery.data,
      },
      {},
      handleZuoraResponse,
      debounceErrorMessageCallback
    );
  }, [fetchRSASignatureQuery.isPending]);

  if (fetchRSASignatureQuery.isError) {
    onFailure?.(tLoggedOutZuoraAddBankAccountFormAuthorizationFailed);
    return null;
  }

  return (
    <>
      {isZuoraFormLoading && <RhCircularProgress marginBottom={4} />}
      <div id="zuora_payment" />
    </>
  );
};
