export const enrollContactFormTranslations = {
  tEnrollContactFormEmail: "Email",
  tEnrollContactFormEmailConfirm: "Confirm Email",
  tEnrollContactFormEmailsMustMatch: "Emails must match.",
  tEnrollContactFormErrorInvalidUsPhoneNumber:
    "Please enter a valid US phone number.",
  tEnrollContactFormErrorUpdatingProspect: "We hit a glitch. Please try again",
  tEnrollContactFormInvalidConfirmEmail: "Invalid Confirm Email.",
  tEnrollContactFormInvalidEmail: "Invalid Email.",
  tEnrollContactFormNotificationsSignUp:
    "Please sign me up for notifications, updates and offers from Rhythm",
  tEnrollContactFormPhone: "Phone",
  tEnrollContactFormSendContactInfoCta: "Next",
  tEnrollContactFormSendingContactInfoCta: "Next",
};
