import { prospectApi } from "@enroll-data/api/prospectApi";
import { CreditCheckRequestV2 } from "@enroll-utils/types/creditCheckTypes";
import { useMutation } from "@tanstack/react-query";

export const useCreateCreditCheckMutation = () => {
  const mutation = useMutation({
    mutationFn: (body: CreditCheckRequestV2) => {
      return prospectApi.createCreditCheck(body);
    },
  });

  return mutation;
};
