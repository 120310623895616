import { ISO8601_DATE_FORMAT_REGEX } from "@common/constants/date.constant";
import { EnrollmentType, MOVE_IN, SWITCH } from "@common/types/customerTypes";
import { InferType, array, boolean, mixed, object, string } from "yup";

export const customerData = object({
  csrSignUp: boolean().required(),
  dateOfBirth: string().matches(ISO8601_DATE_FORMAT_REGEX).required(),
  email: string().email().required(),
  enrollmentType: mixed<EnrollmentType>().oneOf([MOVE_IN, SWITCH]).required(),
  esiId: string().nullable().defined(),
  featureFlagUserId: string().nullable(),
  firstName: string().required(),
  languagePreference: string().matches(/en|es/).required(),
  lastName: string().required(),
  offersnapshotId: string().required(),
  phone: string().required(),
  prospectId: string().nullable().defined(),
  referralFromFriendCode: string().nullable().defined(),
  // sendMarketingPromos deprecated. Create customer now is taking this directly from the prospect in the backend,
  sendMarketingPromos: boolean().required(),
  serviceAddress: object({
    addressLine1: string().required(),
    city: string().required(),
    state: string().required(),
    unitNumber: string().defined(),
    zipCode: string().required(),
  }),
  serviceStartDate: string().matches(ISO8601_DATE_FORMAT_REGEX).required(),
});

export type CreateCustomerDataRequest = InferType<typeof customerData>;

export const createCustomerRequestSchema = object({
  customerData,
  requestOrigin: string().optional(),
});

export type CreateCustomerRequest = InferType<
  typeof createCustomerRequestSchema
>;

export const createCustomerWithProductsRequestSchema = object({
  customerData: customerData.clone().shape({
    products: array().of(string().required()).nullable(),
  }),
});

export type CreateCustomerWithProductsRequest = InferType<
  typeof createCustomerWithProductsRequestSchema
>;
