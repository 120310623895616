import { Order } from "@common/models/Order.model";
import { CommunicationPreferenceType } from "@common/types/premiseTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { AutoPayFinePrint } from "@portal-guest/components/OneClickRenewalSummaryForm/AutoPayFinePrint";
import { oneClickRenewalSummaryFormTranslations } from "@portal-guest/components/OneClickRenewalSummaryForm/OneClickRenewalSummaryForm.en.i18n";
import {
  OneClickBillingPreferencesContainer,
  OneClickFinePrintContainer,
  OneClickPlanDetailRow,
  OneClickPlanDetailRowLabel,
  OneClickPlanDetailRowValue,
  OneClickRenewalSummaryFormContainer,
  OneClickRenewalSummaryFormPortalToggle,
  OneClickTermsCheckboxLabel,
  OneClickTermsContainer,
} from "@portal-guest/components/OneClickRenewalSummaryForm/OneClickRenewalSummaryForm.styled";
import { useOneClickRenewContractMutation } from "@portal-guest/hooks/mutations/useOneClickRenewContract.mutation";
import { useOneClickRenewalDefaultPaymentMethodQuery } from "@portal-guest/hooks/queries/useOneClickRenewalDefaultPaymentMethod.query";
import {
  ActionType,
  CategoryType,
  LabelType,
  RenewalEvents,
  track,
} from "@portal-guest/services/segment.service";
import { EFLLink } from "@portal-shared/components/EFLLink/EFLLink";
import { PortalCheckbox } from "@portal-shared/components/PortalCheckbox/PortalCheckbox";
import { TOSLink } from "@portal-shared/components/TOSLink/TOSLink";
import { YRACLink } from "@portal-shared/components/YRACLink/YRACLink";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { signInPath } from "@portal-shared/routes/routePaths";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

interface OneClickRenewalSummaryFormProps {
  autopay: boolean;
  communicationPreference: CommunicationPreferenceType;
  offerSnapshot: OfferSnapshot;
  renewalToken: string;
  setFutureOrder: Dispatch<SetStateAction<Order | null>>;
}

interface OneClickRenewalSummaryFormValues {
  autopay: boolean;
  paperless: boolean;
  termsAccepted: boolean;
}

export const OneClickRenewalSummaryForm = (
  props: OneClickRenewalSummaryFormProps
) => {
  const {
    communicationPreference,
    offerSnapshot,
    setFutureOrder,
    renewalToken,
    autopay,
  } = props;

  const { iconAutopay, iconPaperless } = useBrandImageUrls();

  const [locale] = useLocaleAtom();

  const flash = useRhFlash();

  const { watch, register, handleSubmit, resetField } =
    useForm<OneClickRenewalSummaryFormValues>({
      defaultValues: {
        autopay: offerSnapshot.isAutoPayPaperlessDiscountOffer ? true : autopay,
        paperless: offerSnapshot.isAutoPayPaperlessDiscountOffer
          ? true
          : communicationPreference === "PAPERLESS" ||
            communicationPreference === "EBILL",
        termsAccepted: false,
      },
    });

  const { translate, translateJsx } = useTranslations();

  const renewalTokenDefaultPaymentMethodQuery =
    useOneClickRenewalDefaultPaymentMethodQuery({
      renewalToken,
    });

  const renewContractMutation = useOneClickRenewContractMutation();

  useEffect(() => {
    if (
      renewalTokenDefaultPaymentMethodQuery.isSuccess &&
      !renewalTokenDefaultPaymentMethodQuery.data.paymentMethod
    ) {
      resetField("autopay", {
        defaultValue: false,
      });
    }

    if (renewalTokenDefaultPaymentMethodQuery.isError) {
      resetField("autopay", {
        defaultValue: false,
      });
    }
  }, [
    renewalTokenDefaultPaymentMethodQuery.data?.paymentMethod,
    renewalTokenDefaultPaymentMethodQuery.isSuccess,
    resetField,
    renewalTokenDefaultPaymentMethodQuery.isError,
  ]);

  const {
    tOneClickRenewalSummaryFormSignup,
    tOneClickRenewalSummaryFormSubmitting,
    tOneClickRenewalSummaryFormBillingPreferences,
    tOneClickRenewalSummaryFormPaperless,
    tOneClickRenewalSummaryFormPaperlessDescription,
    tOneClickRenewalSummaryFormPaperlessLegalText,
    tOneClickRenewalSummaryFormError,
    tOneClickRenewalSummaryFormConfirmPaperlessDisabled,
    tOneClickRenewalSummaryFormAutoPayLabel,
  } = translate(oneClickRenewalSummaryFormTranslations);

  const trackingLocation = "One Click Renewal Summary";

  const {
    tOneClickRenewalSummaryFormTerms,
    tOneClickRenewalSummaryFormNoDiscountMessage,
  } = translateJsx({
    tOneClickRenewalSummaryFormNoDiscountMessage: {
      discountAmount: formatCurrency(
        offerSnapshot.autopayPaperlessMonthlyDiscount ?? "",
        2
      ),
    },
    tOneClickRenewalSummaryFormNoPaymentMethod: {
      link: (chunk: string) => <Link to={signInPath()}>{chunk}</Link>,
    },
    tOneClickRenewalSummaryFormTerms: {
      efl: (chunk: string) => (
        <EFLLink
          offerId={offerSnapshot.id}
          locale={locale}
          dataTrackingClick={{ location: trackingLocation }}
        >
          {chunk}
        </EFLLink>
      ),
      tos: (chunk: string) => (
        <TOSLink
          tosUrl={offerSnapshot.termsOfServiceLink(locale)}
          dataTrackingClick={{ location: trackingLocation }}
        >
          {chunk}
        </TOSLink>
      ),
      yrac: (chunk: string) => (
        <YRACLink
          yracUrl={offerSnapshot.yracLink(locale)}
          dataTrackingClick={{ location: trackingLocation }}
        >
          {chunk}
        </YRACLink>
      ),
    },
  });

  const [termsAccepted, selectedPaperless, selectedAutoPay] = watch([
    "termsAccepted",
    "paperless",
    "autopay",
  ]);

  const showPaperlessDisabledWarning =
    offerSnapshot.autopayPaperlessMonthlyDiscount && !selectedPaperless;

  const autoPayDisabled =
    (renewalTokenDefaultPaymentMethodQuery.isSuccess &&
      !renewalTokenDefaultPaymentMethodQuery.data.paymentMethod) ||
    renewalTokenDefaultPaymentMethodQuery.isError;

  const handleRenewContract = (values: OneClickRenewalSummaryFormValues) => {
    renewContractMutation.mutate(
      {
        autopay: values.autopay,
        paperless: values.paperless,
        renewalOfferSnapshotId: offerSnapshot.id,
        renewalToken,
      },
      {
        onError: () => {
          flash.error(tOneClickRenewalSummaryFormError);
        },
        onSuccess: (data) => {
          track({
            action: ActionType.click,
            category: CategoryType.OneClickRenewals,
            event: RenewalEvents.renewalClick,
            label: LabelType.ClickedRenewalSubmit,
            orderId: data.order?.id,
            renewalToken,
          });

          if (data.futureOrder) {
            setFutureOrder(new Order(data.futureOrder));
          }
        },
      }
    );
  };

  return (
    <OneClickRenewalSummaryFormContainer
      onSubmit={handleSubmit(handleRenewContract)}
    >
      <OneClickBillingPreferencesContainer>
        <OneClickPlanDetailRow>
          <OneClickPlanDetailRowLabel>
            <RhTypography color="textSecondary">
              {tOneClickRenewalSummaryFormBillingPreferences}
            </RhTypography>
          </OneClickPlanDetailRowLabel>
          <OneClickPlanDetailRowValue>
            <OneClickRenewalSummaryFormPortalToggle
              dataTrackingClick={{
                event: "Customer toggling paperless",
                value: String(selectedPaperless),
              }}
              inputProps={register("paperless")}
            >
              <img src={iconPaperless} alt="" aria-hidden />
              <RhTypography variant="body1" fontWeight="Bold" component="span">
                {tOneClickRenewalSummaryFormPaperless}
              </RhTypography>
            </OneClickRenewalSummaryFormPortalToggle>

            <OneClickFinePrintContainer>
              {selectedPaperless ? (
                <RhTypography variant="body2" color="textSecondary">
                  {tOneClickRenewalSummaryFormPaperlessLegalText}
                </RhTypography>
              ) : (
                <RhTypography variant="body2" color="textSecondary">
                  {tOneClickRenewalSummaryFormPaperlessDescription}
                </RhTypography>
              )}

              {showPaperlessDisabledWarning ? (
                <>
                  <RhTypography variant="body2" color="error">
                    {tOneClickRenewalSummaryFormConfirmPaperlessDisabled}
                  </RhTypography>

                  <RhTypography variant="body2" color="error">
                    {tOneClickRenewalSummaryFormNoDiscountMessage}
                  </RhTypography>
                </>
              ) : null}
            </OneClickFinePrintContainer>
          </OneClickPlanDetailRowValue>
        </OneClickPlanDetailRow>

        <OneClickPlanDetailRow>
          <OneClickPlanDetailRowLabel />
          <OneClickPlanDetailRowValue>
            <OneClickRenewalSummaryFormPortalToggle
              dataTrackingClick={{
                event: "Customer toggling autopay",
                value: String(selectedAutoPay),
              }}
              inputProps={{
                ...register("autopay", {
                  disabled: autoPayDisabled,
                }),
              }}
            >
              <img src={iconAutopay} alt="" aria-hidden />
              <RhTypography variant="body1" fontWeight="Bold" component="span">
                {tOneClickRenewalSummaryFormAutoPayLabel}
              </RhTypography>
            </OneClickRenewalSummaryFormPortalToggle>

            <OneClickFinePrintContainer>
              {renewalTokenDefaultPaymentMethodQuery.isPending ? (
                <RhCircularProgress />
              ) : (
                <AutoPayFinePrint
                  offerSnapshot={offerSnapshot}
                  selectedAutoPay={selectedAutoPay}
                  paymentMethod={
                    renewalTokenDefaultPaymentMethodQuery.data?.paymentMethod
                  }
                  isPastDue={
                    renewalTokenDefaultPaymentMethodQuery.data?.isPastDue
                  }
                />
              )}
            </OneClickFinePrintContainer>
          </OneClickPlanDetailRowValue>
        </OneClickPlanDetailRow>
      </OneClickBillingPreferencesContainer>

      <OneClickTermsContainer>
        <OneClickTermsCheckboxLabel>
          <PortalCheckbox {...register("termsAccepted")} />
          <RhTypography>{tOneClickRenewalSummaryFormTerms}</RhTypography>
        </OneClickTermsCheckboxLabel>
      </OneClickTermsContainer>
      <RhButton
        color="primary"
        data-tracking-click={{ event: "Customer attempting to renew" }}
        disabled={!termsAccepted || renewContractMutation.isPending}
        type="submit"
      >
        {!renewContractMutation.isPending
          ? tOneClickRenewalSummaryFormSignup
          : tOneClickRenewalSummaryFormSubmitting}
      </RhButton>
    </OneClickRenewalSummaryFormContainer>
  );
};
