export enum CreditCheckStatus {
  APPROVED = "APPROVED",
  APPROVED_WITH_REQUIREMENTS = "APPROVED_WITH_REQUIREMENTS",
  MANUAL_REVIEW = "MANUAL_REVIEW",
}

type CreditCheckRequirements =
  | {
      details: {
        alloyJourneyApplicationToken: string;
        alloyJourneyToken: string;
        alloySdkKey: string;
      };
      requirementType: "IDENTITY_CHECK";
    }
  | {
      details: {
        depositAlternativeAmount: string | null;
        depositAmount: number;
      };
      requirementType: "DEPOSIT";
    }
  | { requirementType: "SSN" };

export type CreditCheckResponseV2 = {
  requirements: Array<CreditCheckRequirements>;
  status: CreditCheckStatus;
};

export type CreditCheckRequestV2 = {
  ssn?: string;
};
