import { DeviceBrands } from "@portal-account/types/devicesTypes";
import { ResideoOauthType } from "@portal-account/types/resideoOauthTypes";

const CUSTOMER_BASE_URL = "/api/portal/customers";

export const customerUrls = {
  attachResideoDevices: () => `${CUSTOMER_BASE_URL}/attach-resideo-devices`,
  deviceEligibilityInformation: () =>
    `${CUSTOMER_BASE_URL}/device-eligibility-information`,
  getResideoOauthUrl: (deviceBrand: DeviceBrands | null) =>
    `${CUSTOMER_BASE_URL}/get-resideo-oauth-url?device_brand=${deviceBrand}`,
  listResideoDevices: ({ deviceBrand, oauthCode }: ResideoOauthType) =>
    `${CUSTOMER_BASE_URL}/list-resideo-devices?oauth_code=${oauthCode}&device_brand=${deviceBrand}`,
  me: () => `${CUSTOMER_BASE_URL}/me`,
  trackCustomerLogin: () => `${CUSTOMER_BASE_URL}/track-customer-login/`,
  updateContactInfo: () => `${CUSTOMER_BASE_URL}/update-contact-info/`,
};
