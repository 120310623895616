import { IdType } from "@common/types/apiTypes";

const BASE_PATH = "/api/customers";

export const notificationChannelsList = (id: IdType) =>
  `${BASE_PATH}/${id}/notification_subscription_channels/`;
export const notificationMessagesList = (id: IdType) =>
  `${BASE_PATH}/${id}/notification_subscription_types/`;
export const contactLogs = (id: IdType) => `${BASE_PATH}/${id}/contact-logs/`;
export const eventLogs = (id: IdType) => `${BASE_PATH}/${id}/event-logs/`;
