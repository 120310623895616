import { AppBrands } from "@common/enums/appBrands.enum";
import { getAppBrand } from "@common/services/getEnvApplicationSettings.service";
import { getPortalEnvApplicationSettings } from "@portal-shared/settings/getPortalEnvApplicationSettings";

const portalEnvApplicationSettings = getPortalEnvApplicationSettings();

const MARKETING_RHYTHM_URL = portalEnvApplicationSettings.marketingRhythmUrl;
const MARKETING_ENERGY_TEXAS_URL =
  portalEnvApplicationSettings.marketingEnergyTexasUrl;

const rhythmInformation = {
  addressesPoBoxLine1: "PO BOX 735903",
  addressesPoBoxLine2: "Dallas, TX 75373",
  brandLLC: "Rhythm Ops, LLC",
  brandName: "Rhythm",
  cookieDomain: ".gotrhythm.com",
  deviceEnTos: "Rhythm_Device_Management_Program_Terms_en.pdf",
  deviceEsTos: "Rhythm_Device_Management_Program_Terms_es.pdf",
  emailsSupport: "support@gotrhythm.com",
  enrollmentEmailSupport: "enrollments@gotrhythm.com",
  phoneNumbersGeneralSales: "1-888-408-2836",
  phoneNumbersOpsBadDebt: "1-888-512-0283",
  phoneNumbersOpsDisplay: "1-888-7RHYTHM",
  phoneNumbersOpsNumber: "+18887749846",
  socialMediaFacebook: "https://www.facebook.com/rhythmenergy/",
  socialMediaInstagram: "https://www.instagram.com/rhythmenergy/",
  socialMediaLinkedIn: "https://www.linkedin.com/company/rhythmhq/",
  socialMediaTwitter: "https://twitter.com/GotRhythmHQ/",
  urlsBlogConnectDevice: `${MARKETING_RHYTHM_URL}/blog/energy-saving-tips/how-to-connect-your-smart-thermostat`,
  urlsBlogFaqsHome: `${MARKETING_RHYTHM_URL}/faq`,
  urlsBlogPostsEnergySavingTips: `${MARKETING_RHYTHM_URL}/blog/how-to-save-on-energy-in-texas-cut-your-bill-today`,
  urlsComparePower: ({
    trackingId,
    zipCode,
  }: {
    trackingId: string;
    zipCode: string;
  }) => `https://go.comparepower.com/rhythm/?zip_code=${zipCode}`,
  urlsFaqsEtfReimbursement: `${MARKETING_RHYTHM_URL}/faq#accordion-will-rhythm-reimburse-my-current-supplier-s-etf`,
  urlsFaqsHome: `${MARKETING_RHYTHM_URL}/faq`,
  urlsFaqsOutages: `${MARKETING_RHYTHM_URL}/contact-us#accordion-how-can-i-report-or-get-information-on-a-power-outage`,
  urlsFaqsReferAFriend: `${MARKETING_RHYTHM_URL}/faq#accordion-what-is-the-refer-a-friend-program`,
  urlsFaqsRewards: `${MARKETING_RHYTHM_URL}/faq#accordion-what-is-the-rhythm-rewards-program`,
  urlsFaqsSatisfyDeposit: `${MARKETING_RHYTHM_URL}/faq#accordion-how-can-i-satisfy-a-deposit`,
  urlsFaqsSolarBuyBack: `${MARKETING_RHYTHM_URL}/solar-buyback-plan-faq`,
  urlsHomePage: MARKETING_RHYTHM_URL,
  urlsPlansSolar: `${MARKETING_RHYTHM_URL}/solar`,
  urlsPrivacyPolicy: `${MARKETING_RHYTHM_URL}/privacy-notice`,
  urlsReferAFriend: `${MARKETING_RHYTHM_URL}/raf`,
  urlsTermsOfUse: `${MARKETING_RHYTHM_URL}/terms-of-use`,
};

const energyTexasInformation = {
  addressesPoBoxLine1: "PO BOX 735903",
  addressesPoBoxLine2: "Dallas, TX 75373",
  brandLLC: "Rhythm Ops, LLC",
  brandName: "Energy Texas",
  cookieDomain: ".energytexas.com",
  deviceEnTos: "Energy_Texas_Device_Management_Program_Terms_en.pdf",
  deviceEsTos: "Energy_Texas_Device_Management_Program_Terms_es.pdf",
  emailsSupport: "support@energytexas.com",
  enrollmentEmailSupport: "enrollments@energytexas.com",
  phoneNumbersGeneralSales: "1-800-837-4099",
  phoneNumbersOpsBadDebt: "1-888-441-0352",
  phoneNumbersOpsDisplay: "1-800-837-4099",
  phoneNumbersOpsNumber: "1-888-830-0871",
  socialMediaFacebook: "https://www.facebook.com/EnergyTexasCo/",
  socialMediaInstagram: "https://www.instagram.com/energytexasco/",
  socialMediaLinkedIn: "https://www.linkedin.com/company/energytexasco/",
  socialMediaTwitter: "https://x.com/EnergyTexasCo",
  urlsBlogConnectDevice: `${MARKETING_ENERGY_TEXAS_URL}/blog/energy-saving-tips/how-to-connect-your-smart-thermostat`,
  urlsBlogFaqsHome: `${MARKETING_ENERGY_TEXAS_URL}/faq`,
  urlsBlogPostsEnergySavingTips: `${MARKETING_ENERGY_TEXAS_URL}/blog/how-to-save-on-energy-in-texas-cut-your-bill-today`,
  urlsComparePower: ({
    trackingId,
    zipCode,
  }: {
    trackingId: string;
    zipCode: string;
  }) => `https://go.comparepower.com/energy-texas/?zip_code=${zipCode}`,
  urlsFaqsEtfReimbursement: `${MARKETING_ENERGY_TEXAS_URL}/faq#will-energy-texas-reimburse-my-current-supplier-s-etf`,
  urlsFaqsHome: `${MARKETING_ENERGY_TEXAS_URL}/faq`,
  urlsFaqsOutages: `${MARKETING_ENERGY_TEXAS_URL}/contact-us#how-can-i-report-or-get-information-on-a-power-outage`,
  urlsFaqsReferAFriend: `${MARKETING_ENERGY_TEXAS_URL}/faq#what-is-the-refer-a-friend-program`,
  urlsFaqsRewards: `${MARKETING_ENERGY_TEXAS_URL}/faq#accordion-what-is-the-rhythm-rewards-program`,
  urlsFaqsSatisfyDeposit: `${MARKETING_ENERGY_TEXAS_URL}/contact-us#how-can-i-satisfy-a-deposit`,
  urlsFaqsSolarBuyBack: `${MARKETING_ENERGY_TEXAS_URL}/solar-buyback-plan-faq`,
  urlsHomePage: MARKETING_ENERGY_TEXAS_URL,
  urlsPlansSolar: `${MARKETING_ENERGY_TEXAS_URL}/solar`,
  urlsPrivacyPolicy: `${MARKETING_ENERGY_TEXAS_URL}/privacy-policy`,
  urlsReferAFriend: `${MARKETING_ENERGY_TEXAS_URL}/raf`,
  urlsTermsOfUse: `${MARKETING_ENERGY_TEXAS_URL}/terms-of-use`,
} satisfies typeof rhythmInformation;

export const getBrandInformation = () => {
  const appBrand = getAppBrand();

  if (appBrand === AppBrands.ENERGY_TEXAS) {
    return energyTexasInformation;
  }

  return rhythmInformation;
};

export const brandInformation = getBrandInformation(); // TODO: remove this post-MVP of multi-brand
