import { FeatureFlagClientProvider } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider";
import {
  ActionType,
  EventType,
  track,
} from "@portal-account/services/segment.service";
import { featureFlagUserIdAtom } from "@portal-shared/hooks/useFeatureFlagUserId/featureFlagUserId.atoms";
import { useAtomValue } from "jotai";
import React, { PropsWithChildren, useEffect } from "react";

interface PortalFeatureFlagClientProviderProps {}

export const PortalFeatureFlagClientProvider = (
  props: PropsWithChildren<PortalFeatureFlagClientProviderProps>
) => {
  const { children } = props;

  const featureFlagUserId = useAtomValue(featureFlagUserIdAtom);

  useEffect(() => {
    if (featureFlagUserId) {
      track({
        action: ActionType.userIdentified,
        category: "portal",
        event: EventType.identify,
        label: "User Identified",
        value: featureFlagUserId,
      });
    }
  }, [featureFlagUserId]);

  return (
    <FeatureFlagClientProvider featureFlagUserId={featureFlagUserId}>
      {children}
    </FeatureFlagClientProvider>
  );
};
