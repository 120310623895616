import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { createPasswordPath } from "@portal-shared/routes/routePaths";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ActivationController = () => {
  const params = useParams<{
    activationToken: string;
  }>();

  const activationToken = params.activationToken as string;
  const navigate = useNavigate();

  useEffect(() => {
    if (activationToken) {
      const searchParams = new URLSearchParams({
        activationToken,
      });

      navigate(`${createPasswordPath()}?${searchParams.toString()}`);
    }
  }, [activationToken, navigate]);

  return <RhCircularProgress />;
};
