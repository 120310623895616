import { UUIDType } from "@common/types/apiTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacing } from "@design-system/theme/spacing";
import { QuickPayError } from "@portal-guest/components/QuickPayError/QuickPayError";
import { QuickPayForm } from "@portal-guest/components/QuickPayForm/QuickPayForm";
import { useQuickPayAccountBalanceQuery } from "@portal-guest/hooks/queries/useQuickPayAccountBalance.query";
import { googleAnalyticsDataAtom } from "@portal-shared/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking.atoms";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const AmountContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${CSSVariables.COLOR_GREY_100};
  border-top: 1px solid ${CSSVariables.COLOR_GREY_100};
  display: flex;
  flex-direction: row;
  height: 64px;
  justify-content: space-between;
  width: 100%;
`;

const QuickPayContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacing(3)}px;
  text-align: center;
  width: 350px;
`;

const Subtitle = styled(RhTypography)`
  &.MuiTypography-root {
    max-width: 25ch;
  }
`;

const BalancesContainer = styled.div`
  width: 100%;
`;

export const QuickPay = () => {
  const params = useParams<{ token: UUIDType }>();
  const token = params.token as UUIDType;
  const quickPayAccountBalanceQuery = useQuickPayAccountBalanceQuery({ token });
  const setGoogleAnalyticsDataAtom = useSetAtom(googleAnalyticsDataAtom);
  const { t } = useRhIntl();
  const [, setLocale] = useLocaleAtom();

  useEffect(() => {
    if (quickPayAccountBalanceQuery.data?.customerLanguagePreference) {
      setLocale(quickPayAccountBalanceQuery.data?.customerLanguagePreference);
    }
  }, [quickPayAccountBalanceQuery.data?.customerLanguagePreference, setLocale]);

  useEffect(() => {
    if (quickPayAccountBalanceQuery.data) {
      setGoogleAnalyticsDataAtom({
        customerUuid: quickPayAccountBalanceQuery.data.customerUuid,
        premiseUuid: quickPayAccountBalanceQuery.data.premiseUuid,
        type: "tokenAuth",
      });
    }
  }, [quickPayAccountBalanceQuery.data, setGoogleAnalyticsDataAtom]);

  if (quickPayAccountBalanceQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (quickPayAccountBalanceQuery.isError) {
    const { error } = quickPayAccountBalanceQuery;

    if (error.status === 408) {
      // if the request was cancelled, show nothing
      return null;
    }

    return (
      <QuickPayError error={quickPayAccountBalanceQuery.error} token={token} />
    );
  }

  const {
    totalBalance,
    pastDueBalance,
    isNoCheckOrAch,
    isNoCreditOrDebitCard,
  } = quickPayAccountBalanceQuery.data;

  return (
    <QuickPayContainer>
      <RhTypography variant="h1">{t("QuickPay.title")}</RhTypography>
      <Subtitle variant="subtitle2">{t("QuickPay.subTitle")}</Subtitle>
      <BalancesContainer>
        <AmountContainer>
          <RhTypography variant="subtitle2">
            {t("QuickPay.amountDue")}
          </RhTypography>
          <RhTypography variant="h3">
            {formatCurrency(totalBalance)}
          </RhTypography>
        </AmountContainer>
        <AmountContainer>
          <RhTypography variant="body1">{t("QuickPay.pastDue")}</RhTypography>
          <RhTypography color="error" variant="body1">
            {formatCurrency(pastDueBalance)}
          </RhTypography>
        </AmountContainer>
      </BalancesContainer>
      {isNoCheckOrAch && isNoCreditOrDebitCard ? (
        <RhTypography variant="body1">
          {t("QuickPay.unableToCollect")}
        </RhTypography>
      ) : (
        <QuickPayForm
          token={token}
          totalBalance={totalBalance}
          pastDueBalance={pastDueBalance}
          isNoCheckOrAch={isNoCheckOrAch}
          isNoCreditOrDebitCard={isNoCreditOrDebitCard}
        />
      )}
    </QuickPayContainer>
  );
};
