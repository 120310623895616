import { FeatureFlagMetric } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.types";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import {
  ActionType,
  CategoryType,
  EnrollmentEvents,
  track,
} from "@portal-enroll/services/segment.service";
import { setSignUpInfo } from "@portal-enroll/slices/signUpSlice";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

/**
 * murder me slowly
 */
export const useCompatibilityNextLogic = () => {
  const dispatch = useDispatch();
  const { featureFlagClient } = useFeatureFlagClient();
  const prospect = useProspectFromContext();

  const contactNextCompatibilityFunction = useCallback(() => {
    track({
      action: ActionType.clickedNextPage,
      label: "contactNextPage",
    });

    featureFlagClient.trackMetric(
      FeatureFlagMetric.PortalProspectEmailSubmitted
    );

    if (prospect.marketingEmailOptIn) {
      track({
        action: ActionType.emailSubmitted,
        category: "contact.marketingPromoOptIn",
        event: EnrollmentEvents.enrollmentClick,
        label: prospect.email as string,
        value: true,
      });
    }

    track({
      action: ActionType.contactSubmitted,
      category: CategoryType.Contact,
      email: prospect.email as string,
      event: EnrollmentEvents.enrollmentClick,
      label: "Contact Information",
      phone: prospect.phone as string,
    });

    dispatch(
      setSignUpInfo({
        visitedSteps: ["name", "contact", "address"],
      })
    );
  }, [dispatch, featureFlagClient, prospect]);

  return contactNextCompatibilityFunction;
};
