import { formatCurrency } from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { Box } from "@mui/material";
import { RedeemRewardsDialogContentProps } from "@portal-account/components/RedeemRewards/RedeemRewards";
import {
  StyledButtonsContainer,
  StyledDialogContainer,
  StyledDialogTitleWithIllustration,
  StyledIllustrationContainer,
  StyledRedeemRewardsDialogBody,
} from "@portal-account/components/RedeemRewards/RedeemRewards.styled";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import React from "react";
import { useIntl } from "react-intl";

export const RewardRedemptionSuccess = ({
  value,
  originalAccountBalance,
  onCancel,
}: RedeemRewardsDialogContentProps) => {
  const { formatMessage } = useIntl();
  const illustrationAltText = formatMessage({
    id: "RedeemRewards.coinConfetti",
  });
  const { portalRewardsSuccessCoinExplode } = useBrandImageUrls();

  return (
    <>
      <StyledIllustrationContainer>
        <img src={portalRewardsSuccessCoinExplode} alt={illustrationAltText} />
      </StyledIllustrationContainer>
      <StyledDialogTitleWithIllustration>
        {formatMessage(
          { id: "RedeemRewards.yourCreditHasBeenApplied" },
          {
            value: formatCurrency(value),
          }
        )}
      </StyledDialogTitleWithIllustration>
      <StyledDialogContainer>
        <StyledRedeemRewardsDialogBody
          variant="subtitle2"
          color="textSecondary"
        >
          {formatMessage(
            {
              id: "RedeemRewards.previousBalance",
            },
            {
              prevBalance: formatCurrency(originalAccountBalance),
            }
          )}
          <br />
          {formatMessage(
            {
              id: "RedeemRewards.newBalance",
            },
            {
              newBalance: formatCurrency(originalAccountBalance - value),
            }
          )}
        </StyledRedeemRewardsDialogBody>
        <StyledButtonsContainer>
          <Box width="100%">
            <RhButton
              data-tracking-click={{
                event: "Customer canceling applying rewards",
              }}
              fullWidth
              color="primary"
              onClick={onCancel}
            >
              {formatMessage({ id: "RedeemRewards.backToHome" })}
            </RhButton>
          </Box>
        </StyledButtonsContainer>
      </StyledDialogContainer>
    </>
  );
};
