import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { SignUpStepType } from "@enroll-utils/constants/routePaths";
import { SignUpStateType } from "@portal-enroll/slices/signUpSlice";

export const rhythmBillingPreferencesNextStep = (
  signUpState: SignUpStateType,
  hasCustomerEmailConflict: boolean
): SignUpStepType => {
  const {
    creditEvaluation,
    additionalInformationRequired,
    ssnRequired,
    nosRequired,
    depositAmount,
    autopay,
  } = signUpState;

  if (
    creditEvaluation === CreditScoreOutcome.manualReview &&
    !additionalInformationRequired
  ) {
    return "call-us";
  } else if (additionalInformationRequired || hasCustomerEmailConflict) {
    return "provide-additional-information";
  } else if (ssnRequired) {
    return "credit-check";
  } else if (nosRequired) {
    return "call-us";
  } else if (depositAmount) {
    return "deposit";
  } else if (autopay) {
    return "payment";
  } else {
    return "add-ons";
  }
};
