import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import {
  ProductTierFeaturesListItem,
  ProductTierFeaturesUnorderedList,
} from "@portal-shared/components/ProductTierFeatures/ProductTierFeatures.styled";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { ProductTier } from "@portal-shared/models/ProductTier.model";
import { ProductTierFeatureIconType } from "@portal-shared/types/productTypes";
import React from "react";

interface ProductTierFeaturesProps {
  productTier: ProductTier;
}

export const ProductTierFeatures = ({
  productTier,
}: ProductTierFeaturesProps) => {
  const {
    iconCalendar,
    iconCircularCheck,
    iconShield,
    iconPerson2,
    iconTag,
    iconWallet,
  } = useBrandImageUrls();

  const featureIconsMapping: Record<ProductTierFeatureIconType, JSX.Element> = {
    calendar: <img src={iconCalendar} alt="" aria-hidden />,
    check: <img src={iconCircularCheck} alt="" aria-hidden />,
    guarantee: <img src={iconShield} alt="" aria-hidden />,
    person_2: <img src={iconPerson2} alt="" aria-hidden />,
    price_tag: <img src={iconTag} alt="" aria-hidden />,
    save_money: <img src={iconWallet} alt="" aria-hidden />,
  };

  return (
    <ProductTierFeaturesUnorderedList>
      {productTier.sortedFeatures.map((feature) => {
        return (
          <ProductTierFeaturesListItem key={feature.priority}>
            {featureIconsMapping[feature.iconName]}
            <RhTypography>{feature.description}</RhTypography>
          </ProductTierFeaturesListItem>
        );
      })}
    </ProductTierFeaturesUnorderedList>
  );
};
