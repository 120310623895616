import { RhButton } from "@design-system/components/RhButton/RhButton";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { CircularProgress } from "@mui/material";
import { PortalCheckboxLabel } from "@portal-shared/components/PortalCheckbox/PortalCheckbox";
import styled from "styled-components";

export const EnrollContactFormForm = styled.form`
  min-width: 325px;
  padding-top: ${rhSpacingPx(5)};
`;

export const EnrollContactCtaButton = styled(RhButton)`
  &.MuiButton-root {
    height: 50px;
    margin-bottom: ${rhSpacingPx(3)};
    width: 100%;
  }
`;

export const EnrollContactFormCircularProgress = styled(CircularProgress)`
  margin-right: ${rhSpacingPx(1)};
`;

export const EnrollContactFormCheckboxCheckboxLabel = styled(
  PortalCheckboxLabel
)`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(1)};
  margin-bottom: ${rhSpacingPx(4)};
  max-width: 325px;
`;
