import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const PortalInputErrorMessage = styled.div`
  color: ${CSSVariables.COLOR_ERROR_MAIN};
  height: ${rhSpacingPx(2)};
  margin-bottom: ${rhSpacingPx(1)};
  margin-top: ${rhSpacingPx(0.5)};
`;
