import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { GuestAddBankAccount } from "@portal-guest/components/GuestAddBankAccount/GuestAddBankAccount";
import { GuestAddPaymentCard } from "@portal-guest/components/GuestAddPaymentCard/GuestAddPaymentCard";
import { useGuestPayAddPaymentMethodMutation } from "@portal-guest/hooks/mutations/useGuestPayAddPaymentMethod.mutation";
import { useGuestPayPaymentMethodQuery } from "@portal-guest/hooks/queries/useGuestPayPaymentMethod.query";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React from "react";
import styled from "styled-components";

const useGuestPayAddOrGetPaymentMethodTranslation = () => {
  const { t } = useRhIntl();

  return {
    tAddPaymentMethodError: t(
      "GuestPayAddOrGetPaymentMethod.addPaymentMethodError"
    ),
    tPaymentAdded: t("GuestPayAddOrGetPaymentMethod.paymentAdded"),
  };
};

interface GuestPayAddOrGetPaymentMethodProps {
  isNoCheckOrAch: boolean;
  isNoCreditOrDebitCard: boolean;
  token: string;
}

export const GuestPayAddOrGetPaymentMethod = (
  props: GuestPayAddOrGetPaymentMethodProps
) => {
  const { token, isNoCheckOrAch, isNoCreditOrDebitCard } = props;
  const flash = useRhFlash();

  const guestPayAddPaymentMethodMutation =
    useGuestPayAddPaymentMethodMutation();
  const guestPayPaymentMethodQuery = useGuestPayPaymentMethodQuery({
    // Once we add a card, we never want to enable this query instance,
    // as it would mean that once a payment method is there and the BE
    // fails for whatever reason, the add payment button would show up again
    enabled: !guestPayAddPaymentMethodMutation.isSuccess,
    // We don't want to refetch on error as this is supposed to fail when there are no cards
    retry: 0,
    token,
  });

  const { tAddPaymentMethodError, tPaymentAdded } =
    useGuestPayAddOrGetPaymentMethodTranslation();

  const handleAddCardSuccess = (data: ZuoraAddPaymentResponseType) => {
    guestPayAddPaymentMethodMutation.mutate(
      {
        paymentMethodId: data.refId,
        token,
      },
      {
        onError: () => {
          flash.error(tAddPaymentMethodError);
        },
        onSuccess: () => {
          flash.success(tPaymentAdded);
        },
      }
    );
  };

  if (guestPayPaymentMethodQuery.isError) {
    return (
      <StyledCardWrapper>
        {!isNoCreditOrDebitCard ? (
          <GuestAddPaymentCard
            onCardAdded={handleAddCardSuccess}
            showFlashSuccess={false}
          />
        ) : null}
        {!isNoCheckOrAch ? (
          <GuestAddBankAccount
            onBankAccountAdded={handleAddCardSuccess}
            showFlashSuccess={false}
          />
        ) : null}
      </StyledCardWrapper>
    );
  }

  if (guestPayPaymentMethodQuery.isPending) {
    return <RhCircularProgress />;
  }

  return null;
};

const StyledCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  margin: ${rhSpacingPx(1.5)} 0;
`;
