import { useAppCookies } from "@common/hooks/useAppCookies";

type AllKeysRequiredButAllowUndefined<T> = {
  [K in keyof Required<T>]: T[K] | undefined;
};
export const prospectCookieNamePrefix = "rhProspectUuid";

export interface EnrollCookiesWithEnv {
  [prospectCookieNamePrefix]?: string;
}

export const defaultEnrollCookiesWithEnvValues: AllKeysRequiredButAllowUndefined<EnrollCookiesWithEnv> =
  {
    rhProspectUuid: undefined,
  };

export interface EnrollCookies extends EnrollCookiesWithEnv {
  _fs_uid?: string; // FullStory Id
  _ga?: string; // Google Analytics Client Id
  ajs_anonymous_id?: string; // Segment anonymous Id
  rhFeatureFlagUserId?: string;
}

const defaultPortalCookiesValues: AllKeysRequiredButAllowUndefined<EnrollCookies> =
  {
    _fs_uid: undefined,
    _ga: undefined,
    ajs_anonymous_id: undefined,
    rhFeatureFlagUserId: undefined,
    ...defaultEnrollCookiesWithEnvValues,
  };

export const useEnrollCookies = () => {
  return useAppCookies<EnrollCookies>(
    defaultPortalCookiesValues,
    defaultEnrollCookiesWithEnvValues
  );
};
