import { HttpStatusCodes } from "@common/types/apiResponseTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { SESSION_STORAGE_PREMISE_ID_KEY } from "@portal-account/components/PremiseIdChooser/PremiseIdChooser.constants";
import { PremiseContext } from "@portal-account/components/PremiseProvider/PremiseContext";
import { usePremiseQuery } from "@portal-account/hooks/queries/usePremise.query";
import { accountCreationPendingPath } from "@portal-account/routes/routePaths";
import { googleAnalyticsDataAtom } from "@portal-shared/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking.atoms";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { signOutPath } from "@portal-shared/routes/routePaths";
import { useSetAtom } from "jotai";
import React, { PropsWithChildren, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface PremiseProviderProps {}

export const PremiseProvider = ({
  children,
}: PropsWithChildren<PremiseProviderProps>) => {
  const [premiseIdInSessionStorage] = useSessionStorage<string | null>(
    SESSION_STORAGE_PREMISE_ID_KEY,
    null
  );

  const setGoogleAnalyticsDataAtom = useSetAtom(googleAnalyticsDataAtom);

  const premiseQuery = usePremiseQuery({
    premiseId: premiseIdInSessionStorage || "",
    queryOptions: { enabled: Boolean(premiseIdInSessionStorage) },
  });

  useEffect(() => {
    if (premiseQuery.data) {
      const newPremiseInstance = premiseQuery.data;

      setGoogleAnalyticsDataAtom({
        customerUuid: newPremiseInstance.customerUuid,
        premiseCurrentOrderUuid: newPremiseInstance.currentOrder?.uuid,
        premiseUuid: newPremiseInstance.uuid,
        type: "loggedIn",
      });
    }
  }, [premiseQuery.data, setGoogleAnalyticsDataAtom]);

  if (premiseQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (premiseQuery.isError) {
    if (premiseQuery.error.status === HttpStatusCodes.HTTP_403_FORBIDDEN) {
      return <Navigate to={signOutPath()} />;
    }

    return <Error500Page />;
  }

  const premise = premiseQuery.data;

  if (!premise.billingAccountId) {
    return <Navigate to={accountCreationPendingPath()} />;
  }

  return (
    <PremiseContext.Provider value={premise}>
      {children}
    </PremiseContext.Provider>
  );
};
