import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const StyledReconnectionMessaging = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  text-align: center;
  text-wrap: balance;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
  justify-content: center;

  & > img {
    margin-bottom: ${rhSpacingPx(2)};
  }

  & > a {
    height: 60px;
    margin-top: ${rhSpacingPx(2)};
    width: 80%;
  }
`;
