import { Order } from "@common/models/Order.model";
import { IdType } from "@common/types/apiTypes";
import { formatDateRange } from "@common/utils/dataFormatters";
import { brandTheme } from "@design-system/brandTheme/getBrandTheme";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as PDFIcon } from "@design-system/icons/PDFIcon.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { OfferFeatures } from "@portal-shared/components/OfferFeatures/OfferFeatures";
import { OfferRateAndEstimate } from "@portal-shared/components/OfferRateAndEstimate/OfferRateAndEstimate";
import { renewalCurrentPlanCardTranslations } from "@portal-shared/components/RenewalCurrentPlanCard/RenewalCurrentPlanCard.en.i18n";
import {
  StyledBody,
  StyledContainer,
  StyledFeatures,
  StyledFooter,
  StyledFooterItem,
  StyledHeader,
  StyledPurpleText,
  StyledTitle,
} from "@portal-shared/components/RenewalCurrentPlanCard/RenewalCurrentPlanCard.styled";
import { TrackingView } from "@portal-shared/components/TrackingView/TrackingView";
import { WelcomePacketLink } from "@portal-shared/components/WelcomePacketLink/WelcomePacketLink";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import React from "react";

interface RenewalCurrentPlanCardProps {
  offerSnapshot: OfferSnapshot;
  order: Order;
  premiseId?: IdType;
}

export const RenewalCurrentPlanCard = ({
  order,
  offerSnapshot,
  premiseId,
}: RenewalCurrentPlanCardProps) => {
  const { translate } = useTranslations();
  const {
    tRenewalCurrentPlanCardCurrentPlan,
    tRenewalCurrentPlanMonthToMonth,
    tRenewalCurrentPlanYourContractDocuments,
  } = translate(renewalCurrentPlanCardTranslations);
  const { iconCalendar } = useBrandImageUrls();

  const [locale] = useLocaleAtom();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(RhythmBreakpoints.SM));

  const { isMonthToMonth } = offerSnapshot;

  const showWelcomePacketLink = premiseId && !isMonthToMonth;

  return (
    <StyledContainer>
      <TrackingView
        data={{
          event: "Renewal current plan card viewed",
          offerSnapshotUuid: offerSnapshot.uuid,
        }}
      />
      <StyledTitle fontWeight="Bold">
        {tRenewalCurrentPlanCardCurrentPlan}
      </StyledTitle>
      <StyledBody>
        <StyledHeader>
          <RhTypography fontWeight="Bold" variant="h3">
            {offerSnapshot.title}
          </RhTypography>

          {isDesktop ? (
            <RhTypography variant="body1" color="textSecondary">
              {offerSnapshot.description(locale)}
            </RhTypography>
          ) : null}
        </StyledHeader>

        {isDesktop ? (
          <StyledFeatures>
            <OfferFeatures offerSnapshot={offerSnapshot} />
          </StyledFeatures>
        ) : null}

        <OfferRateAndEstimate
          offerSnapshot={offerSnapshot}
          hideTooltip
          hideSolarEligible={!order.solarEligible}
        />
      </StyledBody>
      <StyledFooter>
        <StyledFooterItem>
          <img src={iconCalendar} alt="" aria-hidden />
          <RhTypography>
            {isMonthToMonth
              ? tRenewalCurrentPlanMonthToMonth
              : formatDateRange(order.startDate, order.endDate)}
          </RhTypography>
        </StyledFooterItem>
        {showWelcomePacketLink ? (
          <StyledFooterItem>
            <PDFIcon
              width={20}
              height={20}
              color={brandTheme.colors.primary.main}
            />
            <WelcomePacketLink
              dataTrackingClick={{
                location: "Renewals Current Plan Card",
              }}
              orderId={order.id}
              premiseId={premiseId}
            >
              <StyledPurpleText>
                {tRenewalCurrentPlanYourContractDocuments}
              </StyledPurpleText>
            </WelcomePacketLink>
          </StyledFooterItem>
        ) : null}
      </StyledFooter>
    </StyledContainer>
  );
};
