import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import {
  EnrollPageBackgroundIllustrationLeft,
  EnrollPageBackgroundIllustrationRight,
  PortalPageLayoutStyledPageBackground,
} from "@portal-shared/styled/portalPageLayout.styled";
import React from "react";

export const EnrollOffersPageBackground = () => {
  const { pageOffersBackgroundImageLeft, pageOffersBackgroundImageRight } =
    useBrandImageUrls();

  return (
    <>
      <EnrollPageBackgroundIllustrationLeft>
        <img
          src={pageOffersBackgroundImageLeft}
          alt=""
          width="195px"
          height="auto"
        />
      </EnrollPageBackgroundIllustrationLeft>
      <EnrollPageBackgroundIllustrationRight>
        <img
          src={pageOffersBackgroundImageRight}
          alt=""
          width="195px"
          height="auto"
        />
      </EnrollPageBackgroundIllustrationRight>
      <PortalPageLayoutStyledPageBackground />
    </>
  );
};
