import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

export const LoginFormForm = styled.form`
  min-width: 250px;
`;

export const LoginFormCircularProgress = styled(CircularProgress)`
  margin-right: ${rhSpacingPx(1)};
`;

export const LoginFormCtaButton = styled(RhButton)`
  &.MuiButton-root {
    height: 50px;
    margin-bottom: ${rhSpacingPx(3)};
    width: 100%;
  }
`;

export const LoginFormInputErrorMessage = styled.div`
  color: ${CSSVariables.COLOR_ERROR_MAIN};
  height: ${rhSpacingPx(2)};
  margin-bottom: ${rhSpacingPx(1)};
  margin-top: ${rhSpacingPx(0.5)};
`;
