import { maskPhone, unmaskPhone } from "@common/forms/phone.mask";
import { PROSPECT_INVALID_PHONE_ERROR } from "@common/types/errorTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useProspectUpdateMutation } from "@enroll-data/hooks/mutations/useProspectUpdate.mutation";
import { signUpStepPath } from "@enroll-utils/constants/routePaths";
import { zodResolver } from "@hookform/resolvers/zod";
import { enrollContactFormTranslations } from "@portal-enroll/components/EnrollContactForm/EnrollContactForm.en.i18n";
import {
  EnrollContactCtaButton,
  EnrollContactFormCheckboxCheckboxLabel,
  EnrollContactFormCircularProgress,
  EnrollContactFormForm,
} from "@portal-enroll/components/EnrollContactForm/EnrollContactForm.styled";
import {
  EnrollContactFormValues,
  getEnrollContactFormValidation,
} from "@portal-enroll/components/EnrollContactForm/EnrollContactFormSchema";
import { useCompatibilityNextLogic } from "@portal-enroll/components/EnrollContactForm/useContactNextCompatibility";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { PortalCheckbox } from "@portal-shared/components/PortalCheckbox/PortalCheckbox";
import { PortalInput } from "@portal-shared/components/PortalInput/PortalInput.styled";
import { PortalInputErrorMessage } from "@portal-shared/components/PortalInputErrorMessage/PortalInputErrorMessage.styled";
import { PortalInputLabel } from "@portal-shared/components/PortalInputLabel/PortalInputLabel.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const EnrollContactForm = () => {
  const { translate } = useTranslations();
  const navigate = useNavigate();
  const flash = useRhFlash();
  const prospectUpdateMutation = useProspectUpdateMutation();
  const prospect = useProspectFromContext();
  const compatibilityNextLogic = useCompatibilityNextLogic();

  const {
    tEnrollContactFormEmail,
    tEnrollContactFormEmailConfirm,
    tEnrollContactFormNotificationsSignUp,
    tEnrollContactFormPhone,
    tEnrollContactFormSendContactInfoCta,
    tEnrollContactFormSendingContactInfoCta,
    tEnrollContactFormErrorInvalidUsPhoneNumber,
    tEnrollContactFormErrorUpdatingProspect,
    tEnrollContactFormEmailsMustMatch,
    tEnrollContactFormInvalidConfirmEmail,
    tEnrollContactFormInvalidEmail,
  } = translate(enrollContactFormTranslations);

  const enrollContactFormValidation = getEnrollContactFormValidation({
    errorEmailsMustMatch: tEnrollContactFormEmailsMustMatch,
    errorInvalidConfirmEmail: tEnrollContactFormInvalidConfirmEmail,
    errorInvalidEmail: tEnrollContactFormInvalidEmail,
    errorInvalidPhoneNumber: tEnrollContactFormErrorInvalidUsPhoneNumber,
  });

  const formMethods = useForm<EnrollContactFormValues>({
    defaultValues: {
      confirmEmail: prospect.email || "",
      email: prospect.email || "",
      marketingEmailOptIn: prospect.marketingEmailOptIn || false,
      phone: prospect.phone ? maskPhone(unmaskPhone(prospect.phone)) : "",
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: zodResolver(enrollContactFormValidation),
  });

  const onSubmit = formMethods.handleSubmit(
    (formValues: EnrollContactFormValues) => {
      const { email, marketingEmailOptIn, phone } = formValues;

      prospectUpdateMutation.mutate(
        { data: { email, marketingEmailOptIn, phone } },
        {
          onError: (error) => {
            if (error.data.errorCode === PROSPECT_INVALID_PHONE_ERROR) {
              formMethods.setError("phone", {
                message: tEnrollContactFormErrorInvalidUsPhoneNumber,
                type: "manual",
              });

              return;
            }

            flash.error(tEnrollContactFormErrorUpdatingProspect);
          },
          onSuccess: () => {
            compatibilityNextLogic();

            formMethods.clearErrors("phone");

            navigate(signUpStepPath("address"));
          },
        }
      );
    }
  );

  const emailFormProps = formMethods.register("email");
  const confirmEmailFormProps = formMethods.register("confirmEmail");
  const phoneFormProps = formMethods.register("phone", {
    onChange: (e) => {
      const unmaskedValue = unmaskPhone(e.target.value);
      const limitedValue = unmaskedValue.slice(0, 10);
      const formattedValue = maskPhone(limitedValue);

      e.target.value = formattedValue;
    },
  });
  const marketingEmailOptInFormProps = formMethods.register(
    "marketingEmailOptIn"
  );

  return (
    <FormProvider {...formMethods}>
      <EnrollContactFormForm onSubmit={onSubmit}>
        <PortalInputLabel htmlFor="email">
          {tEnrollContactFormEmail}
        </PortalInputLabel>
        <PortalInput
          {...emailFormProps}
          id="email"
          aria-invalid={Boolean(formMethods.formState.errors.email)}
          type="email"
        />
        <PortalInputErrorMessage>
          {formMethods.formState.errors.email &&
            formMethods.formState.errors.email.message}
        </PortalInputErrorMessage>
        <PortalInputLabel htmlFor="confirmEmail">
          {tEnrollContactFormEmailConfirm}
        </PortalInputLabel>
        <PortalInput
          {...confirmEmailFormProps}
          id="confirmEmail"
          aria-invalid={Boolean(formMethods.formState.errors.confirmEmail)}
          type="email"
        />
        <PortalInputErrorMessage>
          {formMethods.formState.errors.confirmEmail &&
            formMethods.formState.errors.confirmEmail.message}
        </PortalInputErrorMessage>
        <PortalInputLabel htmlFor="phone">
          {tEnrollContactFormPhone}
        </PortalInputLabel>
        <PortalInput
          {...phoneFormProps}
          placeholder="555-555-5555"
          id="phone"
          aria-invalid={Boolean(formMethods.formState.errors.phone)}
          type="phone"
        />
        <PortalInputErrorMessage>
          {formMethods.formState.errors.phone &&
            formMethods.formState.errors.phone.message}
        </PortalInputErrorMessage>
        <EnrollContactFormCheckboxCheckboxLabel>
          <PortalCheckbox {...marketingEmailOptInFormProps} />

          <RhTypography>{tEnrollContactFormNotificationsSignUp}</RhTypography>
        </EnrollContactFormCheckboxCheckboxLabel>
        <EnrollContactCtaButton
          data-tracking-click={{ event: "User sending contact info" }}
          type="submit"
          disabled={prospectUpdateMutation.isPending}
          color="primary"
          size="large"
        >
          {prospectUpdateMutation.isPending ? (
            <>
              <EnrollContactFormCircularProgress color="inherit" size={14} />
              <RhTypography color="inherit" variant="inherit">
                {tEnrollContactFormSendingContactInfoCta}
              </RhTypography>
            </>
          ) : (
            tEnrollContactFormSendContactInfoCta
          )}
        </EnrollContactCtaButton>
      </EnrollContactFormForm>
    </FormProvider>
  );
};
