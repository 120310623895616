import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { enrollOffersPath } from "@enroll-utils/constants/routePaths";
import { enrollErrorPageTranslations } from "@portal-enroll/pages/EnrollErrorPage/EnrollErrorPage.en.i18n";
import {
  EnrollErrorPageErrorMessage,
  EnrollErrorPageStyledLightSwitch,
} from "@portal-enroll/pages/EnrollErrorPage/EnrollErrorPage.styled";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { BaseErrorPage } from "@portal-shared/pages/BaseErrorPage/BaseErrorPage";
import React, { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

interface EnrollErrorPageProps {}

export const EnrollErrorPage = (props: EnrollErrorPageProps) => {
  const location = useLocation();
  const { translate, translateJsx } = useTranslations();
  const { portalBull } = useBrandImageUrls();

  const { tEnrollErrorPageErrorHeader, tEnrollErrorPageErrorInfo } = translate(
    enrollErrorPageTranslations
  );

  const { tEnrollErrorPageErrorTryAgainCta } = translateJsx({
    tEnrollErrorPageErrorTryAgainCta: {
      clickHere: (content: ReactNode) => (
        <Link to={`${enrollOffersPath()}${location.search}`}>{content}</Link>
      ),
    },
  });

  const illustration = (
    <EnrollErrorPageStyledLightSwitch>
      <img src={portalBull} alt="" aria-hidden />
    </EnrollErrorPageStyledLightSwitch>
  );

  return (
    <BaseErrorPage
      header={tEnrollErrorPageErrorHeader}
      showLinkToHome={false}
      illustration={illustration}
    >
      <EnrollErrorPageErrorMessage variant="subtitle1">
        {tEnrollErrorPageErrorInfo}
      </EnrollErrorPageErrorMessage>
      <RhTypography variant="subtitle1">
        {tEnrollErrorPageErrorTryAgainCta}
      </RhTypography>
    </BaseErrorPage>
  );
};
