import { AddressFormType } from "@common/types/customerTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { enrollPremiseFormTranslations } from "@portal-enroll/components/EnrollPremiseForm/EnrollPremiseForm.en.i18n";
import {
  StyledEnrollPremiseForm,
  StyledEnrollPremiseFormRow,
  StyledEnrollZipCodeErrorMessage,
  StyledUnitNumberInput,
} from "@portal-enroll/components/EnrollPremiseForm/EnrollPremiseForm.styled";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { ActionType } from "@portal-enroll/services/segment.service";
import { PortalInput } from "@portal-shared/components/PortalInput/PortalInput.styled";
import { PortalInputErrorMessage } from "@portal-shared/components/PortalInputErrorMessage/PortalInputErrorMessage.styled";
import { PortalInputLabel } from "@portal-shared/components/PortalInputLabel/PortalInputLabel.styled";
import { StateSelectField } from "@portal-shared/components/StateSelectField/StateSelectField";
import { isValidZipCode } from "@portal-shared/forms/validators";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import { Controller, useForm } from "react-hook-form";

interface EnrollPremiseFormProps {
  disableSubmit: boolean;
  onSubmit: (serviceAddress: AddressFormType) => void;
}

export const EnrollPremiseForm = ({
  disableSubmit,
  onSubmit,
}: EnrollPremiseFormProps) => {
  const { state, addressLine1, zipCode, unitNumber, city } =
    useProspectFromContext();
  const { register, formState, handleSubmit, control } =
    useForm<AddressFormType>({
      defaultValues: {
        addressLine1: addressLine1 || undefined,
        city: city || undefined,
        state: state || "TX",
        unitNumber: unitNumber || undefined,
        zipCode: zipCode || undefined,
      },
      reValidateMode: "onBlur",
    });

  const { translate } = useTranslations();
  const { isValid, isSubmitting, errors } = formState;

  const { trackEvent } = useSignUpFlow();

  const {
    tEnrollPremiseFormStreetAddress,
    tEnrollPremiseFormUnit,
    tEnrollPremiseFormCity,
    tEnrollPremiseFormZipCode,
    tEnrollPremiseFormNext,
    tEnrollPremiseFormCityRequired,
    tEnrollPremiseFormStreetAddressRequired,
    tEnrollPremiseFormZipCodeRequired,
  } = translate(enrollPremiseFormTranslations);

  return (
    <StyledEnrollPremiseForm onSubmit={handleSubmit(onSubmit)}>
      <StyledEnrollPremiseFormRow>
        <PortalInputLabel>
          {tEnrollPremiseFormStreetAddress}
          <PortalInput
            {...register("addressLine1", {
              required: tEnrollPremiseFormStreetAddressRequired,
            })}
            aria-invalid={Boolean(errors.addressLine1)}
            onFocus={() =>
              trackEvent({
                action: ActionType.focus,
                label: "serviceAddress.addressLine1",
              })
            }
          />
        </PortalInputLabel>
        <PortalInputLabel>
          {tEnrollPremiseFormUnit}
          <StyledUnitNumberInput {...register("unitNumber")} />
        </PortalInputLabel>
      </StyledEnrollPremiseFormRow>
      <PortalInputErrorMessage>
        {errors.addressLine1 && errors.addressLine1.message}
      </PortalInputErrorMessage>
      <PortalInputLabel>
        {tEnrollPremiseFormCity}
        <PortalInput
          {...register("city", { required: tEnrollPremiseFormCityRequired })}
          aria-invalid={Boolean(errors.city)}
          onFocus={() =>
            trackEvent({
              action: ActionType.focus,
              label: "serviceAddress.city",
            })
          }
        />
      </PortalInputLabel>
      <PortalInputErrorMessage>
        {errors.city && errors.city.message}
      </PortalInputErrorMessage>
      <StyledEnrollPremiseFormRow>
        <Controller
          control={control}
          name="state"
          render={({ field: { onChange, value } }) => (
            <StateSelectField
              translationId="tEnrollPremiseFormState"
              name="state"
              defaultValue={value}
            />
          )}
        />
        <PortalInputLabel>
          {tEnrollPremiseFormZipCode}
          <PortalInput
            {...register("zipCode", {
              required: tEnrollPremiseFormZipCodeRequired,
              validate: (value) => isValidZipCode(value),
            })}
            aria-invalid={Boolean(errors.zipCode)}
            onFocus={() =>
              trackEvent({
                action: ActionType.focus,
                label: "serviceAddress.zipCode",
              })
            }
          />
        </PortalInputLabel>
      </StyledEnrollPremiseFormRow>
      <StyledEnrollZipCodeErrorMessage>
        {errors.zipCode && errors.zipCode.message}
      </StyledEnrollZipCodeErrorMessage>
      <RhButton
        data-tracking-click={{ event: "User sending address info" }}
        disabled={!isValid || isSubmitting || disableSubmit}
        type="submit"
        fullWidth
        size="large"
      >
        {tEnrollPremiseFormNext}
      </RhButton>
    </StyledEnrollPremiseForm>
  );
};
