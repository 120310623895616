export const loginFormTranslations: Record<string, string> = {
  tLoginFormEmail: "Email",
  tLoginFormErrorInvalidEmailOrPassword: "Invalid email or password.",
  tLoginFormErrorInvalidInputEmail: "Invalid email.",
  tLoginFormErrorInvalidInputEmailRequired: "Email is required.",
  tLoginFormLoggingInCustomer: "Logging you in",
  tLoginFormPassword: "Password",
  tLoginFormPasswordRequired: "Password is required.",
  tLoginFormSubmitCta: "Log In",
};
