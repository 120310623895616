import {
  SignUpStateType,
  SignUpStoreState,
} from "@portal-enroll/slices/signUpSlice";
import { createSelector } from "@reduxjs/toolkit";

export const selectSignUpState = (state: SignUpStoreState): SignUpStateType =>
  state.signUp;

export const selectVisitedSignUpPages = createSelector(
  selectSignUpState,
  ({ visitedSteps }) => ({ visitedSteps })
);

export const selectProspectId = createSelector(
  selectSignUpState,
  ({ prospectId }) => prospectId
);

export const selectSignUpPremiseFormValues = createSelector(
  selectSignUpState,
  ({
    dunsNumber,
    esiId,
    serviceAddress,
    depositAmount,
    depositAlternativeAmount,
    creditEvaluation,
    ssnRequired,
    additionalInformationRequired,
    depositAlternativeElected,
  }) => ({
    additionalInformationRequired,
    creditEvaluation,
    depositAlternativeAmount,
    depositAlternativeElected,
    depositAmount,
    dunsNumber,
    esiId,
    serviceAddress: {
      ...serviceAddress,
      // TODO: remove this when we move outside of TX
      state: serviceAddress.state || "TX", // for MLP, assume the address is in TX
    },
    ssnRequired,
  })
);

export const selectSignUpNameFormValues = createSelector(
  selectSignUpState,
  ({ dateOfBirth, firstName, lastName }) => ({
    dateOfBirth,
    firstName,
    lastName,
  })
);

export const selectSignUpContactFormValues = createSelector(
  selectSignUpState,
  ({ confirmEmail, firstName, email, phone, sendMarketingPromos }) => ({
    confirmEmail,
    email,
    firstName,
    phone,
    sendMarketingPromos,
  })
);

export const selectCreditCheckContactValues = createSelector(
  selectSignUpNameFormValues,
  selectSignUpContactFormValues,
  ({ firstName, lastName, dateOfBirth }, { email, phone }) => ({
    dateOfBirth,
    email,
    firstName,
    lastName,
    phone,
  })
);

export const selectSignUpAcquisition = createSelector(
  selectSignUpState,
  ({ acquisition }) => acquisition
);

export const selectCreditCheckValues = createSelector(
  selectSignUpNameFormValues,
  selectSignUpContactFormValues,
  selectSignUpPremiseFormValues,
  (
    { firstName, lastName, dateOfBirth },
    { email, phone },
    { serviceAddress }
  ) => ({
    dateOfBirth,
    email,
    firstName,
    lastName,
    phone,
    ...serviceAddress,
  })
);

export const selectCampaignPromo = createSelector(
  selectSignUpState,
  ({ campaignPromo }) => campaignPromo
);
