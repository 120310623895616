import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhCard } from "@design-system/components/RhCard/RhCard";
import { RhListItemText } from "@design-system/components/RhListItemText/RhListItemText.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { borders } from "@design-system/constants/borders";
import { ReactComponent as PDFIcon } from "@design-system/icons/PDFIcon.svg";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { fontFamily } from "@design-system/theme/typography";
import { List, ListItem, ListItemIcon } from "@mui/material";
import styled from "styled-components";

export const StyledTitle = styled.div`
  margin-bottom: ${rhSpacingPx(1)};
`;

export const StyledRhCard = styled(RhCard)`
  border: ${borders[100]};
  flex-basis: 100%;
  margin-right: 0;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};

  img {
    height: auto;
    width: 24px;
  }

  &.MuiListItemIcon-root {
    min-width: 32px;
  }
`;

export const StyledList = styled(List)`
  &.MuiList-padding {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const StyledListItem = styled(ListItem)`
  &.MuiListItem-dense {
    padding-bottom: ${rhSpacingPx(1)};
    padding-top: 0;
  }

  &.MuiListItem-gutters {
    padding-left: 0;
  }
`;

export const StyledListItemText = styled(RhListItemText)`
  & .MuiListItemText-primary {
    font-family: ${fontFamily};
    margin: 0;
  }
`;

export const StyledLegalDocTitle = styled.div`
  margin-bottom: ${rhSpacingPx(2)};
`;

export const StyledLegalDocIcon = styled(PDFIcon)`
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
`;

export const StyledLegalDocIconContainer = styled.div`
  align-items: flex-start;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-right: ${rhSpacingPx(1.5)};
  width: 24px;
`;

export const StyledLegalDocDescription = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_900};
    margin-top: ${rhSpacingPx(0.5)};
  }
`;

export const StyledLegalDocumentBody = styled.div`
  display: flex;
  margin-bottom: ${rhSpacingPx(2.5)};
`;

export const StyledLegalDocumentWrapper = styled.div`
  width: 90%;
`;

export const StyledSection = styled.section`
  display: flex;
`;
