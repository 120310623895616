import { Method } from "axios";

export const AREA_NOT_SERVICED = "pricing.offers.unavailable";
export const AUTH_PORTAL_UNACCEPTABLE_PASSWORD =
  "authentication_portal.error.unacceptable_password";
export const AUTH_PORTAL_INVALID_CREDENTIALS =
  "authentication_portal.error.invalid_credentials";
export const CUSTOMER_NOT_FOUND_ERROR = "customer.error.not_found";
export const DELETE_PAYMENT_METHOD_DISABLE_AUTOPAY_ERROR =
  "billing.delete_payment_method.autopay_enabled";
export const IRONHIDE_CUSTOMER = "prospect.already_ironhide_customer";
export const PROSPECT_INVALID_PHONE_ERROR = "rhythm.prospects.phone.invalid";
export const METER_NOT_SERVICEABLE = "edi.meter.unserviceable";
export const MULTIPLE_METERS = "edi.meter.multiple_meters";
export const OKTA_INVALID_TOKEN = "okta.connection.unauthorized";
export const OKTA_PASSWORD_REQUIREMENTS_ERROR =
  "okta.request.passwordDoesNotMeetRequirements";
export const OKTA_REQUEST_ERROR = "okta.request.failed";
export const PRICING_OFFERS_MULTIPLE_UTILITIES =
  "pricing.offers.multipleUtilities";

export const MTM_SIGNUP_ERROR =
  "rhythm.customer.offersnapshot_id.mtm_offer_snapshot_signup";

export const INVALID_START_DATE =
  "rhythm.customer.offersnapshot_id.offer_snapshot_service_start_date";

export const INVALID_PHONE = "rhythm.customer.phone.invalid";

export const PREMISE_RECENTLY_SWAPPED_ERROR_CODE =
  "premise.error.recently_swapped";

export enum BillingErrors {
  ZUORA_CONNECTION_ERROR = "zuora.connection.failed",
  ZUORA_DEPOSIT_ALTERNATIVE_FAILED = "zuora.deposit_alternative.failed",
  ZUORA_DEPOSIT_ERROR = "zuora.createDepositOrder.failed",
  ZUORA_DEPOSIT_PAYMENT_ERROR = "zuora.createDepositOrderPayment.failed",
  ZUORA_PAYMENT_FAILED = "zuora.payment.failed",
  ZUORA_PAYMENT_METHOD_NOT_FOUND = "zuora.payment_method.not_found",
}

export interface RhApiErrorDetail {
  code: string;
  detail?: string;
  field?: string;
}

export interface RhApiErrorResponseData {
  errorCode: string | null;
  errors: RhApiErrorDetail[];
  stateToken?: string;
}

interface RhBaseError {
  config?: {
    baseURL?: string;
    data?: string;
    method?: Method | string;
    params?: string;
    url?: string;
  };
  data: RhApiErrorResponseData;
  status?: number;
  statusText?: string;
}

export interface RhApiError extends RhBaseError {
  kind: "API";
}

export interface RhNetworkError extends RhBaseError {
  kind: "Network";
}

export interface FormError extends Record<string, string[] | FormError> {}

export type RhError = RhApiError | RhNetworkError;
