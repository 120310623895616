import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { rhSpacingPx } from "@design-system/theme/spacing";
import styled from "styled-components";

export const PortalInput = styled.input`
  border: 1px solid ${CSSVariables.COLOR_GREY_200};
  border-radius: 4px;
  font-size: 16px;
  height: 45px;
  padding: ${rhSpacingPx(1)} ${rhSpacingPx(1.5)};
  width: 100%;

  &[aria-invalid="true"] {
    border-color: ${CSSVariables.COLOR_ERROR_MAIN};
  }

  &:focus {
    border-color: transparent;
    outline: 2px solid ${CSSVariables.COLOR_PRIMARY_MAIN};

    &[aria-invalid="true"] {
      outline-color: ${CSSVariables.COLOR_ERROR_MAIN};
    }
  }

  &::placeholder {
    color: ${CSSVariables.COLOR_GREY_400};
  }
`;
