export const rhythmRTBsTranslations = {
  tRhythmRTBs30Day: "30-day Test Drive",
  tRhythmRTBs30DayDesc:
    "Try us for 30 days and if you don't love us, leave us penalty-free.",
  tRhythmRTBsBackToTop: "Back to plans",
  tRhythmRTBsBestPrice: "Best Price Always Pledge",
  tRhythmRTBsBestPriceDesc:
    "New and renewing customers get the same great deals. Always.",
  tRhythmRTBsGreenEnergy: "100% Green Energy Plans",
  tRhythmRTBsGreenEnergyDesc:
    "We make it a breeze to chip in and do your part for the planet.",
  tRhythmRTBsRateLock: "Rhythm Rate Lock",
  tRhythmRTBsRateLockDesc:
    "Pay the same fixed rate for electricity throughout your contract.",
};
