import { SessionGuard } from "@portal-shared/components/SessionGuard/SessionGuard";
import { usePortalSharedCookies } from "@portal-shared/hooks/usePortalSharedCookies";
import { customerHomePath, rootPath } from "@portal-shared/routes/routePaths";
import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

interface SessionCheckAuthnProps {
  secured: boolean;
}

export const SessionCheckAuthn = (
  props: PropsWithChildren<SessionCheckAuthnProps>
) => {
  const { children, secured } = props;
  const {
    cookies: { portal_session_state: portalSessionState },
    cookiesLoading,
  } = usePortalSharedCookies();

  if (cookiesLoading) {
    // Wait for cookies to resolve first
    return null;
  }

  if (secured && portalSessionState !== "logged_in") {
    return <Navigate to={rootPath()} />;
  }

  if (!secured && portalSessionState === "logged_in") {
    return <Navigate to={customerHomePath()} />;
  }

  if (secured) {
    return <SessionGuard>{children}</SessionGuard>;
  }

  return <>{children}</>;
};
