import { FeatureFlagValues } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.types";
import { atom, useAtom } from "jotai";

type SentFlagToDataLayerAtom = Partial<
  Record<keyof FeatureFlagValues, boolean>
>;

export const sentFlagToDataLayerAtom = atom<SentFlagToDataLayerAtom>({});

export const useSentFlagToDataLayerAtom = () => {
  return useAtom(sentFlagToDataLayerAtom);
};
