export const prospectQueryKeys = {
  BASE: ["prospect"] as const,
  availableOfferSnapshots: (options?: { rcid?: string }) =>
    [
      ...prospectQueryKeys.retrieve(),
      "available-offer-snapshots",
      options,
    ] as const,
  creditCheck: () => [...prospectQueryKeys.BASE, "credit-check"],
  retrieve: () => [...prospectQueryKeys.BASE, "me"] as const,
};
