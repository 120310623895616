import { AppBrands } from "@common/enums/appBrands.enum";
import { getAppBrand } from "@common/services/getEnvApplicationSettings.service";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { energyTexasBillingPreferencesNextStep } from "@portal-enroll/components/SignUpBillingPreferencesForm/energyTexasBillingPreferencesNextStep";
import { rhythmBillingPreferencesNextStep } from "@portal-enroll/components/SignUpBillingPreferencesForm/rhythmBillingPreferencesNextStep";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { useCallback } from "react";
import { useSelector } from "react-redux";

export const useGetSignUpBillingPreferencesNextStep = () => {
  const signUpState = useSelector(selectSignUpState);
  const appBrand = getAppBrand();
  const { hasCustomerEmailConflict } = useProspectFromContext();

  const getBillingPreferencesNextStep = useCallback(
    ({ autopay }: { autopay: boolean }) => {
      if (appBrand === AppBrands.ENERGY_TEXAS) {
        return energyTexasBillingPreferencesNextStep(
          {
            ...signUpState,
            autopay,
          },
          hasCustomerEmailConflict
        );
      }

      return rhythmBillingPreferencesNextStep(
        {
          ...signUpState,
          autopay,
        },
        hasCustomerEmailConflict
      );
    },
    [signUpState, appBrand, hasCustomerEmailConflict]
  );

  return { getBillingPreferencesNextStep };
};
