import { HttpStatusCodes } from "@common/types/apiResponseTypes";
import { RhApiError } from "@common/types/errorTypes";
import { prospectApi } from "@enroll-data/api/prospectApi";
import { prospectQueryKeys } from "@enroll-data/queryKeys/prospectQueryKeys";
import { enrollOffersErrorPath } from "@enroll-utils/constants/routePaths";
import {
  EnrollProspectType,
  ProspectUpdateRequestType,
} from "@enroll-utils/types/prospectTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

export const useProspectUpdateMutation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ data }: { data: ProspectUpdateRequestType }) => {
      return prospectApi.update({
        data,
      });
    },
    onError: (error: RhApiError) => {
      if (error.status === HttpStatusCodes.HTTP_403_FORBIDDEN) {
        navigate({
          pathname: enrollOffersErrorPath(),
          search: location.search,
        });
      }
    },
    onSettled: () => {
      queryClient.removeQueries({
        queryKey: prospectQueryKeys.availableOfferSnapshots(),
      });
      return queryClient.invalidateQueries({
        queryKey: prospectQueryKeys.BASE,
      });
    },
    onSuccess: (_, updateRequestData) => {
      const prospectData = queryClient.getQueryData(
        prospectQueryKeys.retrieve()
      ) as EnrollProspectType;

      // Optimistically set query data before invalidating to help slow networking
      queryClient.setQueryData(prospectQueryKeys.retrieve(), {
        ...prospectData,
        ...updateRequestData,
      });

      queryClient.removeQueries({
        queryKey: prospectQueryKeys.availableOfferSnapshots(),
      });

      return queryClient.invalidateQueries({
        queryKey: prospectQueryKeys.BASE,
      });
    },
  });

  return mutation;
};
