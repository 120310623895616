import { AppBrands } from "@common/enums/appBrands.enum";
import { getAppBrand } from "@common/services/getEnvApplicationSettings.service";
import { myAccountNavTranslations } from "@portal-account/components/MyAccountNav/MyAccountNav.en.i18n";

export enum AccountNavAnchorTag {
  billingPreferences = "billingPreferences",
  changePassword = "changePassword",
  communicationPreferences = "communicationPreferences",
  contactInfo = "contactInfo",
  languagePreference = "languagePreference",
  paymentMethods = "paymentMethods",
  productAddOns = "productAddOns",
  serviceInfo = "serviceInfo",
  yourPlan = "yourPlan",
}

interface MyAccountNavItemValue {
  anchorTag: string;
  translationId: Exclude<
    keyof typeof myAccountNavTranslations,
    "tMyAccountNavLogout"
  >;
}

export const rhythmMyAccountNavItemValues: MyAccountNavItemValue[] = [
  {
    anchorTag: `#${AccountNavAnchorTag.serviceInfo}`,
    translationId: "tMyAccountNavServiceInfo",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.yourPlan}`,
    translationId: "tMyAccountNavYourPlan",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.productAddOns}`,
    translationId: "tMyAccountNavProductAddOns",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.contactInfo}`,
    translationId: "tMyAccountNavContactInfo",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.languagePreference}`,
    translationId: "tMyAccountNavLanguagePreference",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.changePassword}`,
    translationId: "tMyAccountNavChangePassword",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.communicationPreferences}`,
    translationId: "tMyAccountNavCommunicationPreferences",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.billingPreferences}`,
    translationId: "tMyAccountNavBillingPreferences",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.paymentMethods}`,
    translationId: "tMyAccountNavPaymentMethods",
  },
];

export const energyTexasMyAccountNavItemValues: MyAccountNavItemValue[] = [
  {
    anchorTag: `#${AccountNavAnchorTag.serviceInfo}`,
    translationId: "tMyAccountNavServiceInfo",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.yourPlan}`,
    translationId: "tMyAccountNavYourPlan",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.contactInfo}`,
    translationId: "tMyAccountNavContactInfo",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.languagePreference}`,
    translationId: "tMyAccountNavLanguagePreference",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.changePassword}`,
    translationId: "tMyAccountNavChangePassword",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.communicationPreferences}`,
    translationId: "tMyAccountNavCommunicationPreferences",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.billingPreferences}`,
    translationId: "tMyAccountNavBillingPreferences",
  },
  {
    anchorTag: `#${AccountNavAnchorTag.paymentMethods}`,
    translationId: "tMyAccountNavPaymentMethods",
  },
];

export const getMyAccountNavItemValues = () => {
  const appBrand = getAppBrand();

  if (appBrand === AppBrands.ENERGY_TEXAS) {
    return energyTexasMyAccountNavItemValues;
  }

  return rhythmMyAccountNavItemValues;
};
