export const enrollPremiseFormTranslations = {
  tEnrollPremiseFormCity: "City",
  tEnrollPremiseFormCityRequired: "Some required information is missing.",
  tEnrollPremiseFormNext: "Next",
  tEnrollPremiseFormState: "State",
  tEnrollPremiseFormStreetAddress: "Street Address",
  tEnrollPremiseFormStreetAddressRequired:
    "Some required information is missing.",
  tEnrollPremiseFormUnit: "Unit #",
  tEnrollPremiseFormUnknownErrorApiMeterAvailability:
    "We are having difficulty verifying your address. Please check spelling or try again.",
  tEnrollPremiseFormUtilityChanged:
    "The address provided is a {utilityName} meter. Please select a new plan available within that utility.",
  tEnrollPremiseFormZipCode: "Zip Code",
  tEnrollPremiseFormZipCodeRequired: "Some required information is missing.",
};
