import { plansEmptyStateTranslations } from "@portal-enroll/components/PlansPageEmptyState/PlansPageEmptyState.en.i18n";
import {
  PlansPageEmptyStateIconContainer,
  PlansPageEmptyStateText,
} from "@portal-enroll/components/PlansPageEmptyState/PlansPageEmptyState.styled";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export function PlansPageEmptyState() {
  const { translate } = useTranslations();
  const { tThereAreNoPlans } = translate(plansEmptyStateTranslations);
  const { iconBigMagnifier } = useBrandImageUrls();

  return (
    <PlansPageEmptyStateIconContainer>
      <img src={iconBigMagnifier} alt="" aria-hidden />
      <PlansPageEmptyStateText>{tThereAreNoPlans}</PlansPageEmptyStateText>
    </PlansPageEmptyStateIconContainer>
  );
}
