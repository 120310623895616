import { EnergyUsageGraphV2 } from "@common/components/EnergyUsageGraphV2/EnergyUsageGraphV2";
import { useLatestAvailableDate } from "@common/components/EnergyUsageGraphV2/useLatestAvailableDate";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import {
  ChartDirection,
  GraphTemperatureValue,
  SelectedGraph,
  SelectedGraphMode,
  chartTypeMapping,
} from "@common/types/usageTypes";
import { RhCalendarDatePicker } from "@design-system/components/RhCalendarDatePicker/RhCalendarDatePicker";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhRoundTabItem } from "@design-system/components/RhRoundTabItem/RhRoundTabItem";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { EnergyUsageChartMissing } from "@portal-account/components/EnergyUsageChartMissing/EnergyUsageChartMissing";
import {
  DateAndTotalsContainer,
  EnergyGraphContainer,
  EnergyGraphTabsRadiosContainer,
  EnergyUsageGraphContainer,
  StyledDatePickerWrapper,
  StyledRhRoundTabs,
  StyledTapGraphText,
  TabContainer,
  TemperaturesContainer,
} from "@portal-account/components/EnergyUsageSectionV2/EnergyGraph.styles";
import { translations as EnergyUsageSectionV2Translations } from "@portal-account/components/EnergyUsageSectionV2/EnergyUsageSectionV2.en.i18n";
import { TotalUsage } from "@portal-account/components/EnergyUsageSectionV2/TotalUsage/TotalUsage";
import { usePremiseKwhUsageDayQuery } from "@portal-account/hooks/queries/usePremiseKwhUsageDay.query";
import { usePremiseKwhUsageSummaryQuery } from "@portal-account/hooks/queries/usePremiseKwhUsageSummary.query";
import { usePremiseTemperatureSummaryQuery } from "@portal-account/hooks/queries/usePremiseTemperatureSummary.query";
import { Premise } from "@portal-account/models/Premise.model";
import { KwhUsageSummaryType } from "@portal-account/types/usageTypes";
import {
  PortalRadioButton,
  PortalRadioLabel,
} from "@portal-shared/components/PortalRadioButton/PortalRadioButton";
import { usePortalOfferSnapshotQuery } from "@portal-shared/hooks/queries/usePortalOfferSnapshot.query";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";

// We don't need to map hourly data so exclude from this mapping
const usageDataMapping: Record<
  Exclude<SelectedGraph, "hourly">,
  keyof KwhUsageSummaryType
> = {
  [SelectedGraph.billPeriod]: "billPeriodData",
  [SelectedGraph.monthly]: "thirtyDaysData",
  [SelectedGraph.yearly]: "oneYearData",
};

interface EnergyGraphProps {
  premise: Premise;
}

export const EnergyGraph = (props: EnergyGraphProps) => {
  const { premise } = props;
  const theme = useTheme();

  const { translate } = useTranslations();
  const {
    tEnergyUsageSectionV2BillPeriodTab,
    tEnergyUsageSectionV2DailyTab,
    tEnergyUsageSectionV2DatePickerCancel,
    tEnergyUsageSectionV2DatePickerOk,
    tEnergyUsageSectionV2HourlyTab,
    tEnergyUsageSectionV2MonthlyTab,
    tEnergyUsageSectionV2EnergyGraphSectionLabel,
    tEnergyUsageSectionV2DateHourTitle,
    tEnergyUsageSectionV2DateTitle,
    tEnergyUsageSectionV2DefaultTierTitle,
    tEnergyUsageSectionV2GenerationLabelTitle,
    tEnergyUsageSectionV2UsageAxisTitle,
    tEnergyUsageSectionV2UsageLabelTitle,
    tEnergyUsageSectionV2UsageTooltipLabel,
    tEnergyUsageSectionV2CostTooltipLabel,
    tEnergyUsageSectionV2GenerationTooltipLabel,
    tEnergyUsageSectionV2EarnedTooltipLabel,
    tEnergyUsageSectionV2Both,
    tEnergyUsageSectionV2Consumption,
    tEnergyUsageSectionV2Surplus,
    tEnergyUsageSectionV2AverageTemp,
    tEnergyUsageSectionV2HighLow,
    tEnergyUsageSectionV2HideTemp,
    tEnergyUsageSectionV2Average,
    tEnergyUsageSectionV2High,
    tEnergyUsageSectionV2HighTemp,
    tEnergyUsageSectionV2Low,
    tEnergyUsageSectionV2LowTemp,
    tEnergyUsageSectionV2Temperature,
    tEnergyUsageSectionV2TemperatureFahrenheit,
    tEnergyUsageSectionV2Tap,
  } = translate(EnergyUsageSectionV2Translations);

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalTouGenerationUsageGraph, portalUsageGraphTemperatures } =
    featureFlagClient.getFlags([
      ["portalTouGenerationUsageGraph", false],
      ["portalUsageGraphTemperatures", false],
    ]);

  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));

  // TODO: SWE-10248
  const chartDirection: ChartDirection = isMobile ? "vertical" : "horizontal";

  const [selectedDate, setSelectedDate] = useState<string>("");

  const [selectedGraph, setSelectedGraph] = useState<SelectedGraph>(
    SelectedGraph.billPeriod
  );

  const [selectedGraphMode, setSelectedGraphMode] = useState<SelectedGraphMode>(
    SelectedGraphMode.both
  );

  const [temperature, setTemperature] = useState<
    GraphTemperatureValue | undefined
  >(GraphTemperatureValue.Average);

  const graphModeTabs: { label: string; value: SelectedGraphMode }[] = [
    { label: tEnergyUsageSectionV2Both, value: SelectedGraphMode.both },
    {
      label: tEnergyUsageSectionV2Consumption,
      value: SelectedGraphMode.consumption,
    },
    { label: tEnergyUsageSectionV2Surplus, value: SelectedGraphMode.surplus },
  ];

  const tabs: { label: string; value: SelectedGraph }[] = [
    {
      label: tEnergyUsageSectionV2BillPeriodTab,
      value: SelectedGraph.billPeriod,
    },
    { label: tEnergyUsageSectionV2HourlyTab, value: SelectedGraph.hourly },
    { label: tEnergyUsageSectionV2DailyTab, value: SelectedGraph.monthly },
    { label: tEnergyUsageSectionV2MonthlyTab, value: SelectedGraph.yearly },
  ];

  const kwhUsageSummaryQuery = usePremiseKwhUsageSummaryQuery({
    enabled: Boolean(
      premise.id && premise.currentOrder && premise.meter?.isSmt
    ),
    premiseId: premise.id,
  });

  const latestAvailableDate = useLatestAvailableDate(
    kwhUsageSummaryQuery.data?.thirtyDaysData || [],
    setSelectedDate
  );

  const kwhUsageDayQuery = usePremiseKwhUsageDayQuery({
    enabled: Boolean(
      premise.id && premise.currentOrder && premise.meter?.isSmt && selectedDate
    ),
    premiseId: premise.id,
    targetDate: selectedDate,
  });

  const premiseTemperatureSummaryQuery = usePremiseTemperatureSummaryQuery({
    premiseId: premise.id,
    queryOptions: {
      enabled: Boolean(portalUsageGraphTemperatures.value),
    },
  });

  const { data: offerSnapshot } = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: premise.currentOrder?.offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offerSnapshotUuid),
    },
  });

  if (!premise.currentOrder) {
    return null;
  }

  if (
    kwhUsageSummaryQuery.isError ||
    kwhUsageDayQuery.isError ||
    (portalUsageGraphTemperatures.value &&
      premiseTemperatureSummaryQuery.isError)
  ) {
    return <EnergyUsageChartMissing />;
  }

  if (
    kwhUsageSummaryQuery.isPending ||
    kwhUsageDayQuery.isPending ||
    (portalUsageGraphTemperatures.value &&
      premiseTemperatureSummaryQuery.isPending)
  ) {
    return <RhCircularProgress />;
  }

  const handleDateSelection = (dateString: string | null) => {
    if (dateString) {
      setSelectedDate(dateString);
    }
  };

  const usageData =
    selectedGraph === SelectedGraph.hourly
      ? kwhUsageDayQuery.data
      : kwhUsageSummaryQuery.data[usageDataMapping[selectedGraph]];

  const chartLabels = {
    costTooltipLabel: tEnergyUsageSectionV2CostTooltipLabel,
    dateHourTitle: tEnergyUsageSectionV2DateHourTitle,
    dateTitle: tEnergyUsageSectionV2DateTitle,
    defaultTierTitle: tEnergyUsageSectionV2DefaultTierTitle,
    earnedTooltipLabel: tEnergyUsageSectionV2EarnedTooltipLabel,
    generationLabelTitle: tEnergyUsageSectionV2GenerationLabelTitle,
    generationTitle: tEnergyUsageSectionV2GenerationLabelTitle,
    generationTooltipLabel: tEnergyUsageSectionV2GenerationTooltipLabel,
    temperatureLabels: {
      average: tEnergyUsageSectionV2Average,
      averageTemp: tEnergyUsageSectionV2AverageTemp,
      high: tEnergyUsageSectionV2High,
      highTemp: tEnergyUsageSectionV2HighTemp,
      low: tEnergyUsageSectionV2Low,
      lowTemp: tEnergyUsageSectionV2LowTemp,
      temperature: tEnergyUsageSectionV2Temperature,
      temperatureAxisTitle: tEnergyUsageSectionV2TemperatureFahrenheit,
    },
    usageAxisTitle: tEnergyUsageSectionV2UsageAxisTitle,
    usageLabelTitle: tEnergyUsageSectionV2UsageLabelTitle,
    usageTitle: tEnergyUsageSectionV2UsageLabelTitle,
    usageTooltipLabel: tEnergyUsageSectionV2UsageTooltipLabel,
  };

  const { solarEligible, solarGenerationCapped } = premise.currentOrder;

  const showGraphModeTabs =
    portalTouGenerationUsageGraph.value && solarEligible;

  const currentTierNames = offerSnapshot?.priceTierSnapshots
    ? offerSnapshot.priceTierSnapshots.map(({ name }) => name)
    : undefined;

  const showTemperatures =
    portalUsageGraphTemperatures.value &&
    selectedGraph !== SelectedGraph.hourly &&
    (!showGraphModeTabs || selectedGraphMode !== SelectedGraphMode.both);

  return (
    <EnergyGraphContainer
      aria-label={tEnergyUsageSectionV2EnergyGraphSectionLabel}
    >
      <EnergyGraphTabsRadiosContainer>
        <TabContainer>
          {showGraphModeTabs && (
            <StyledRhRoundTabs
              initialSelected={graphModeTabs.findIndex(
                (tab) => tab.value === selectedGraphMode
              )}
              contained
              variant="scrollable"
            >
              {graphModeTabs.map(({ label, value }) => (
                <RhRoundTabItem
                  key={label}
                  label={label}
                  onClick={() => {
                    setSelectedGraphMode(value);
                  }}
                />
              ))}
            </StyledRhRoundTabs>
          )}
          <StyledRhRoundTabs variant="scrollable">
            {tabs.map(({ label, value }) => (
              <RhRoundTabItem
                key={label}
                label={label}
                onClick={() => {
                  setSelectedGraph(value);
                }}
              />
            ))}
          </StyledRhRoundTabs>
        </TabContainer>
        <DateAndTotalsContainer>
          {selectedGraph === SelectedGraph.hourly ? (
            <StyledDatePickerWrapper>
              <RhCalendarDatePicker
                label=""
                format="MM/DD/YYYY"
                inputName="usageGraphDatePicker"
                value={dayjs(selectedDate)}
                maxDate={dayjs(latestAvailableDate)}
                minDate={dayjs(premise.confirmedStartDate)}
                onChange={(date: Dayjs) => {
                  const formattedDate = date.format(ISO8601_DATE_FORMAT);

                  handleDateSelection(formattedDate);
                }}
                okLabel={tEnergyUsageSectionV2DatePickerOk}
                cancelLabel={tEnergyUsageSectionV2DatePickerCancel}
              />
            </StyledDatePickerWrapper>
          ) : null}
          {showTemperatures ? (
            <TemperaturesContainer>
              <PortalRadioLabel>
                <PortalRadioButton
                  type="radio"
                  onChange={() => setTemperature(GraphTemperatureValue.Average)}
                  checked={temperature === GraphTemperatureValue.Average}
                />
                {tEnergyUsageSectionV2AverageTemp}
              </PortalRadioLabel>
              <PortalRadioLabel>
                <PortalRadioButton
                  type="radio"
                  onChange={() => setTemperature(GraphTemperatureValue.HighLow)}
                  checked={temperature === GraphTemperatureValue.HighLow}
                />
                {tEnergyUsageSectionV2HighLow}
              </PortalRadioLabel>
              <PortalRadioLabel>
                <PortalRadioButton
                  type="radio"
                  onChange={() => setTemperature(undefined)}
                  checked={temperature === undefined}
                />
                {tEnergyUsageSectionV2HideTemp}
              </PortalRadioLabel>
            </TemperaturesContainer>
          ) : null}
          <TotalUsage
            usageData={usageData}
            showGeneration={
              solarEligible &&
              selectedGraphMode !== SelectedGraphMode.consumption
            }
            showEarned={!solarGenerationCapped}
            showConsumption={selectedGraphMode !== SelectedGraphMode.surplus}
          />
        </DateAndTotalsContainer>

        <StyledTapGraphText>{tEnergyUsageSectionV2Tap}</StyledTapGraphText>
      </EnergyGraphTabsRadiosContainer>
      <EnergyUsageGraphContainer>
        {usageData.length === 0 ? (
          <EnergyUsageChartMissing />
        ) : (
          <EnergyUsageGraphV2
            usageData={usageData}
            chartType={chartTypeMapping[selectedGraph]}
            chartDirection={
              portalUsageGraphTemperatures.value ? "horizontal" : chartDirection
            }
            showGeneration={
              showGraphModeTabs
                ? selectedGraphMode === SelectedGraphMode.surplus ||
                  selectedGraphMode === SelectedGraphMode.both
                : solarEligible
            }
            showConsumption={
              selectedGraphMode === SelectedGraphMode.consumption ||
              selectedGraphMode === SelectedGraphMode.both
            }
            showEarned={!solarGenerationCapped}
            labels={chartLabels}
            currentTierNames={currentTierNames}
            latestAvailableDate={latestAvailableDate}
            selectedTemperatureValue={temperature}
            temperatures={
              showTemperatures && temperature
                ? premiseTemperatureSummaryQuery.data?.[
                    usageDataMapping[selectedGraph]
                  ]
                : []
            }
          />
        )}
      </EnergyUsageGraphContainer>
    </EnergyGraphContainer>
  );
};
