import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { borders } from "@design-system/constants/borders";
import { rhSpacingPx } from "@design-system/theme/spacing";
import {
  MAIN_CONTAINER_WIDTH,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { Link, List } from "@mui/material";
import styled from "styled-components";

export const StyledPortalFooter = styled.div`
  background-color: ${CSSVariables.COLOR_GREY_50};
  border-top: ${borders[200]};
  bottom: 0;
  padding-bottom: ${rhSpacingPx(4)};
  padding-top: ${rhSpacingPx(4)};
  width: 100vw;
`;

export const StyledPortalFooterInner = styled.div`
  margin: 0 auto;
  max-width: 100%;
  width: auto;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    max-width: ${`${MAIN_CONTAINER_WIDTH}px`};
    width: 100%;
  }
`;

export const StyledFlexPortalFooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 300px;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    max-width: 100%;
  }
`;

export const StyledPortalFooterLogoRoot = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rhSpacingPx(4)};

  & svg {
    color: ${CSSVariables.COLOR_SECONDARY_MAIN};
    height: auto;
    width: 125px;
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    justify-content: flex-start;
    margin-top: 0;
  }
`;

export const StyledPortalFooterSocialContainer = styled.div`
  margin-right: ${rhSpacingPx(1)};

  svg {
    height: 40px;
    width: 40px;
  }
`;

export const StyledAllRightsReservedContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rhSpacingPx(4)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    max-width: 100%;
  }
`;

export const StyledRhythmLogoContainer = styled.div`
  margin: 0 auto;
  margin-bottom: ${rhSpacingPx(3)};
  width: 140px;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    margin-bottom: 0;
  }
`;

export const StyledResourcesContainer = styled.div`
  margin-bottom: ${rhSpacingPx(2)};
`;

export const StyledPortalFooterResourcesLinkList = styled(List)`
  padding: 0;

  .MuiListItem-root {
    padding: 0 ${rhSpacingPx(2)} ${rhSpacingPx(1)} 0;
  }
`;

export const StyledPortalFooterResourcesLink = styled(Link)`
  &.MuiLink-root {
    text-decoration: none;
  }
`;

export const StyledAllRightsReserved = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_400};
  }
`;
