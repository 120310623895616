import { prospectApi } from "@enroll-data/api/prospectApi";
import { prospectQueryKeys } from "@enroll-data/queryKeys/prospectQueryKeys";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { useQuery } from "@tanstack/react-query";

interface ProspectAvailableOfferSnapshotsQueryOptions {
  enabled?: boolean;
  retry?: boolean;
}

interface ProspectAvailableOfferSnapshotsQueryProps {
  queryOptions?: ProspectAvailableOfferSnapshotsQueryOptions;
  searchOptions?: {
    rcid?: string;
  };
}

export const useProspectAvailableOfferSnapshotsQuery = (
  props: ProspectAvailableOfferSnapshotsQueryProps
) => {
  const {
    queryOptions: { enabled = true, retry = true } = {},
    searchOptions = {},
  } = props;

  return useQuery({
    enabled,
    queryFn: () => prospectApi.availableOfferSnapshots(),
    queryKey: prospectQueryKeys.availableOfferSnapshots(searchOptions),
    retry,
    select: (data) => data.map((snapshot) => new OfferSnapshot(snapshot)),
  });
};
