import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as PDFIcon } from "@design-system/icons/PDFIcon.svg";
import { EFLLink } from "@portal-shared/components/EFLLink/EFLLink";
import { offerSnapshotLearnMoreLegalDocumentsTranslations } from "@portal-shared/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreLegalDocuments/OfferSnapshotLearnMoreLegalDocuments.en.i18n";
import {
  LinkContainer,
  StyledContainer,
} from "@portal-shared/components/OfferSnapshotLearnMore/OfferSnapshotLearnMoreLegalDocuments/OfferSnapshotLearnMoreLegalDocuments.styled";
import { TOSLink } from "@portal-shared/components/TOSLink/TOSLink";
import { YRACLink } from "@portal-shared/components/YRACLink/YRACLink";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import React from "react";

interface OfferSnapshotLearnMoreLegalDocumentsProps {
  offerSnapshot: OfferSnapshot;
}

export const OfferSnapshotLearnMoreLegalDocuments = (
  props: OfferSnapshotLearnMoreLegalDocumentsProps
) => {
  const { offerSnapshot } = props;
  const { translate } = useTranslations();
  const [locale] = useLocaleAtom();

  const {
    tOfferSnapshotLearnMoreLegalDocumentsEFLDescription,
    tOfferSnapshotLearnMoreLegalDocumentsEFLTitle,
    tOfferSnapshotLearnMoreLegalDocumentsTOSDescription,
    tOfferSnapshotLearnMoreLegalDocumentsTOSTitle,
    tOfferSnapshotLearnMoreLegalDocumentsTitle,
    tOfferSnapshotLearnMoreLegalDocumentsYRACDescription,
    tOfferSnapshotLearnMoreLegalDocumentsYRACTitle,
  } = translate(offerSnapshotLearnMoreLegalDocumentsTranslations);

  const trackingLocation = "Offer Snapshot Learn More";

  return (
    <StyledContainer>
      <RhTypography variant="h2" fontWeight={FontWeight.Bold}>
        {tOfferSnapshotLearnMoreLegalDocumentsTitle}
      </RhTypography>

      <LinkContainer>
        <EFLLink
          offerId={offerSnapshot.id}
          locale={locale}
          dataTrackingClick={{ location: trackingLocation }}
        >
          <PDFIcon aria-hidden />
          <RhTypography component="span">
            {tOfferSnapshotLearnMoreLegalDocumentsEFLTitle}
          </RhTypography>
        </EFLLink>

        <RhTypography variant="body1" color="textSecondary">
          {tOfferSnapshotLearnMoreLegalDocumentsEFLDescription}
        </RhTypography>
      </LinkContainer>

      <LinkContainer>
        <TOSLink
          dataTrackingClick={{ location: trackingLocation }}
          tosUrl={offerSnapshot.termsOfServiceLink(locale)}
        >
          <PDFIcon aria-hidden />
          <RhTypography component="span">
            {tOfferSnapshotLearnMoreLegalDocumentsTOSTitle}
          </RhTypography>
        </TOSLink>

        <RhTypography variant="body1" color="textSecondary">
          {tOfferSnapshotLearnMoreLegalDocumentsTOSDescription}
        </RhTypography>
      </LinkContainer>

      <LinkContainer>
        <YRACLink
          yracUrl={offerSnapshot.yracLink(locale)}
          dataTrackingClick={{ location: trackingLocation }}
        >
          <PDFIcon aria-hidden />
          <RhTypography component="span">
            {tOfferSnapshotLearnMoreLegalDocumentsYRACTitle}
          </RhTypography>
        </YRACLink>

        <RhTypography variant="body1" color="textSecondary">
          {tOfferSnapshotLearnMoreLegalDocumentsYRACDescription}
        </RhTypography>
      </LinkContainer>
    </StyledContainer>
  );
};
