import { Player } from "@lottiefiles/react-lottie-player";
import { rhythmRTBsTranslations } from "@portal-enroll/components/RhythmRTBs/RhythmRTBs.en.i18n";
import {
  LottieContainer,
  StyledBackToTopButton,
  StyledRhythmRTBsContainer,
  StyledRhythmRTBsWrapper,
  StyledRtb,
} from "@portal-enroll/components/RhythmRTBs/RhythmRTBs.styled";
import thirtyDay from "@portal-enroll/images/30-day.lottie.json";
import bestPrice from "@portal-enroll/images/best-price.lottie.json";
import greenEnergy from "@portal-enroll/images/green-energy.lottie.json";
import rhythmRateLock from "@portal-enroll/images/rhythm-rate-lock.lottie.json";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface RTB {
  desc: string;
  lottie: Record<string, unknown>;
  title: string;
}

export const RhythmRTBs = () => {
  const [scrolledTo, setScrolledTo] = useState(false);
  const { translate } = useTranslations();
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();

  const {
    tRhythmRTBs30Day,
    tRhythmRTBs30DayDesc,
    tRhythmRTBsBackToTop,
    tRhythmRTBsBestPrice,
    tRhythmRTBsBestPriceDesc,
    tRhythmRTBsGreenEnergy,
    tRhythmRTBsGreenEnergyDesc,
    tRhythmRTBsRateLock,
    tRhythmRTBsRateLockDesc,
  } = translate(rhythmRTBsTranslations);

  const RTBs: RTB[] = [
    {
      desc: tRhythmRTBsBestPriceDesc,
      lottie: bestPrice,
      title: tRhythmRTBsBestPrice,
    },
    {
      desc: tRhythmRTBsRateLockDesc,
      lottie: rhythmRateLock,
      title: tRhythmRTBsRateLock,
    },
    {
      desc: tRhythmRTBsGreenEnergyDesc,
      lottie: greenEnergy,
      title: tRhythmRTBsGreenEnergy,
    },
    {
      desc: tRhythmRTBs30DayDesc,
      lottie: thirtyDay,
      title: tRhythmRTBs30Day,
    },
  ];

  useEffect(() => {
    if (hash === "#see-for-yourself") {
      setScrolledTo(true);
    }
  }, [hash, setScrolledTo]);

  return (
    <StyledRhythmRTBsWrapper>
      <StyledRhythmRTBsContainer $flashBorder={scrolledTo}>
        {RTBs.map((rtb) => (
          <StyledRtb key={rtb.title}>
            <LottieContainer>
              <Player
                src={rtb.lottie}
                autoplay
                loop
                rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
                style={{
                  height: 36,
                  width: 36,
                }}
              />
              <div>
                <h3>{rtb.title}</h3>
                <p>{rtb.desc}</p>
              </div>
            </LottieContainer>
          </StyledRtb>
        ))}
      </StyledRhythmRTBsContainer>
      {scrolledTo ? (
        <StyledBackToTopButton
          type="button"
          data-tracking-click={{
            event: "Clicked Back to Plans from Rhythm RTBs",
          }}
          onClick={() => {
            navigate(pathname, { replace: true });
            window.scrollTo({ behavior: "smooth", top: 0 });
            setScrolledTo(false);
          }}
        >
          {tRhythmRTBsBackToTop}
        </StyledBackToTopButton>
      ) : null}
    </StyledRhythmRTBsWrapper>
  );
};
