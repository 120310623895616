import { SnippetOptions } from "@fullstory/browser";
import { OktaAuthOptions } from "@okta/okta-auth-js";
import { getPortalEnvApplicationSettings } from "@portal-shared/settings/getPortalEnvApplicationSettings";

interface GoogleTagManagerConfigObject {
  gtmId: string;
}

const portalEnvApplicationSettings = getPortalEnvApplicationSettings();

export const APP_BASE_URL = portalEnvApplicationSettings.appBaseUrl;
export const AXIOS_BASE_URL = portalEnvApplicationSettings.axiosBaseUrl;
export const AXIOS_TIMEOUT_MS: number =
  portalEnvApplicationSettings.axiosTimeoutMs;
export const CDN_URL = portalEnvApplicationSettings.cdnUrl;

export const OKTA_CONFIG = Object.freeze<OktaAuthOptions>({
  clientId: portalEnvApplicationSettings.oktaClientId,
  issuer: portalEnvApplicationSettings.oktaIssuer,
  pkce: true,
  redirectUri: portalEnvApplicationSettings.oktaRedirectUri,
  scopes: ["openid", "profile", "email", "groups"],
});

export const ZUORA_URL = portalEnvApplicationSettings.zuoraBaseUrl;
export const ZUORA_CREDIT_CARD_PAYMENT_PAGE_ID =
  portalEnvApplicationSettings.zuoraCreditCardPaymentPageId;
export const ZUORA_ADD_BANK_ACCOUNT_PAGE_ID =
  portalEnvApplicationSettings.zuoraAddBankAccountPageId;

export const MAPBOX_TOKEN = portalEnvApplicationSettings.mapboxToken;
export const MAPBOX_API_URL = "https://api.mapbox.com";
export const MAPBOX_GEOCODING_API = `${MAPBOX_API_URL}/geocoding/v5/mapbox.places`;
export const MAPBOX_STATIC_API = `${MAPBOX_API_URL}/styles/v1/mlcamilli/ckhao6zpm2ff41aqho05d6j9b/static`;

export const GoogleTagManagerConfig: GoogleTagManagerConfigObject = {
  gtmId: portalEnvApplicationSettings.googleTagManagerId,
};

export const FullStoryConfig: SnippetOptions | null =
  portalEnvApplicationSettings.fullStoryOrgId
    ? Object.freeze({
        orgId: portalEnvApplicationSettings.fullStoryOrgId,
      })
    : null;

export const SENTRY_CONFIG = Object.freeze({
  dsn: portalEnvApplicationSettings.sentryDSN,
  orgSlug: portalEnvApplicationSettings.sentryOrgSlug,
  release: portalEnvApplicationSettings.sentryRelease,
  tracesSampleRate: portalEnvApplicationSettings.sentryTracesSampleRate,
});

export const FAVICON_PATH = portalEnvApplicationSettings.faviconPath;

export const PURECLOUD_CHAT_CONFIG = Object.freeze({
  orgId: portalEnvApplicationSettings.purecloudOrgId,
  orgName: portalEnvApplicationSettings.purecloudOrgName,
  queueName: portalEnvApplicationSettings.purecloudQueueName,
});

export const RHAPSODY_BASE_URL = portalEnvApplicationSettings.rhapsodyBaseUrl;

export const FINGERPRINT_JS_URL = portalEnvApplicationSettings.fingerPrintJsUrl;
export const FINGERPRINT_JS_API_KEY =
  portalEnvApplicationSettings.fingerPrintJsApiKey;
