import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useCreateCreditCheckMutation } from "@enroll-data/hooks/mutations/useCreateCreditCheck.mutation";
import { useProspectUpdateMutation } from "@enroll-data/hooks/mutations/useProspectUpdate.mutation";
import { signUpStepPath } from "@enroll-utils/constants/routePaths";
import { CreditCheckStatus } from "@enroll-utils/types/creditCheckTypes";
import { useProspectFromContext } from "@portal-enroll/components/ProspectProvider/useProspectFromContext";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { ReactComponent as ComparePowerLogoIcon } from "@portal-enroll/images/ComparePowerLogo.svg";
import { DepositForm } from "@portal-enroll/pages/SignUpDepositRequiredPage/DepositForm";
import { DepositSelectionForm } from "@portal-enroll/pages/SignUpDepositRequiredPage/DepositSelectionForm";
import { signUpDepositRequiredPageTranslations } from "@portal-enroll/pages/SignUpDepositRequiredPage/SignUpDepositRequiredPage.en.i18n";
import {
  StyledComparePowerButton,
  StyledComparePowerContainer,
  StyledComparePowerSection,
  StyledDepositRequiredPageLine,
  StyledDepositRequiredPageOrRow,
} from "@portal-enroll/pages/SignUpDepositRequiredPage/SignUpDepositRequiredPage.styled";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { getStepAfterCreditCheckNotApproved } from "@portal-enroll/utils/getStepAfterCreditCheckNotApproved";
import { TrackingView } from "@portal-shared/components/TrackingView/TrackingView";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const allowedMediums = [
  "organic",
  "email",
  "cpc",
  "paid-social",
  "sms",
  "Video",
  "my-account",
  "Display",
  "ppc",
  "referral",
  "FFGG_Display",
  "tatari-streaming",
  "paid",
  "raf",
  "email-share",
];

export const SignUpDepositRequiredPage = () => {
  const {
    creditEvaluation,
    depositAmount,
    depositAlternativeAmount,
    creditCheckResponseV2,
  } = useSelector(selectSignUpState);
  const { acquisitionMedium, uuid, zipCode } = useProspectFromContext();

  const { featureFlagClient } = useFeatureFlagClient();
  const navigate = useNavigate();

  const { translate } = useTranslations();
  const { signUpClickNextStepHandler } = useSignUpFlow();
  const createCreditCheckMutation = useCreateCreditCheckMutation();
  const updateProspectMutation = useProspectUpdateMutation();
  const flash = useRhFlash();

  const {
    tSignUpDepositRequiredPageDontWantToPay,
    tSignUpDepositRequiredPageShopForPlans,
    tSignUpDepositRequiredPageOr,
    tSignUpDepositRequiredPageCreditCheckError,
  } = translate(signUpDepositRequiredPageTranslations);

  const {
    portalDepositAlternativeProgram,
    portalComparePowerSection,
    prospectCreditFlowV2,
  } = featureFlagClient.getFlags([
    ["portalDepositAlternativeProgram", false],
    ["portalComparePowerSection", false],
    ["prospectCreditFlowV2", false],
  ]);

  if (
    creditEvaluation === CreditScoreOutcome.manualReview &&
    !prospectCreditFlowV2.value
  ) {
    navigate(signUpStepPath("call-us"));
  }

  let finalAmount = depositAmount;
  let finalAlternativeAmount = depositAlternativeAmount;

  if (
    prospectCreditFlowV2.value &&
    creditCheckResponseV2?.requirements[0].requirementType === "DEPOSIT"
  ) {
    finalAmount = creditCheckResponseV2.requirements[0].details.depositAmount;
    finalAlternativeAmount =
      creditCheckResponseV2.requirements[0].details.depositAlternativeAmount;
  }

  const showDepositSelectionForm =
    finalAlternativeAmount &&
    finalAlternativeAmount !== "0" &&
    portalDepositAlternativeProgram.value;

  const showComparePower =
    portalComparePowerSection.value &&
    (!acquisitionMedium ||
      allowedMediums
        .map((medium) => medium.toLowerCase())
        .includes(acquisitionMedium.toLowerCase()));

  const comparePowerUrl = brandInformation.urlsComparePower({
    trackingId: uuid,
    zipCode: zipCode ?? "",
  });

  const handleSubmit = async (
    billingPaymentMethodId: string,
    depositAlternativeElected?: boolean
  ) => {
    if (prospectCreditFlowV2.value) {
      await updateProspectMutation.mutateAsync({
        data: { billingPaymentMethodId, depositAlternativeElected },
      });

      createCreditCheckMutation.mutate(
        {},
        {
          onError: () =>
            flash.error(tSignUpDepositRequiredPageCreditCheckError),
          onSuccess: (response) => {
            const nextStep =
              response.status === CreditCheckStatus.APPROVED
                ? "summary"
                : getStepAfterCreditCheckNotApproved(response);

            signUpClickNextStepHandler({
              nextStep,
              signUpData: {
                creditCheckResponseV2: response,
              },
              track: true,
            });
          },
        }
      );
    } else {
      signUpClickNextStepHandler({
        nextStep: "summary",
        signUpData: { billingPaymentMethodId, depositAlternativeElected },
        track: true,
      });
    }
  };

  return (
    <SignUpPageLayout>
      {showDepositSelectionForm ? (
        <DepositSelectionForm
          depositAmount={finalAmount}
          depositAlternativeAmount={finalAlternativeAmount}
          handleSubmit={handleSubmit}
        />
      ) : (
        <DepositForm depositAmount={finalAmount} handleSubmit={handleSubmit} />
      )}
      {showComparePower ? (
        <StyledComparePowerSection>
          <TrackingView data={{ event: "Compare Power section being shown" }} />
          <StyledDepositRequiredPageOrRow>
            <StyledDepositRequiredPageLine />
            <RhTypography variant="subtitle2">
              {tSignUpDepositRequiredPageOr}
            </RhTypography>
            <StyledDepositRequiredPageLine />
          </StyledDepositRequiredPageOrRow>
          <StyledComparePowerContainer>
            <RhTypography variant="h4">
              {tSignUpDepositRequiredPageDontWantToPay}
            </RhTypography>
            <RhTypography>
              {tSignUpDepositRequiredPageShopForPlans}
            </RhTypography>
            <StyledComparePowerButton
              fullWidth={false}
              data-tracking-click={{
                event: "Customer clicks Compare Power link",
              }}
              href={comparePowerUrl}
              target="__blank"
            >
              <ComparePowerLogoIcon />
            </StyledComparePowerButton>
          </StyledComparePowerContainer>
        </StyledComparePowerSection>
      ) : null}
    </SignUpPageLayout>
  );
};
