import { HttpStatusCodes } from "@common/types/apiResponseTypes";
import { LoadingOverlay } from "@portal-account/components/LoadingOverlay/LoadingOverlay";
import { SESSION_STORAGE_PREMISE_ID_KEY } from "@portal-account/components/PremiseIdChooser/PremiseIdChooser.constants";
import { useCustomerQuery } from "@portal-account/hooks/queries/useCustomer.query";
import { usePremiseQuery } from "@portal-account/hooks/queries/usePremise.query";
import { usePremiseAccountSummaryQuery } from "@portal-account/hooks/queries/usePremiseAccountSummary.query";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { signOutPath } from "@portal-shared/routes/routePaths";
import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

interface AccountLoaderProps {}

export const AccountLoader = (props: PropsWithChildren<AccountLoaderProps>) => {
  const { children } = props;
  const [premiseIdInSessionStorage] = useSessionStorage<string | null>(
    SESSION_STORAGE_PREMISE_ID_KEY,
    null
  );

  const customerQuery = useCustomerQuery();
  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    premiseId: premiseIdInSessionStorage || "",
    queryOptions: {
      enabled: Boolean(premiseIdInSessionStorage),
    },
  });
  const premiseQuery = usePremiseQuery({
    premiseId: premiseIdInSessionStorage || "",
    queryOptions: { enabled: Boolean(premiseIdInSessionStorage) },
  });

  if (customerQuery.isError) {
    if (customerQuery.error.status === HttpStatusCodes.HTTP_403_FORBIDDEN) {
      return <Navigate to={signOutPath()} />;
    }

    return <Error500Page />;
  }

  if (
    customerQuery.isLoading ||
    premiseQuery.isLoading ||
    accountSummaryQuery.isLoading
  ) {
    return <LoadingOverlay />;
  }

  // Customer and premise errors handled in the respective providers
  // Since accountSummaryQuery doesn't have a provider, handling the error here
  if (accountSummaryQuery.isError) {
    if (
      accountSummaryQuery.error?.status === HttpStatusCodes.HTTP_403_FORBIDDEN
    ) {
      return <Navigate to={signOutPath()} />;
    }

    return <Error500Page />;
  }

  return <>{children}</>;
};
