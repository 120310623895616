import alloy from "@alloyidentity/web-sdk";
import { isProduction } from "@common/services/getEnvApplicationSettings.service";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useCreditCheckQuery } from "@enroll-data/hooks/queries/useCreditCheck.query";
import { CreditCheckStatus } from "@enroll-utils/types/creditCheckTypes";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { enrollIdUploadPageTranslations } from "@portal-enroll/pages/EnrollIdUploadPage/EnrollIdUploadPage.en.i18n";
import { StyledGetStartedButton } from "@portal-enroll/pages/EnrollIdUploadPage/EnrollIdUploadPage.styled";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { getStepAfterCreditCheckNotApproved } from "@portal-enroll/utils/getStepAfterCreditCheckNotApproved";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { useBrandImageUrls } from "@portal-shared/hooks/useBrandImageUrls/useBrandImageUrls";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const EnrollIdUploadPage = () => {
  const { translate } = useTranslations();

  const {
    tEnrollIdUploadPageGetStarted,
    tEnrollIdUploadPageItLooks,
    tEnrollIdUploadPageWeNeed,
    tEnrollIdUploadPageGotIt,
    tEnrollIdUploadPageWeAreVerifying,
    tEnrollIdUploadPageError,
  } = translate(enrollIdUploadPageTranslations);
  const { iconBigMagnifier } = useBrandImageUrls();

  const [isIdUploaded, setIsIdUploaded] = useState(false);
  const { creditCheckResponseV2 } = useSelector(selectSignUpState);
  const creditCheckQuery = useCreditCheckQuery({
    queryOptions: { enabled: false },
  });

  const { signUpClickNextStepHandler } = useSignUpFlow();
  const flash = useRhFlash();

  useEffect(() => {
    if (creditCheckQuery.isPending) {
      return;
    }

    if (creditCheckQuery.isError) {
      flash.error(tEnrollIdUploadPageError);
      return;
    }

    const nextStep =
      creditCheckQuery.data.status === CreditCheckStatus.APPROVED
        ? "summary"
        : getStepAfterCreditCheckNotApproved(creditCheckQuery.data);

    signUpClickNextStepHandler({
      nextStep,
      signUpData: { creditCheckResponseV2: creditCheckQuery.data },
      track: true,
    });
  }, [
    creditCheckQuery.isPending,
    creditCheckQuery.isError,
    creditCheckQuery.data,
  ]);

  const handleGetStarted = async () => {
    if (
      creditCheckResponseV2?.requirements[0].requirementType ===
      "IDENTITY_CHECK"
    ) {
      const { alloyJourneyApplicationToken, alloyJourneyToken, alloySdkKey } =
        creditCheckResponseV2.requirements[0].details;
      const production = isProduction();

      await alloy.init({
        journeyApplicationToken: alloyJourneyApplicationToken,
        journeyToken: alloyJourneyToken,
        key: alloySdkKey,
        production,
      });

      alloy.open(handleAlloyClose);
    }
  };

  const handleAlloyClose = () => {
    setIsIdUploaded(true);
    creditCheckQuery.refetch();
  };

  return (
    <SignUpPageLayout>
      {isIdUploaded ? (
        <LoggedOutPageHeader
          headerText={tEnrollIdUploadPageGotIt}
          subHeaderText={tEnrollIdUploadPageWeAreVerifying}
          illustration={<img src={iconBigMagnifier} alt="" aria-hidden />}
        />
      ) : (
        <>
          <LoggedOutPageHeader
            headerText={tEnrollIdUploadPageItLooks}
            subHeaderText={tEnrollIdUploadPageWeNeed}
          />

          <StyledGetStartedButton
            data-tracking-click={{ event: "User clicks Get started id upload" }}
            onClick={handleGetStarted}
          >
            {tEnrollIdUploadPageGetStarted}
          </StyledGetStartedButton>
        </>
      )}
    </SignUpPageLayout>
  );
};
